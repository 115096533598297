import { Button, Input, message, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { API } from "../../config/API";
import { POST } from "../../utils/apirequest";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../component/pageHeaders";
import { IoMdCloseCircle } from "react-icons/io";
import Loadingbox from "../../component/loadingbox";
import DataTable from "./components/dataTable";

function CampaignScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState() as any;
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;
  const [publishCount, setPublishCount] = useState();
  const [trashcount, setTrashcount] = useState();
  const [draft, setDraft] = useState();

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      setIndicator(true);
      let url = API.DYNAMIC_FORM_LIST;
      const res: any = await POST(url, filter);
      if (res?.status) {
        setData(res?.data);
        setIsloading(false);
        setIndicator(false);
        setPublishCount(res?.publishedCount);
        setTrashcount(res?.trashedCount);
        setDraft(res?.draftCount);
      }
    } catch (err) {
      console.log("err", err);
      setIsloading(false);
      setIndicator(false);
      message.error("Something went wrong...");
    }
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setTimeout(() => {
        loadData(filters);
      }, 500);
      setFilter(filters);
    }
  };

  return (
    <div>
      <PageHeader
        title={"Campaign"}
        loader={indicator}
        length={`Dashboard / Campaign`}
      >
        <div className="homescreen-pageItem">
          <Input
            style={{ width: "250px" }}
            allowClear
            placeholder="Search Campaign"
            onChange={(e: any) => Search(e.target.value)}
          />
          &nbsp; &nbsp;
          <div>
            <Button
              type="primary"
              onClick={() => navigate("/Auth/campaign/form/create")}
            >
              Add New +
            </Button>
          </div>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="PageHeaders-box5">
        <div className="PageHeaders-txt3">Campaign ( {meta} )</div>
        {Object.keys(filter)?.length > 2 ? <div>|</div> : null}
        <div className="PageHeaders-txt2">
          {Object.keys(filter)?.length > 2 ? "Filter By : " : ``}
        </div>
        <div>
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag
                  onClick={() => clearFilters(key)}
                  icon={<IoMdCloseCircle color="red" />}
                >
                  &nbsp;{key} : {filter[key]}
                </Tag>
              )
          )}
        </div>
      </div>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          page={page}
          pagesize={pageSize}
          //   onDelete={(id: any) => deleteData(id)}
          //   onUpdate={(id: any, checked: any) => updateData(id, checked)}
          //   onPageChange={(page: any, pageSize: any) =>
          //     onPageChange(page, pageSize)
          //   }
          refresh={() => loadData(filter)}
        />
      )}
    </div>
  );
}

export default CampaignScreen;
