import { Pagination, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IoMdEye } from "react-icons/io";

const calculateAnswers = (data: any) => {
  if (data?.length) {
    return data.filter((item: any) => item.is_correct === true).length;
  } else {
    return 0;
  }
};
function DataTable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      width: 500,
      render: (text, record: any) => {
        return <div style={{ fontSize: "14px" }}>{record?.name}</div>;
      },
    },
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
      width: 500,
      render: (text, record: any) => {
        return <div style={{ fontSize: "10px" }}>{record?.phone}</div>;
      },
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      width: 500,
      render: (text, record: any) => {
        return <div style={{ fontSize: "10px" }}>{record?.address}</div>;
      },
    },
    {
      title: "Win / Failed",
      dataIndex: "win",
      key: "win",
      width: 200,
      render: (text, record: any) => {
        return (
          <Tag color={record.winner ? "green" : "red"}>
            {record.winner ? "Win" : "Failed"}
          </Tag>
        );
      },
    },
    {
      title: "Answers",
      width: 300,
      render: (text, record: any) => {
        console.log(record);
        return (
          <div style={{ fontSize: "12px" }}>
            {calculateAnswers(record?.quiz_answer_items)} /{" "}
            {record?.answers?.length}
          </div>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 300,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }}>{moment(text).format("lll")}</div>
        );
      },
    },

    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <Space size="small">
            <IoMdEye
              size={20}
              cursor={"pointer"}
              onClick={() => props.showResults(record)}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <div style={{ marginTop: "10px" }}>
      <Table
        loading={props.loading}
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          disabled={props.loading}
          defaultCurrent={props?.page}
          total={props?.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
