import { Button, Form, Input, Select, message, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Loadingbox from "../../../component/loadingbox";
import { API } from "../../../config/API";
import TextArea from "antd/es/input/TextArea";
import { BsFillPlusCircleFill, BsUpload } from "react-icons/bs";
import PageHeader from "../../../component/pageHeaders";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LuMinus } from "react-icons/lu";
import ImagePicker from "../../../component/image-picker";
import moment from "moment";
import { GET } from "../../../utils/apirequest";
import "../styles.css";
import { useSelector } from "react-redux";

function NewFormVidyaArticles() {
  const navigate = useNavigate();
  let { id }: any = useParams();
  const User = useSelector((state: any) => state.User.user);
  const location = useLocation();
  const props = location?.state;

  let InitialBody =
    props?.item && props?.item?.id && props?.item && props?.item?.Body;
  const [form] = Form.useForm();
  const [content, setContent] = useState<any>(InitialBody);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(false);
  const [returnedNewsId, setReturnedNewsId] = useState() as any;
  const [imageuploaddata, setImageuploaddata] = useState<any>({
    imageurl1: props?.item?.image1 || "",
    imageurl2: props?.item?.image2 || "",
    imageurl3: props?.item?.profile || "",
  });
  const [uploadImg, setUploadImg] = useState(false);
  const [UpdateuploadImgData, setUpdateUploadImgData] = useState(false);
  const [imageType, setImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [authorImg, setAuthorImg] = useState();
  const [medialibrarymodal, setMedialibrarymodal] = useState(false);
  const [button, stepButton] = useState(false);
  const [tags, setTags] = useState<any>([]);

  const { imageurl1, imageurl2, imageurl3 } = imageuploaddata;
  let pic1 = imageurl1;
  let pic2 = imageurl2;
  let pic3 = imageurl3;

  useEffect(() => {
    LoadTags();
  }, []);

  const LoadTags = async () => {
    let url = API.TAGS_LIST_ALL;
    let res: any = await GET(url, null, null);
    setTags(res);
  };

  const handleEditorChange = (content: any, editor: any) => {
    setContent(content);
  };

  function deleteImagesFromList(key: any) {
    try {
      setImageuploaddata((prevImages: any) => {
        const updatedImages = { ...prevImages };
        updatedImages[key] = "";
        return updatedImages;
      });
      notification.success({
        message: `Image Removed`,
      });
    } catch (err) {
      notification.error({
        message: `Failed to remove`,
      });
    }
  }
  const addImagesToList = (data: any) => {
    try {
      let DataObj = imageuploaddata;
      if (data.type === "image1") {
        DataObj["imageurl1"] = "";
        DataObj["imageurl1"] = data.image_url;
        DataObj["image1Value"] = "";
        DataObj["image1Value"] = data.image_desc;
        DataObj["image1Caption"] = "";
        DataObj["image1Caption"] = data.image_Caption;
      } else if (data.type === "image2") {
        DataObj["imageurl2"] = "";
        DataObj["imageurl2"] = data.image_url;
        DataObj["image2Value"] = "";
        DataObj["image2Value"] = data.image_desc;
        DataObj["image2Caption"] = "";
        DataObj["image2Caption"] = data.image_Caption;
      } else if (data.type === "image3") {
        DataObj["imageurl3"] = "";
        DataObj["imageurl3"] = data.image_url;
        DataObj["image3Value"] = "";
        DataObj["image3Value"] = data.image_desc;
        DataObj["image3Caption"] = "";
        DataObj["image3Caption"] = data.image_Caption;
      } else if (data.type === "authorImg") {
        setAuthorImg(data.image_url);
      }
      setImageuploaddata(DataObj);
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let requestObj = {
      data: {
        title: values?.title,
        Author: values?.author,
        Body: content,
        image1:
          props?.item?.id && props?.item?.image1 ? props?.item?.image1 : pic1,
        image2:
          props?.item?.id && props?.item?.image2 ? props?.item?.image2 : pic2,
        publishedAt: props?.item?.publishedAt ? props?.item?.publishedAt : null,
        profile:
          props?.item?.id && props?.item?.profile
            ? props?.item?.profile
            : authorImg,
        masterid: id,
        permalink: values.permalink,
        tags: values.tags,
        user: id !== undefined ? props?.item?.user?.id : User?.id,
        image1_description: imageuploaddata.image1Value,
        image2_description: imageuploaddata.image2Value,
      },
    };
    let url =
      props?.item && props?.item?.id
        ? API.BASE_URL + API.VIDYA_PRABHAADHAM + `/${props?.item?.id}`
        : API.BASE_URL + API.VIDYA_PRABHAADHAM;

    let method = props?.item && props?.item.id ? "PUT" : "POST";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        setReturnedNewsId(response?.data?.data?.id);
        setLoading(false);
        if (props?.item?.publishedAt) {
          navigate(`/Auth/VidyaScreeen`);
        }
        message.success(
          <>
            Success : <b>Saved</b>
          </>
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onPublish = (newsid: any) => {
    setLoading3(true);
    let url = API.BASE_URL + API.VIDYA_PRABHAADHAM + `/${newsid}`;
    let requestObj = {
      data: {
        publishedAt: new Date(),
      },
    };
    axios({
      method: "PUT",
      url: url,
      data: requestObj,
    })
      .then((response) => {
        message.success("Published");
        setLoading3(false);
        navigate(`/Auth/VidyaScreeen`);
        props.refresh();
      })
      .catch((error) => {
        setLoading3(false);
        console.log(error);
      });
  };
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };
  const addImage = (image: any, caption: any) => {
    if (window.tinymce && window.tinymce.activeEditor) {
      const editor = window.tinymce.activeEditor;
      let Moment = `${moment().format(
        "YYYY-MM-DDHH:MM:SS"
      )}.suprabhaatham-news.png`;
      let alt = caption ? caption : Moment;
      editor.insertContent(`<img src="${image}" alt="${alt}" />`);
      setMedialibrarymodal(false);
    }
  };

  const handleUrlCopy = (type: any) => {
    const perma = form.getFieldValue("permalink");
    let formattedPerma = perma?.replace(/\s+/g, "-");
    const link = `${API.APP_URL}Prabhaatham?id=${
      returnedNewsId ? returnedNewsId : props?.item?.id
    }&&${formattedPerma ? formattedPerma : ""}`;
    if (type === "copy") {
      navigator.clipboard.writeText(link).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(link, "_blank");
    }
  };

  const checkPermaLink = (e: any) => {
    const regex = /^[a-zA-Z0-9- ]*$/;
    const inputValue = e.target.value;
    const filteredValue = inputValue
      .split("")
      .filter((char: any) => regex.test(char))
      .join("");
    form.setFieldsValue({ permalink: filteredValue });
  };

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      <PageHeader title={"Vidya prabhaatham form"} />
      <Form
        onFinish={onFinish}
        form={form}
        name="add-editorials"
        initialValues={{
          title:
            props?.item && props?.item?.id && props?.item && props?.item?.title,
          author:
            props?.item &&
            props?.item?.id &&
            props?.item &&
            props?.item?.Author,
          permalink:
            props?.item &&
            props?.item?.id &&
            props?.item &&
            props?.item?.permalink,
          tags:
            props?.item?.tags &&
            props?.item?.tags?.data &&
            props?.item?.tags?.data.map((item: any) => item?.id),
        }}
      >
        <Container>
          <Row>
            <Col sm={9} xs={12} style={{ marginTop: 10 }}>
              <Row>
                <Col md={6}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item name="title" {...formItemLayout}>
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <label className="newsFormLabels">Byline</label>
                  <Form.Item name="author" {...formItemLayout}>
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              {loading2 && <Loadingbox />}
              <Col md={11}>
                <label className="newsFormLabels">Body</label>
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: "none" }}
                  onChange={() => {}}
                />
                <Editor
                  // apiKey={API.EDITOR_API_KEY}
                  value={content}
                  init={{
                    height: 500,
                    menubar: true,
                    directionality: "ltr",
                    // selector: 'textarea#file-picker',
                    plugins:
                      "advlist autolink lists link image pageembed code preview charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount pageembed code preview image code",
                    toolbar:
                      "  | bold italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent |subscript superscript |link unlink| help | forecolor backcolor | \
                      \
                      \
                           \
                           | image | myCustomButton | joinButton | TwitterEmbed",
                    setup: (editor) => {
                      editor.ui.registry.addButton("myCustomButton", {
                        text: "Media Library",
                        icon: "gallery",
                        onAction: () => {
                          setImageType("Media");
                          setUploadImg(true);
                        },
                      });
                      editor.ui.registry.addButton("joinButton", {
                        text: "Custom Button",
                        onAction: () => {
                          stepButton(true);
                        },
                      });
                    },
                    file_browser_callback_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === "image") {
                        var input = document.getElementById("my-file") as any;
                        input.click();
                        input.onchange = function () {
                          var file = input.files[0];
                          var reader = new FileReader();
                          reader.onload = function (e: any) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },
                    paste_data_images: true,
                  }}
                  onEditorChange={handleEditorChange}
                  onInit={() => setLoading2(false)}
                />
              </Col>
              <br />
              <Col md={6}>
                <div className="newsFormLabels">Tags</div>
                <Form.Item name="tags" {...formItemLayout}>
                  <Select
                    mode="multiple"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tags.length > 0 &&
                      tags.map((item: any, index: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <br />
              <Row>
                <Col md={4}>
                  <div className="NewsImagePickerItem">
                    <div className="newsFormLabels">News Image 1</div>
                    <div className="vidyaImageBox">
                      {imageuploaddata?.imageurl1 === "" ? (
                        <>
                          <BsFillPlusCircleFill
                            color="#c2c2c2"
                            size={40}
                            onClick={() => {
                              if (imageuploaddata?.imageurl1 === "") {
                                setImageType("image1");
                                setImageName("Image 1");
                                setUploadImg(true);
                              }
                            }}
                          />
                          <br />
                          <div className="imageUplaod-Txt">Click to Upload</div>
                        </>
                      ) : (
                        <>
                          <LuMinus
                            onClick={() => deleteImagesFromList("imageurl1")}
                            className="image-delete-btn"
                            size={25}
                          />
                          <img
                            alt="img"
                            className="NewsImageBox-img"
                            src={imageuploaddata?.imageurl1}
                            onClick={() => {
                              setImageType("image1");
                              setImageName("Image 1");
                              setUpdateUploadImgData(true);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="NewsImagePickerItem">
                    <div className="newsFormLabels">News Image 2</div>
                    <div className="vidyaImageBox">
                      {imageuploaddata?.imageurl2 === "" ? (
                        <>
                          <BsFillPlusCircleFill
                            color="#c2c2c2"
                            size={40}
                            onClick={() => {
                              if (imageuploaddata?.imageurl2 === "") {
                                setImageType("image2");
                                setImageName("Image 2");
                                setUploadImg(true);
                              }
                            }}
                          />
                          <br />
                          <div className="imageUplaod-Txt">Click to Upload</div>
                        </>
                      ) : (
                        <>
                          <LuMinus
                            onClick={() => {
                              imageuploaddata["imageurl2"] = "";
                              deleteImagesFromList("imageurl2");
                            }}
                            className="image-delete-btn"
                            size={25}
                          />
                          <img
                            alt="img"
                            className="NewsImageBox-img"
                            src={imageuploaddata?.imageurl2}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="NewsImagePickerItem">
                    <div className="newsFormLabels">Author Image</div>
                    <div className="vidyaImageBox">
                      {imageuploaddata?.imageurl3 === "" ? (
                        <>
                          <BsFillPlusCircleFill
                            color="#c2c2c2"
                            size={40}
                            onClick={() => {
                              if (imageuploaddata?.imageurl3 === "") {
                                setImageType("authorImg");
                                setImageName("authorImg");
                                setUploadImg(true);
                              }
                            }}
                          />
                          <br />
                          <div className="imageUplaod-Txt">Click to Upload</div>
                        </>
                      ) : (
                        <>
                          <LuMinus
                            onClick={() => deleteImagesFromList("imageurl3")}
                            className="image-delete-btn"
                            size={25}
                          />
                          <img
                            alt="img"
                            className="NewsImageBox-img"
                            src={imageuploaddata?.imageurl3}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              sm={3}
              style={{
                marginTop: "50px",
                borderLeft: "1px solid rgb(222, 222, 222)",
              }}
            >
              {returnedNewsId || props?.item?.id ? (
                <>
                  <Button
                    onClick={() => handleUrlCopy("preview")}
                    style={{ marginRight: "15px" }}
                  >
                    Web Preview
                  </Button>
                </>
              ) : null}
              <br />
              <label className="newsFormLabels">Perma-Link</label>
              <Form.Item
                name="permalink"
                rules={[
                  {
                    validator: (_, value) => {
                      const regex = /^[a-zA-Z0-9- ]*$/;
                      return regex.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            "Only alphanumeric characters, spaces, and dashes are allowed."
                          );
                    },
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  lang="en"
                  onChange={checkPermaLink}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm="4" />
            <Col sm="4">
              {!props?.item?.publishedAt ? (
                <Button
                  block
                  style={{ height: 50 }}
                  disabled={!returnedNewsId}
                  type="primary"
                  onClick={() => onPublish(returnedNewsId)}
                  icon={<BsUpload />}
                >
                  Publish
                </Button>
              ) : null}
            </Col>
            <Col sm="4">
              <Button
                block
                style={{ height: 50 }}
                htmlType="submit"
                loading={loading}
              >
                {props?.item?.id ? "Update" : " Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
      <ImagePicker
        mode="create"
        imageType={imageType}
        imageName={imageName}
        onSelect={addImage}
        onSubmit={(val: any) => addImagesToList(val)}
        visible={uploadImg}
        close={() => setUploadImg(false)}
      />
    </div>
  );
}

export default NewFormVidyaArticles;
