import axios from "axios";
import { API } from "../../../config/API";

export const GetPolls: any = async (id: any, skip: number, limit: any) => {
  let Idfilter = ` filters: {
    id : { eq : ${id}}
  }`;
  return new Promise(async function (resolve, reject) {
    try {
      const query = `
      query{
        polls(
          ${id ? Idfilter : ""}
          sort: "id:desc", pagination: { start: ${skip}, limit: ${limit} }){
          data{
            id
              attributes {
                questions
                isActive
                permalink
                startDate
                endDate
                is_deleted
                publishedAt
                tags{
                  data {
                    id
                    attributes {
                      tag
                    }
                  }
                }
                poll_options{
                   data {
                    id
                    attributes {
                      options
                      vote
                    }
                  }
                }
              }
          }
             meta {
            pagination {
              total
            }
          }
        }
      }`;
      axios({
        method: "post",
        url: API.GRAPHQL,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          query: query,
        },
      })
        .then((response: any) => {
          let obj = {
            status: true,
            data: response?.data?.data.polls,
          };
          resolve(obj);
        })
        .catch((error: any) => {
          let obj = { status: false };
          reject(obj);
        });
    } catch (err) {
      let obj = { status: false };
      reject(obj);
      console.log(err);
    }
  });
};
