import { useState, useEffect } from "react";
import "./styles.css";
import PageHeader from "../../component/pageHeaders";
import DataTable from "./component/datatable";
import Loadingbox from "../../component/loadingbox";
import { Button, DatePicker, Select, message } from "antd";
import Preview from "./component/preview";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/API";
import { DELETE, POST, PUT } from "../../utils/apirequest";
import editons from "./component/editions.json";

function EPaperScreeen() {
  const navigation = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [preview, setPreview] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState();
  const [filter, setFilter] = useState({ start: 0, limit: 10 }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      let URL = API.EPAPER_LIST;
      const res: any = await POST(URL, filter);
      if (res?.status) {
        setData(res?.data);
        setMeta(res.count);
        setIsloading(false);
      }
    } catch (err) {
      setData([]);
      setIsloading(false);
    }
  };

  const showPreview = (val: any) => {
    setItem(val);
    setPreview(true);
  };

  const editionChange = (category: any) => {
    let filters = {
      ...filter,
      edition: category,
    };
    setFilter(filters);
    loadData(filters);
  };

  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : (page - 1) * 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const UndoTrash = async (val: any) => {
    let url = API.EPAPER_EDIT + `${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const addToTrash = async (val: any) => {
    try {
      let url = API.EPAPER_EDIT + `${val.id}`;
      let obj = {
        data: {
          is_deleted: true,
        },
      };
      let res: any = await PUT(url, obj);
      if (res?.data?.id) {
        message.success("Sucesssfully moved to trash");
        loadData(filter);
      } else {
        message.error("Something went wrong...!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.EPAPER_DELETE + `${val}`;
      let response: any = await DELETE(url);
      if (response) {
        loadData(filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"E-Paper"} length={`${data.length} Pages`}>
        <Select
          placeholder="Edition"
          showSearch={true}
          style={{ width: 200 }}
          allowClear
          filterOption={(input: any, option: any) =>
            option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
          }
          onChange={editionChange}
        >
          {editons?.map((item: any) => {
            return <Select.Option value={item}>{item}</Select.Option>;
          })}
        </Select>
        &nbsp;
        <DatePicker allowClear style={{ width: 200 }} onChange={onChangeDate} />
        &nbsp;
        <Button
          type="primary"
          onClick={() => navigation("/Auth/EpaperForm/create")}
        >
          Add New +
        </Button>
      </PageHeader>
      <div>
        {isloading ? (
          <Loadingbox />
        ) : (
          <DataTable
            data={data}
            page={page}
            pagesize={pageSize}
            meta={meta}
            refresh={() => loadData(filter)}
            onTrash={(val: any) => addToTrash(val)}
            unTrash={(val: any) => UndoTrash(val)}
            onDelete={(val: any) => onDelete(val)}
            onEdit={(val: any) => navigation(`/Auth/EpaperForm/${val?.id}`)}
            onPageChange={(page: any, pageSize: any) =>
              onPageChange(page, pageSize)
            }
            showPreview={(val: any) => showPreview(val)}
          />
        )}
      </div>
      {preview ? (
        <Preview
          item={item}
          open={preview}
          onCancel={() => setPreview(false)}
        />
      ) : null}
    </div>
  );
}

export default EPaperScreeen;
