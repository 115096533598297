import { Button, Form, Select, message } from "antd";
import  { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../config/API";
import TextArea from "antd/es/input/TextArea";
import PageHeader from "../../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import { GET } from "../../../utils/apirequest";
import { useSelector } from "react-redux";

function NjayarForm(props: any) {
  const navigate = useNavigate();
  const User = useSelector((state: any) => state.User.user);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    LoadTags()
  },[])

  const LoadTags = async() => {
    let url =  API.TAGS_LIST_ALL;
    let res:any = await GET(url,null,null)
    setTags(res)
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    let requestObj = {
      data: {
        head: values?.title,
        permalink: values?.permalink,
        tags: values.tags,
        user: User?.id,
      },
    };
    let url = API.BASE_URL + API.NJAYAR_MASTER;
    let method = "POST";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        setLoading(false);
        message.success(
          <>
            Success : <b>Saved</b>
          </>
        );
        navigate(
          `/Auth/NjayarScreen/njayarArticles/${response?.data?.data?.id}`,
          { state: { items: response?.data?.data } }
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };

  return (
    <div>
      <PageHeader
        title={"Njayar prabhaatham"}
      />
      <div style={{ border: "1px solid #f0f2f5" }}> </div>
      <Col md={{ span: 11, offset: 1 }} style={{ marginTop: 50 }}>
        <Form onFinish={onFinish} form={form} name="add-editorials">
          <Row>
            <Col md={6}>
              <label className="newsFormLabels">Title</label>
              <Form.Item name="title" {...formItemLayout}>
                <TextArea />
              </Form.Item>
            </Col>
            <Col md={6}>
            <div className="newsFormLabels">Tags</div>
            <Form.Item name="tags" {...formItemLayout}>
                  <Select
                     mode="multiple"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tags.length > 0 &&
                      tags.map((item: any, index: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
            </Col>
            <Col md={6}>
              <label className="newsFormLabels">permalink</label>
              <Form.Item name="permalink" {...formItemLayout}>
                <TextArea />
              </Form.Item>
            </Col>
            <Col md={6}></Col>
            <Col md={6}>
              <div className="epaper-uploads">
                <Button
                  className="epaper-Btn"
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                >
                  create articles
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </div>
  );
}

export default NjayarForm;
