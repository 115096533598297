import { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { Button, message } from "antd";
import PodcastForm from "./component/podcastForm";
import axios from "axios";
import PodcastList from "./component/podcastList";
import "./styles.css";
import { API } from "../../config/API";
import { useNavigate } from "react-router-dom";

function PodcastScreen() {
  const [isTable, setIsTable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [item, setItem] = useState({});
  const navigation = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    let url = API.BASE_URL + API.PODCAST + "?populate=*";
    axios.get(url).then((response) => {
      let data = response.data.data;
      setData(data);
      setIsLoading(false);
    });
  };

  const deleteData = (id: any) => {
    setIsLoading(true);
    let url = API.BASE_URL + API.PODCAST + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        setIsLoading(false);
        message.success("Successfully deleted");
        loadData();
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  const onEdit = (val: any) => {
    setItem(val);
    setIsTable(false);
  };

  const search = () => {
    let filter = `?filters[title][$contains]=${inputValue}`;
    let Api = API.BASE_URL + API.PODCAST + filter;
    axios.get(Api).then((response) => {
      let data = response.data.data;
      setData(data);
    });
  };

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      {isTable ? (
        <PageHeader title={"Podcast"}>
          <Button
            type="primary"
            onClick={() => {
              setItem({});
              setIsTable(false);
            }}
          >
            Add New +
          </Button>
        </PageHeader>
      ) : null}
      <div className="">
        {!isTable ? (
          <PodcastForm
            items={item}
            onClose={() => {
              loadData();
              setIsTable(true);
            }}
          />
        ) : (
          <PodcastList
            data={data}
            loading={isLoading}
            onDelete={(id: any) => deleteData(id)}
            onEdit={(val: any) => onEdit(val)}
          />
        )}
      </div>
    </div>
  );
}

export default PodcastScreen;
