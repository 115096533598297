import { Space, Table, Tag, Popconfirm, Pagination, Button, Switch } from "antd";
import "../styles.css";
import { ColumnsType } from "antd/es/table";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import moment from "moment";

function DataTable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 500,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "hint",
      dataIndex: "hint",
      key: "hint",
      width: 400,
      render: (text, record: any) => {
        return (
          <div
          className="Quiz-tableItem"
            onClick={() => props.onEdit(record)}
          >
            {record?.hint}
          </div>
        );
      },
    },
    {
      title: "Winner",
      dataIndex: "winner_selected",
      key: "winner_selected",
      width: 100,
      render: (text, record: any) => {
        return (
          <Tag color={text ? "green" : "grey"}>
            {text ? "Selected" : "Ongoing"}
          </Tag>
        );
      },
    },
    {
      title: "created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      render: (text, record: any) => {
        return <div> {moment(record?.createdAt).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Switch
              onClick={() => props.toggleStatus(record.id)}
              checked={record?.is_active}
            />
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record?.publishedAt ? "green" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record?.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <Button
                size="small"
                type="primary"
                onClick={() => props.answers(record)}
              >
                Answers
              </Button>
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => props?.onDelete(record)}
            >
              <IoTrashOutline color="red" size={20} cursor={"pointer"} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Table
          columns={columns}
          dataSource={props.data}
          className="commen-table-custom-scrollbar"
          pagination={false}
          size="small"
        />
        <br />
        <div style={{ float: "right" }}>
          <Pagination
            responsive
            defaultCurrent={props?.page}
            total={props?.meta}
            pageSize={props?.pagesize}
            onChange={(p, t) => {
              props.onPageChange(p, t);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DataTable;
