import {
  Space,
  Table,
  Tag,
  Popconfirm,
  Pagination,
  Tooltip,
  Button,
  Switch,
  Image,
} from "antd";
import "../styles.css";
import { ColumnsType } from "antd/es/table";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { IoShareSocialOutline, IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import NoImg from "../../../assets/image/noImg.jpg";

function DataTable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "Preview",
      key: "bannerDesk",
      dataIndex: "bannerDesk",
      width: 20,
      render: (bannerDesk, record: any) => {
        return (
          <Image
            width={40}
            height={40}
            style={{ borderRadius: 5, objectFit: "contain" }}
            src={bannerDesk}
            fallback={NoImg}
          />
        );
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 350,
      render: (text, record: any) => {
        return <div onClick={() => props.onEdit(record)}>{record?.title}</div>;
      },
    },
    {
      title: "head",
      dataIndex: "head",
      key: "head",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "12px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 60,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Switch
              onClick={() => props.toggleStatus(record.id)}
              checked={record?.is_active}
            />
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      width: 60,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={
                record?.is_deleted === true
                  ? "red"
                  : record?.publishedAt
                  ? "green"
                  : "blue"
              }
              style={{ fontSize: "10px" }}
            >
              {record?.is_deleted === true
                ? "Trash"
                : record?.publishedAt
                ? "Published"
                : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "12px" }}>{moment(text).format("lll")}</div>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div className="table-action">
            <div>
              <Button
                size="small"
                type="primary"
                onClick={() => props?.onpenQuestion(record)}
              >
                Questions
              </Button>
            </div>
            <div>
              <Button
                onClick={() => props.shareModal(record)}
                type="text"
                size="small"
              >
                <IoShareSocialOutline
                  size={20}
                  color="#000"
                  cursor={"pointer"}
                />
              </Button>
            </div>
            <div>
              <Button
                type="text"
                size="small"
                onClick={() => props.onEdit(record)}
              >
                <BiEdit size={20} color="#f5a442" />
              </Button>
            </div>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => props?.onDelete(record)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Table
          columns={columns}
          dataSource={props.data}
          className="commen-table-custom-scrollbar"
          pagination={false}
          size="small"
        />
        <br />
        <div style={{ float: "right" }}>
          <Pagination
            responsive
            defaultCurrent={props?.page}
            total={props?.meta}
            pageSize={props?.pagesize}
            onChange={(p, t) => {
              props.onPageChange(p, t);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DataTable;
