import "./style.css";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { VscKey } from "react-icons/vsc";
import { useSelector } from "react-redux";
import PageHeader from "../../component/pageheader";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/userslice";

function ProfileScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.User.user);
  const handleClick = () => {
    navigate("/Auth/editprofile", {
      state: {
        val: user,
      },
    });
  };
  const passwordClick = () => {
    navigate("/Auth/ChangePassword/form", {
      state: {
        val: user,
      },
    });
  };
  const logOut = () => {
    dispatch(logout(""));
    navigate("/");
  };
  return (
    <>
      <PageHeader
        title={"Profile"}
        onBtnPress={() => navigate("/")}
        goBack={() => navigate("/Auth")}
      />
      <div className="profileScrn-box1">
        <div className="profileScrn-Box2">
          <div className="profileScrn-Box3">
            <FaUserCircle size={100} color="grey" />
          </div>
          <div className="profileScrn-Box4" onClick={handleClick}>
            <div className="profileScrn-Box6">
              <div>
                <MdOutlineModeEdit color="black" size={22} />
              </div>
              <div className="profileScrn-Txt5">Edit Profile</div>
            </div>
            <div>
              <AiOutlineRight size={20} color="grey" />
            </div>
          </div>
          <div className="profileScrn-Box4" onClick={passwordClick}>
            <div className="profileScrn-Box6">
              <div>
                <VscKey color="black" size={22} />
              </div>
              <div className="profileScrn-Txt5">Change Password</div>
            </div>
            <div>
              <AiOutlineRight size={20} color="grey" />
            </div>
          </div>
          <div className="profileScrn-Box4" onClick={logOut}>
            <div className="profileScrn-Box6">
              <div>
                <BiLogOutCircle color="black" size={22} />
              </div>
              <div className="profileScrn-Txt5">Log-out</div>
            </div>
            <div>
              <AiOutlineRight size={20} color="grey" />
            </div>
          </div>
        </div>
        <div className="profileScrn-Box5">
          <div className="profileScrn-Txt1">Profile Information</div>
          <Row>
            <Col md={4}>
              <div className="profileScrn-Txt3">Username</div>
              <div className="profileScrn-Txt2">{user.username}</div>
            </Col>
            <Col md={8}>
              <div className="profileScrn-Txt3">E-mail</div>
              <div className="profileScrn-Txt4">{user.email}</div>
            </Col>
            <Col md={4}>
              <div className="profileScrn-Txt3">Role</div>
              <div className="profileScrn-Txt4">{user.role.name}</div>
            </Col>
            <Col md={8}>
              <div className="profileScrn-Txt3">Phone-Number</div>
              <div className="profileScrn-Txt4">
                {user.phone_number ? user.phone_number : "N/A"}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ProfileScreen;
