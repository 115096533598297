import React, { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input, Select, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Loadingbox from "../../component/loadingbox";
import ReactQuill from "react-quill";
import BannerImage from "../quiz/components/bannerImages";

function CreateCampaign() {
  const [form] = Form.useForm();
  let { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<any>(true);
  const [data, setData] = useState({}) as any;
  const [tags, setTags] = useState<any>([]);
  const [description, setDescription] = useState<any>(null);
  const [bannerDesk, setBannerDesk] = useState<any>(null);
  const [bannerMob, setBannerMob] = useState<any>(null);

  useEffect(() => {
    if (id !== "create") {
      getData();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getData = async () => {
    try {
      if (id !== "create") {
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {
          color: ["red", "blue", "yellow"],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <div>
      <PageHeader title={"Campaign"} length={"Dashboard / Campaign / Create"} />
      <br />
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div>
            <Form form={form} initialValues={{}}>
              <Row>
                <Col sm={5}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item
                    name={"title"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Heading</label>
                  <Form.Item
                    name={"head"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Perma-Link</label>
                  <Form.Item
                    name={"permalink"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Tags</label>
                  <Form.Item
                    name="tags"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      style={{ background: "transparent" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {tags.length &&
                        tags.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item.id}>
                              {item.tag}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <br />
                </Col>
                <Col sm={7}>
                  <label className="newsFormLabels">Description</label>
                  <ReactQuill
                    style={{ height: "150px" }}
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                  />
                  <br />
                  <br />
                  <div style={{ margin: 5 }} />
                  <Row>
                    <Col sm={6}>
                      <label className="newsFormLabels">Desktop Banner</label>
                      <BannerImage
                        image={bannerDesk}
                        onChagne={(value: any) => setBannerDesk(value)}
                      />
                    </Col>
                    <Col sm={6}>
                      <label className="newsFormLabels">
                        Mobile Banner{" "}
                        <span style={{ color: "grey", fontSize: 10 }}>
                          (optional)
                        </span>
                      </label>
                      <BannerImage
                        image={bannerMob}
                        onChagne={(value: any) => setBannerMob(value)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="FixedFooter">
                <Container fluid>
                  <Row>
                    <Col sm={9} xs={12}></Col>
                    <Col sm={1} xs={6}>
                      <Button
                        size="large"
                        type="text"
                        danger
                        block
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col sm={2} xs={6}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Form>
          </div>
        )}
      </Container>
    </div>
  );
}

export default CreateCampaign;
