import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import "../styles.css";

function NewsItem2(props: any) {
  return (
    <div className="newsItems1Box">
      <img
        src={props?.item?.attributes?.image}
        className="NewsItem2Img"
        alt="No Image"
      />
      {props.item && (
        <>
          <p className="NewsItem2Txt1">
            {props.item?.attributes?.head && props.item.attributes.head}
          </p>
          {props.item.attributes.title ? (
            <div
              style={{ fontSize: 14, marginBottom: 5 }}
              dangerouslySetInnerHTML={{
                __html: props?.item?.attributes?.title,
              }}
            ></div>
          ) : null}
        </>
      )}
      {props.item?.attributes?.createdAt && (
        <div className="newsItemsTxt2">
          <span className="newsItemsTxt3">
            {props?.item?.attributes?.Subcategories?.data[0] &&
              props?.item?.attributes?.Subcategories?.data[0]?.attributes?.name}
          </span>
          &#x2022; {moment(props?.item?.attributes?.createdAt).fromNow()}
        </div>
      )}
    </div>
  );
}

export default NewsItem2;
