import { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import Loadingbox from "../../component/loadingbox";
import DataTable from "./component/datatable";
import { API } from "../../config/API";
import axios from "axios";
import "./styles.css";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { GetPolls } from "./queries/getPolls";
import { PUT } from "../../utils/apirequest";
import Result from "./component/result";

function PollScreen() {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [resultModal, setResultModal] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    loadData(page, pageSize);
  }, []);

  const loadData = async(page: any, pageSize: any) => {
    let result = await GetPolls(null,page,pageSize);
    if(result.status){
      let data = result?.data?.data;
      let metaData = result?.data?.meta?.pagination?.total;
      setData(data);
      setMeta(metaData);
      setIsloading(false);
    } else {
      setIsloading(false);
      message.error("Something went wrong...!");
    }
  };

  const upload = (val: any) => {
    let url = API.BASE_URL + API.MAKE_VISIBLE + `/${val.id}`;
    axios
      .put(url)
      .then((response) => {
        loadData(page, pageSize);
        message.success("Sucessfully Published");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const onEdit = (val:any) => {
    navigate(`/Auth/poll/form/${val.id}`)
  }

  const addToTrash = async (val: any) => {
    let url = API.POLLS + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(page, pageSize);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const UndoTrash = async (val: any) => {
    let url = API.POLLS + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(page, pageSize);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const showResults = (val:any) => {
    setItem(val)
    setResultModal(true)
  }

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Polls"} length={meta}>
          <Button
            type="primary"
            onClick={() => navigation("/Auth/poll/form/create")}
          >
            Add New +
          </Button>
      </PageHeader>
      {isloading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          refresh={() => loadData(page, pageSize)}
          onEdit={(val: any) => onEdit(val)}
          showResults={(val: any) => showResults(val)}
          upload={(val: any) => upload(val)}
          onTrash={(val: any) => addToTrash(val)}
          unTrash={(val: any) => UndoTrash(val)}
        />
      )}
      {
        resultModal ? <Result open={resultModal} item={item} onCancel={() => setResultModal(false)}/> :null
      }
    </div>
  );
}

export default PollScreen;
