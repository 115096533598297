import { IoIosCloseCircleOutline } from "react-icons/io";

import { Modal, Progress } from "antd";

function Result(props: any) {
  return (
    <Modal
      open={props.open}
      onCancel={props.onCancel}
      closeIcon={<IoIosCloseCircleOutline />}
      footer={false}
      title={<h6>Results</h6>}
    >
      <div style={{ margin: 20 }} />
      <h6 className="poll-Result-txt1">{props?.item?.attributes?.questions}</h6>
      <div style={{ margin: 20 }} />
      {props?.item?.attributes.poll_options.data.map((item: any) => {
        return (
          <div className="poll-Result-box1">
            <div className="poll-Result-txt2">{item?.attributes?.options}</div>
            <Progress percent={item?.attributes?.vote} size={[300, 20]} />
          </div>
        );
      })}
      <br />
    </Modal>
  );
}

export default Result;
