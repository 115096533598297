import { Button, Modal, Form, Input, Switch, message } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import ImageUploader from "./imageUploader";
import { POST, PUT } from "../../../utils/apirequest";
import { API } from "../../../config/API";

export default function AddsModal(props: any) {
  const [form] = Form.useForm();
  let ifGoogle =
    props?.value && props?.value?.value && props?.value?.value.type === "google"
      ? true
      : false;
  const [loading, setLoading] = useState(false);
  const [googleadds, setGoogleAdds] = useState(ifGoogle);
  const [desktopAd, setDesktopAd] = useState(props?.value?.value?.desktop);
  const [mobileAd, setMobileAd] = useState(props?.value?.value?.mobile);

  const onFinish = async (val: any) => {
    try {
      setLoading(true);
      let obj = {
        data: {
          screen: props.value.screen,
          section: props.value.field,
          desktop: desktopAd,
          mobile: mobileAd,
          type: googleadds ? "google" : "custom",
          text: "text",
          hyperlink: val.hyperlink,
          iframe: val.iframe,
        },
      };
      let url =
        props.mode === "POST"
          ? API.ADVERTISEMENT_ADD
          : API.ADVERTISEMENT_EDIT + props?.value?.value?.id;
      let METHOD = props.mode === "POST" ? POST : PUT;
      let res: any = await METHOD(url, obj);
      if (res.data.id) {
        message.success("Success");
        setLoading(false);
        props.close();
      } else {
        setLoading(false);
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong...!");
    }
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      width={700}
      footer={false}
      centered
    >
      <div>
        <Container fluid={false} style={{ margin: 0, padding: 0 }}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              hyperlink: props?.value && props?.value?.value?.hyperlink,
              iframe: props?.value && props?.value?.value?.iframe,
            }}
          >
            <Row>
              <Col md={6}>
                <label className="newsFormLabels">DeskTop Banner</label>
                <Form.Item>
                  <ImageUploader
                    type="DeskTop"
                    imageUrl={props?.value?.value?.desktop}
                    setImage={(val: any) => setDesktopAd(val)}
                    removeImage={() => setDesktopAd(null)}
                  />
                  <div>{props?.desktopSize}</div>
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="newsFormLabels">Mobile Banner</label>
                <Form.Item>
                  <ImageUploader
                    type="Mobile"
                    imageUrl={props?.value?.value?.mobile}
                    setImage={(val: any) => setMobileAd(val)}
                    removeImage={() => setMobileAd(null)}
                  />
                   <div>{props?.mobileSize}</div>
                </Form.Item>
              </Col>
              <label className="newsFormLabels">Hyper Link</label>
              <Form.Item
                name="hyperlink"
                tooltip={"Place the link you wanna redirect to"}
              >
                <Input size="large" />
              </Form.Item>

              {googleadds ? (
                <>
                  <label className="newsFormLabels">
                    Place your HTML code{" "}
                  </label>
                  <Form.Item name="iframe">
                    <TextArea rows={6} />
                  </Form.Item>
                </>
              ) : null}
              <Col sm={6} xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Switch
                    onChange={() => setGoogleAdds(!googleadds)}
                    checked={googleadds}
                  />
                  &nbsp; &nbsp;
                  <div>Google AdSense</div>
                </div>
              </Col>
              <Col sm={3} xs={3}>
                <Button
                  block
                  style={{ height: 50 }}
                  onClick={() => props.close()}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={3} xs={3}>
                <Button
                  block
                  type="primary"
                  style={{ height: 50 }}
                  htmlType="submit"
                  loading={loading}
                >
                  {props.mode === "POST" ? "Submit" : "Update"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </Modal>
  );
}
