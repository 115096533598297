import { Col, Row } from "react-bootstrap";
import PageHeader from "../../component/pageHeaders";
import TextArea from "antd/es/input/TextArea";
import { Button, Card, Form, Tabs, TabsProps, message } from "antd";
import "./styles.css";
import { GET, POST, PUT } from "../../utils/apirequest";
import { API } from "../../config/API";
import { useEffect, useState } from "react";
import Loadingbox from "../../component/loadingbox";
import ImagePicker from "../electionScreen/components/imagePicker";

function Settings() {
  const [form] = Form.useForm();
  const [id, setId] = useState();
  const [isloading1, setisLoading1] = useState(true);
  const [isloading2, setisLoading2] = useState(false);
  const [type, setType] = useState(null);
  const [data, setData] = useState({}) as any;
  const [image, setImage] = useState() as any;

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    try {
      let url = API.SETTINGS_LIST;
      let res: any = await GET(url, null, null);
      if (res?.data) {
        setId(res?.data[0]?.id);
        setData(res?.data[0]?.attributes);
        setImage(res?.data[0]?.attributes?.logo);
      }
    } catch (er) {
    } finally {
      setisLoading1(false);
    }
  };

  const onFinish = async (type: any) => {
    try {
      setisLoading2(true);
      setType(type);
      let values: any = form.getFieldsValue();
      let obj = {
        data: {
          whatsappLinkAttachment: values.whatsappLinkAttachment,
          whatasppLink: values.whatasppLink,
          logo: image,
          whatsappJoinLink: values?.whatsappJoinLink,
        },
      };
      let url = id ? API.SETTINGS_ADD + `/${id}` : API.SETTINGS_ADD;
      let METHOD = id ? PUT : POST;
      let res: any = await METHOD(url, obj);
      if (res?.data?.id) {
        message.success("Success");
        LoadData();
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setisLoading2(false);
    }
  };

  return (
    <div>
      <PageHeader title={"Settings"} length={"Settings"}></PageHeader>
      <br />
      {isloading1 ? (
        <Loadingbox />
      ) : (
        <Form
          form={form}
          initialValues={{
            whatsappLinkAttachment: data?.whatsappLinkAttachment,
            whatasppLink: data?.whatasppLink,
            whatsappJoinLink: data?.whatsappJoinLink,
          }}
        >
          <Row>
            <Col xs="4">
              <Card title={"Whatsapp Link Attachment"}>
                <Form.Item name="whatsappLinkAttachment">
                  <TextArea rows={8} />
                </Form.Item>
                <Row>
                  <Col sm="8"></Col>
                  <Col sm="4">
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={type === "attachment" && isloading2}
                      onClick={() => onFinish("attachment")}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="4">
              <Card title={"Whatsapp Link"}>
                <Form.Item name="whatasppLink">
                  <TextArea rows={8} />
                </Form.Item>
                <Row>
                  <Col sm="8"></Col>
                  <Col sm="4">
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={type === "link" && isloading2}
                      onClick={() => onFinish("link")}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="4">
              <Card title={"Logo"}>
                <Form.Item name="logo">
                  <ImagePicker
                    image={image}
                    onChagne={(value: any) => setImage(value)}
                  />
                </Form.Item>
                <Row>
                  <Col sm="8"></Col>
                  <Col sm="4">
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={type === "logo" && isloading2}
                      onClick={() => onFinish("logo")}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="4">
              <Card title={"Whatsapp Join Link"}>
                <Form.Item name="whatsappJoinLink">
                  <TextArea rows={8} />
                </Form.Item>
                <Row>
                  <Col sm="8"></Col>
                  <Col sm="4">
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={type === "Joinlink" && isloading2}
                      onClick={() => onFinish("Joinlink")}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}

export default Settings;
