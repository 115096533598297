import {
  Space,
  Table,
  Tag,
  Popconfirm,
  Pagination,
  Tooltip,
  Button,
  Switch,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { IoTrashOutline } from "react-icons/io5";
import { IoShareSocialOutline } from "react-icons/io5";
import moment from "moment";
import { SiMicrosoftexcel } from "react-icons/si";
import { useState } from "react";
import * as XLSX from "xlsx";
import { API } from "../../config/API";
import { POST } from "../../utils/apirequest";

function DataTable(props: any) {
  const [isLoading, setIsloading] = useState(false);
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "Preview",
      key: "image",
      dataIndex: "image",
      width: 20,
      render: (text, record: any) => {
        return (
          <div>
            {record?.image ? (
              <img
                key={record?.image}
                src={record?.image}
                alt="Table Image"
                style={{ height: 40, borderRadius: 6 }}
              />
            ) : (
              <div className="homescreen-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "head",
      dataIndex: "head",
      key: "head",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Switch
              onClick={() => props.toggleStatus(record)}
              checked={record?.publishedAt}
            />
          </Space>
        );
      },
    },
    {
      title: "created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record: any) => {
        return <div>{moment(text).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={
                record?.is_deleted === true
                  ? "red"
                  : record?.publishedAt
                  ? "green"
                  : "blue"
              }
              style={{ fontSize: "10px" }}
            >
              {record?.is_deleted === true
                ? "Trash"
                : record?.publishedAt
                ? "Published"
                : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div className="table-action">
            <div>
              <Button
                onClick={() => generateExcel(record.id)}
                loading={isLoading}
                type="text"
                size="small"
              >
                <SiMicrosoftexcel size={18} cursor={"pointer"} />
              </Button>
            </div>
            <div>
              <Button
                onClick={() => props.shareModal(record)}
                type="text"
                size="small"
              >
                <IoShareSocialOutline
                  size={20}
                  color="#000"
                  cursor={"pointer"}
                />
              </Button>
            </div>
            <div>
              <Button
                onClick={() => props.onEdit(record)}
                type="text"
                size="small"
              >
                <BiEdit size={20} color="#f5a442" cursor={"pointer"} />
              </Button>
            </div>
            <div>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </div>

            {record.is_deleted === true ? (
              <div>
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => props?.onDelete(record)}
                >
                  <Button size="small" danger style={{ fontSize: 12 }}>
                    Remove
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const generateExcel = async (id: any) => {
    try {
      setIsloading(true);
      let obj = {
        formId: id,
      };
      let URL = API.DYNAMIC_FORM_RESPONSES;
      let data: any = await POST(URL, obj);
      const arr: any = [];

      data.forEach((ite: any) => {
        let item = ite.data !== "[object Object]" && JSON.parse(ite.data);
        let formattedItem: any = {};
        Object.keys(item).forEach((key) => {
          formattedItem[key] = item[key];
        });
        arr.push(formattedItem);
      });

      const allKeys = new Set();
      arr.forEach((item: any) => {
        Object.keys(item).forEach((key) => {
          allKeys.add(key);
        });
      });

      const headers = Array.from(allKeys);
      const dataArray = arr.map((item: any) =>
        headers.map((header: any) => item[header] || "")
      );
      dataArray.unshift(headers);

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(dataArray);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "report.xlsx");
      setIsloading(false);
    } catch (err) {
      console.log(err);
      setIsloading(false);
    }
  };

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Table
          columns={columns}
          dataSource={props.data}
          className="commen-table-custom-scrollbar"
          pagination={false}
          size="small"
        />
        <br />
        <div style={{ float: "right" }}>
          <Pagination
            responsive
            defaultCurrent={props?.page}
            total={props?.meta}
            pageSize={props?.pagesize}
            onChange={(p, t) => {
              props.onPageChange(p, t);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DataTable;
