import { Popover, Avatar, Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/userslice";

import { CgProfile } from "react-icons/cg";

function ProfilePopup() {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const User = useSelector((state: any) => state.User.user);

  const logOut = () => {
    dispatch(logout(""));
    navigation("/");
  };
  const popovercontent = (
    <div className="popovercontent">
      <CgProfile size={50} color="#0055a6" />
      <div className="username">{User.username}</div>
      <div className="headerDiscriptioncanvas">{User.email}</div>
      <br />
      <Button block type="primary" onClick={() => navigation("/Auth/profile")}>
        Manage Profile
      </Button>
      <div style={{ margin: 5 }} />
      <Button block danger onClick={logOut}>
        Logout
      </Button>
    </div>
  );
  return (
    <Popover content={popovercontent} trigger="click">
      <CgProfile size={35} color="grey"/>
    </Popover>
  );
}

export default ProfilePopup;
