import { useState } from "react";
import { Modal, Button, Input, Form, notification, message } from "antd";
import { Row, Col } from "react-bootstrap";
import { API } from "../../../config/API";
import { EditNews } from "../utils/apiRequest";
const SocialShare = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(props?.image);
  const [title, setTitle] = useState(props?.data?.head);
  const [description, setDescription] = useState(props?.data?.head);

  const onValuesChange = (val: any) => {
    if (val.meta_title) {
      setTitle(val.meta_title);
    } else if (val.meta_description) {
      setDescription(val.meta_description);
    }
  };

  const onFinish = async (val: any) => {
    setLoading(false);
    try {
      let body = {
        data: {
          meta_title: val.meta_title,
          meta_description: val.meta_description,
        },
      };
      let id = props.data.id;
      const res: any = await EditNews(id, body);
      if (res.data.id) {
        notification.success({
          message: "Success",
        });
        setLoading(false);
        props.onCancel();
      } else {
        setLoading(false);
        message.error("Something went wrong....!");
      }
    } catch (err) {
      setLoading(false);
      message.error("Something went wrong....!");
      console.log("res", err);
    }
  };

  return (
    <Modal visible={props.visible} onCancel={props.onCancel} footer={false}>
      <br />
      <div className="SocialShare-box1">
        <div className="SocialShare-box2">
          <img
            src={image}
            alt="social-sharepreview"
            className="SocialShare-img1"
            width={"100%"}
          />
        </div>
        <div className="SocialShare-box3">
          <div className="SocialShare-txt1">{API.APP_URL.toUpperCase()}</div>
          <div className="SocialShare-txt2">{title}</div>
          <div className="SocialShare-txt3">{description}</div>
        </div>
      </div>
      <br />
      <Form
        initialValues={{
          meta_title:
            props?.data && props?.data?.meta_title
              ? props?.data?.meta_title
              : props?.data?.head,
          meta_description:
            props?.data && props?.data && props?.data?.meta_description
              ? props?.data?.meta_description
              : props?.data?.head,
        }}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <div>Title</div>
        <Form.Item name="meta_title">
          <Input />
        </Form.Item>
        <div>Description</div>
        <Form.Item name="meta_description">
          <Input />
        </Form.Item>
        <br />
        <br />
        <Row>
          <Col xs="6"></Col>
          <Col xs="3">
            <Button block onClick={() => props.onCancel()}>
              Cancel
            </Button>
          </Col>
          <Col xs="3">
            <Button block type="primary" htmlType="submit" loading={loading}>
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SocialShare;
