import React, { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Image, Tooltip } from "antd";
import { FiPlusCircle } from "react-icons/fi";
import NewFormNjayarArticles from "./newform";
import { API } from "../../../config/API";
import { Card, Space, Table, Tag, Popconfirm, message, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiEditBoxLine } from "react-icons/ri";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { IoCopyOutline, IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { PUT } from "../../../utils/apirequest";
import { IoMdOpen } from "react-icons/io";
import moment from "moment";
import Loadingbox from "../../../component/loadingbox";

function NjayarArticles() {
  const User = useSelector((state: any) => state.User.user);

  let { id }: any = useParams();
  let location = useLocation();

  const navigate = useNavigate();
  const [loading, setIsloading] = useState(false);
  const [data, setData] = useState<any>();
  const [meta, setMeta] = useState<any>();
  const [responseId, setResponseId] = useState<any>();
  const [settings, setSettings] = useState<any>();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsloading(true);
    let url =
      API.BASE_URL +
      API.NJAYAR_PRABHAADHAM +
      `?filters[masterid][$eq]=${id}&sort=createdAt:asc`;
    axios
      .get(url)
      .then((response) => {
        let data = response.data.data;
        let metaData = response.data.meta.pagination;
        setData(data);
        setMeta(metaData);
        getSettings();
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };
  const getSettings = () => {
    let url = API.BASE_URL + API.SETTINGS_LIST;
    axios.get(url).then((response) => {
      setSettings(response?.data?.data[0]?.attributes);
    });
  };

  const setOPenForm = () => {
    navigate(`/Auth/NjayarScreen/NewFormNjayarArticles/${id}`);
  };
  const setEdit = (val: any) => {
    navigate(`/Auth/NjayarScreen/NewFormNjayarArticles/${id}`, {
      state: { item: val },
    });
  };

  const itemsArticles = data?.map((item: any) => item.id);

  const addToTrash = async (val: any) => {
    let url = API.BANNERS + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData();
    } else {
      message.error("Something went wrong...!");
    }
  };

  const UndoTrash = async (val: any) => {
    let url = API.BANNERS + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData();
    } else {
      message.error("Something went wrong...!");
    }
  };

  const generateLink = (record: any, type: any) => {
    let link = record?.attributes?.permalink
      ? record?.attributes?.permalink?.replace(/\s+/g, "-")
      : record?.attributes?.title?.replace(/\s+/g, "-");
    let url = `${API.APP_URL}njayarprabhaatham?id=${record?.id}&link=${link}`;
    let body = `${record?.attributes?.title} \nRead more at: ${url} ${
      settings?.whatsappJoinLink
        ? `\nStay updated with the latest news! Join our WhatsApp group: ${settings?.whatsappJoinLink}`
        : null
    }`;
    if (type === "copy") {
      navigator.clipboard.writeText(body).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(url, "_blank");
    }
  };

  const updateNjayar = () => {
    setIsloading(true);
    let requestObj = {
      data: {
        head: location?.state?.items?.attributes?.head,
        items: itemsArticles,
      },
    };
    let url = API.BASE_URL + API.NJAYAR_MASTER + `/${id}`;
    let method = "PUT";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        setResponseId(response.data.data);
        setIsloading(false);
        message.success(
          <>
            Success : <b>Added to Njayar Prabhaatham</b>
          </>
        );
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const scrollConfig = {
    x: "100vh",
    y: "50vh",
    scrollToFirstRowOnChange: true,
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "image",
      key: "image1",
      dataIndex: "image1",
      render: (text, record: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {record.attributes.image1 ? (
              <Image
                key={record.attributes.image1}
                src={record.attributes.image1}
                style={{ width: 70, borderRadius: 6 }}
                alt="Table Image"
              />
            ) : (
              <div className="NjayarArticles-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Tittle",
      dataIndex: "title",
      key: "title",
      width: 500,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px", cursor: "pointer" }}
            onClick={() => setEdit(record)}
          >
            {record.attributes.title}
          </div>
        );
      },
    },
    {
      title: "Author",
      dataIndex: "Author",
      key: "Author",
      width: 500,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px", cursor: "pointer" }}
            onClick={() => setEdit(record)}
          >
            {record.attributes.Author}
          </div>
        );
      },
    },
    {
      title: "Date-Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 12 }}
            onClick={() => setEdit(record)}
          >
            {moment(record?.attributes?.createdAt).format("DD-MM-YYYY-h:mm a")}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record.attributes.publishedAt ? "green" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record.attributes.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            {record?.attributes?.publishedAt && (
              <>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoMdOpen
                    color="#666"
                    cursor={"pointer"}
                    size={20}
                    onClick={() => generateLink(record, "preview")}
                  />
                </Space>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoCopyOutline
                    size={20}
                    color="green"
                    cursor={"pointer"}
                    onClick={() => generateLink(record, "copy")}
                  />
                </Space>
              </>
            )}
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => setEdit(record)}
              />
            </Space>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => onDeleteItem(record.id)}
            >
              <Space size="middle">
                <RiDeleteBin6Line size={20} color="gray" />
              </Space>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.NJAYAR_PRABHAADHAM + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        // props.refresh();
        loadData();
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };
  const handleUrlCopy = (type: any) => {
    const perma = responseId?.attributes?.permalink;
    let formattedPerma = perma?.replace(/\s+/g, "-");
    const link = `${API.APP_URL}njayarprabhaatham?id=${responseId.id}&&${
      formattedPerma ? formattedPerma : ""
    }`;
    if (type === "copy") {
      navigator.clipboard.writeText(link).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(link, "_blank");
    }
  };

  return (
    <div>
      <PageHeader title={"Njayar prabhaatham articles"}></PageHeader>
      <div style={{ border: "1px solid #f0f2f5" }}> </div>
      <div className="NjayarArticles-Box1">
        {responseId?.id ? (
          <>
            <Button
              onClick={() => handleUrlCopy("preview")}
              style={{ marginRight: "15px" }}
            >
              Web Preview
            </Button>
          </>
        ) : null}
        <Button type="primary" onClick={setOPenForm}>
          Add Articles &nbsp; <FiPlusCircle />
        </Button>
      </div>
      {loading ? (
        <Loadingbox />
      ) : (
        <div className="">
          <Table
            columns={columns}
            dataSource={data}
            className="commen-table-custom-scrollbar"
            pagination={false}
            size="small"
          />
          <br />
          {data?.length ? (
            <div style={{ float: "right" }}>
              <Button type="primary" onClick={() => updateNjayar()}>
                Add Articles to Njayar Prabhaatham
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NjayarArticles;
