import React, { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { Button, DatePicker, Input, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import { DELETE, POST, PUT } from "../../utils/apirequest";
import { API } from "../../config/API";
import moment from "moment";
import Loadingbox from "../../component/loadingbox";
import DataTable from "./datatable";
import ShareModal from "./components/shareModal";

function Form() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;
  const [draft, setDraft] = useState();
  const [item, setItem] = useState({});
  const [shareModal, setShareModal] = useState(false);

  useEffect(() => {
    loadData(filter);
  }, []);

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };

  const loadData = async (filter: any) => {
    try {
      setIndicator(true);
      let url = API.DYNAMIC_FORM_LIST;
      const res: any = await POST(url, filter);
      if (res?.status) {
        setData(res?.data);
        setIsloading(false);
        setIndicator(false);
        setPublishCount(res?.publishedCount);
        setTrashcount(res?.trashedCount);
        setDraft(res?.draftCount);
      }
    } catch (err) {
      console.log("err", err);
      setIsloading(false);
      setIndicator(false);
      message.error("Something went wrong...");
    }
  };

  const LoadStatus = (type: any) => {
    let filters = {
      ...filter,
      type: type,
    };
    setFilter(filters);
    loadData(filters);
  };

  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/form/${val?.id}`);
  };

  const viewResponses = (val: any) => {
    navigate(`/Auth/form-answers/${val?.id}`);
  };

  const addToTrash = async (val: any, type: any) => {
    let url = API.DYNAMIC_FORM_EDIT + `${val.id}`;
    let obj = { data: { is_deleted: type } };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.DYNAMIC_FORM_EDIT + `${val.id}`;
      let response: any = await DELETE(url);
      if (response) {
        loadData(filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const toggleStatus = async (val: any) => {
    let URL = API.DYNAMIC_FORM_EDIT + val?.id;
    let obj = {
      data: {
        publishedAt: val.publishedAt ? null : new Date(),
      },
    };
    let res: any = await PUT(URL, obj);
    if (res?.data?.id) {
      message.success("Status Changed");
      loadData(filter);
    }
  };

  const openShareModal = (item: any) => {
    setItem(item);
    setShareModal(true);
  };

  return (
    <>
      <div style={{ height: "90vh", overflow: "scroll" }}>
        <PageHeader title={"Form"} loader={0} length={0}>
          <div className="homescreen-pageItem">
            <div style={{ width: 250 }}>
              <Input
                allowClear
                placeholder="Search Form"
                onChange={(e: any) => Search(e.target.value)}
              />
            </div>
            &nbsp;
            <div className="homescreen-pageBox">
              <div
                className="homescreen-pageItem"
                onClick={() => LoadStatus("published")}
              >
                <span style={{ color: "green" }}>Published</span> : &nbsp;
                <b>{publishCount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadStatus("trash")}
              >
                <span style={{ color: "red" }}>Trashed</span> : &nbsp;
                <b>{trashcount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadStatus("draft")}
              >
                <span style={{ color: "#0055a6" }}>Draft</span> : &nbsp;
                <b>{draft}</b>
              </div>
            </div>
            &nbsp;
            <Button
              type="primary"
              onClick={() => navigate("/Auth/form/create")}
            >
              New Form +
            </Button>
          </div>
        </PageHeader>
        <div style={{ padding: 5 }} />
        <div className="homescreen-pageItem">
          <div
            style={{
              flex: 5,
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(filter)?.length > 2 ? "Filter By :" : "All Forms"}{" "}
            &nbsp;
            {Object.keys(filter)?.map(
              (key, index) =>
                key !== "start" &&
                key !== "limit" && (
                  <Tag style={{ marginBottom: 5 }}>
                    {`${key}:  ${
                      key === "createdAt"
                        ? moment(filter[key]).format("DD-MM-YYYY")
                        : filter[key]
                    }`}{" "}
                    <CloseCircleOutlined onClick={() => clearFilters(key)} />
                  </Tag>
                )
            )}
          </div>
          <div>
            <DatePicker
              allowClear
              style={{ width: 200 }}
              onChange={onChangeDate}
            />
          </div>
        </div>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <DataTable
            meta={meta}
            data={data}
            page={page}
            pagesize={pageSize}
            onEdit={(val: any) => onEdit(val)}
            onTrash={(val: any) => addToTrash(val, true)}
            unTrash={(val: any) => addToTrash(val, false)}
            onDelete={(val: any) => onDelete(val)}
            refresh={() => loadData(filter)}
            viewResponses={(val: any) => viewResponses(val)}
            onPageChange={(page: any, pageSize: any) =>
              onPageChange(page, pageSize)
            }
            toggleStatus={(val: any) => toggleStatus(val)}
            shareModal={(item: any) => openShareModal(item)}
          />
        )}
        {ShareModal ? (
          <ShareModal
            item={item}
            visible={shareModal}
            onCancel={() => setShareModal(false)}
          />
        ) : null}
      </div>
    </>
  );
}

export default Form;
