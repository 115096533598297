import { useState, useEffect } from "react";
import PageHeader from "../../component/pageHeaders/index";
import { useNavigate } from "react-router-dom";
import "./style.css";
import DataTableBanner from "./component/dataTable";
import { API } from "../../config/API";
import axios from "axios";
import Loadingbox from "../../component/loadingbox";
import { PUT } from "../../utils/apirequest";
import { CloseCircleOutlined } from "@ant-design/icons";

import { Button, DatePicker, Input, Select, Tag, message } from "antd";
import moment from "moment";
import { LoadCategories } from "../../utils/commonapis";

const BannerScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [show, setShow] = useState(false);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [tag, setTAg] = useState<any[]>([]);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
    loadCategories();
    loadTag();
  }, []);

  const loadData = (filter: any) => {
    let url = API.BASE_URL + API.GET_BANNERS;
    axios
      .post(url, filter)
      .then((response) => {
        let imageData = response.data.data;
        let metaData = response.data?.totalCount;
        setData(imageData);
        setPublishCount(response.data?.publishedCount);
        setTrashcount(response.data?.trashedCount);
        setMeta(metaData);
        setIsloading(false);
        setIndicator(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };
  const loadCategories = async () => {
    const res: any = await LoadCategories();
    setCategoryList(res.data);
  };

  const loadTag = () => {
    let url =
      API.BASE_URL + API.TAGS_LIST + `?pagination[page]=1&pagination[pageSize]=100`;
    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
          setTAg(json.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addToTrash = async (val: any) => {
    let url = API.BANNERS + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const UndoTrash = async (val: any) => {
    let url = API.BANNERS + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadPublished = () => {
    let filters = {
      ...filter,
      type: "published",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadTrashed = () => {
    let filters = {
      ...filter,
      type: "trash",
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };
  const CategoryChange = (category: any) => {
    let filters = {
      ...filter,
      catId: category,
    };
    setFilter(filters);
    loadData(filters);
  };
  const TAgChange = (tag: any) => {
    let filters = {
      ...filter,
      tagId: tag,
    };
    setFilter(filters);
    loadData(filters);
  };
  const Live = (prio: any) => {
    let filters = {
      ...filter,
      handleShow: prio,
    };
    setFilter(filters);
    loadData(filters);
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/banner/Create`, { state: { items: val } });
  };

  return (
    <>
      <div style={{ height: "90vh", overflow: "scroll" }}>
        <PageHeader title={"Banner"} loader={indicator} length={meta}>
          <div className="homescreen-pageItem">
            <div style={{ width: 250 }}>
              <Input
                allowClear
                placeholder="Search Banner "
                onChange={(e: any) => Search(e.target.value)}
              />
            </div>
            &nbsp;
            <div className="homescreen-pageBox">
              <div
                className="homescreen-pageItem"
                onClick={() => LoadPublished()}
              >
                <span style={{ color: "green" }}>Published</span> : &nbsp;
                <b>{publishCount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadTrashed()}
              >
                <span style={{ color: "red" }}>Trashed</span> : &nbsp;
                <b>{trashcount}</b>
              </div>
            </div>
            &nbsp;
            <Button
              type="primary"
              onClick={() => navigate("/Auth/banner/Create")}
            >
              New Entry +
            </Button>
          </div>
        </PageHeader>
        <div style={{ padding: 5 }} />
        <div className="homescreen-pageItem">
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(filter)?.length > 2 ? "Filter By :" : "All Banners"}{" "}
            &nbsp;
            {Object.keys(filter)?.map(
              (key, index) =>
                key !== "start" &&
                key !== "limit" && (
                  <Tag style={{ marginBottom: 5 }}>
                    {`${key}:  ${
                      key === "createdAt"
                        ? moment(filter[key]).format("DD-MM-YYYY")
                        : filter[key]
                    }`}{" "}
                    <CloseCircleOutlined onClick={() => clearFilters(key)} />
                  </Tag>
                )
            )}
          </div>
          <div style={{ flex: 1 }}>
            <DatePicker
              allowClear
              style={{ width: 200 }}
              onChange={onChangeDate}
            />
            &nbsp;
            <Select
              showSearch={true}
              placeholder="Category"
              onChange={(val) => CategoryChange(val)}
              style={{ width: 200 }}
              allowClear
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {categoryList.length > 0 &&
                categoryList.map((item: any, index: any) => {
                  return (
                    <Select.Option key={index} value={item.id}>
                      {item.attributes.category}
                    </Select.Option>
                  );
                })}
            </Select>
            &nbsp;
            <Select
              showSearch={true}
              placeholder="Tags"
              onChange={(val) => TAgChange(val)}
              style={{ width: 200 }}
              allowClear
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {tag.length > 0 &&
                tag.map((item: any, index: any) => {
                  return (
                    <Select.Option key={index} value={item.id}>
                      {item.attributes.tag}
                    </Select.Option>
                  );
                })}
            </Select>
            &nbsp;
            <Select
              showSearch={true}
              placeholder="Status"
              onChange={(val) => Live(val)}
              style={{ width: 200 }}
              allowClear
            >
              <Select.Option key={"true"}>live</Select.Option>
              <Select.Option key={"false"}>Not in Live</Select.Option>
            </Select>
          </div>
        </div>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div style={{ height: "80vh", overflow: "scroll" }}>
            <DataTableBanner
              meta={meta}
              data={data}
              page={page}
              pagesize={pageSize}
              onEdit={(val: any) => onEdit(val)}
              onTrash={(val: any) => addToTrash(val)}
              unTrash={(val: any) => UndoTrash(val)}
              refresh={() => loadData(filter)}
              onPageChange={(page: any, pageSize: any) =>
                onPageChange(page, pageSize)
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BannerScreen;
