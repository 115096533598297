import  { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import CategoryDataTable from "./component/dataTable";
import { API } from "../../config/API";
import Loadingbox from "../../component/loadingbox";
import axios from "axios";
import { Button, Form, Input, Modal, Row, Select, message } from "antd";
import "./style.css";

function SubCategoryscreen(props: any) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [meta, setMeta] = useState<any>();
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [update, setUpdate] = useState<any>();

  useEffect(() => {
    loadCategories();
  }, []);

  const onFinish = async (values: any) => {
    let body = {
      data: {
        category: values.category,
        name: values.subcategory,
        publishedAt: update?.id ? update?.published : new Date(),
      },
    };
    let url = update?.id
      ? API.BASE_URL + API.SUBCATEGORY + `/${update?.id}`
      : API.BASE_URL + API.SUBCATEGORY;
    let method = update?.id ? "put" : "post";
    axios({
      method: method,
      url: url,
      data: body,
    })
      .then((response) => {
        setIsLoading(false);
        setShow(false);
        loadCategories();

        message.success("Sucessfull");
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error("Please try again");
      });
  };
  const handleCancel = () => {
    setShow(false);
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const loadCategories = async () => {
    let filter = `?populate[0]=subcategories`;
    let url = API.BASE_URL + API.CATEGORY + filter;
    axios.get(url).then((response) => {
      let data = response.data.data;
      setData(data);
      setMeta(meta);
      setIsLoading(false);
      setCategories(data);
    });
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.BASE_URL + API.SUBCATEGORY + `/${val}`;
      let response = await axios.delete(url);
      if (response) {
        loadCategories();
      }
    } catch (error) {
      console.log("==error===", error);
    }
  };
  const onEdit = (val: any) => {
    let obj = {
      id: val.id,
      published: val.publishedAt,
    };
    setUpdate(obj);
    form.setFieldsValue({
      category: val.categoryId,
      subcategory: val.name,
    });
    setShow(true);
  };

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      <PageHeader title={"Sub Category"} length={meta?.total}>
        <Button type="primary" onClick={() => setShow(true)}>
          Add New +
        </Button>
      </PageHeader>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <CategoryDataTable
          data={data}
          meta={meta}
          refresh={() => loadCategories()}
          delete={(val: any) => onDelete(val)}
          Edit={(val: any) => onEdit(val)}
        />
      )}
      {show && (
        <>
          <Modal
            footer={false}
            open={show}
            onCancel={handleCancel}
            centered
            closable={false}
          >
            <div className="category-Modal">
              <div className="categoryScrn-Txt1">Sub Category</div>
              <Form
                layout="vertical"
                form={form}
                name="nest-messages"
                onFinish={onFinish}
              >
                <div style={{ marginTop: 30 }}>
                  <Form.Item
                    name={"category"}
                    label={"Category"}
                    {...formItemLayout}
                  >
                    <Select>
                      {categories.length > 0 &&
                        categories.map((item: any, index: any) => {
                          return (
                            <Select.Option value={item.id}>
                              {item.attributes.category}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"subcategory"}
                    label={"Sub Category"}
                    {...formItemLayout}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div style={{ float: "right" }}>
                  <Row>
                    <Form.Item>
                      <Button
                        className="category-Cancel-Bttn"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="category-sbmt-Bttn"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Row>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default SubCategoryscreen;
