import { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Input, Select, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import "../style.css";
import TextArea from "antd/es/input/TextArea";
import { GrGallery } from "react-icons/gr";
import {
  uploadImageToS3,
  uploadInsnap,
} from "../../../config/s3config/s3fileUpload";
import moment from "moment";
import { API } from "../../../config/API";
import { GoInbox } from "react-icons/go";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import ImagePicker2 from "../../../component/image-pickersecond";
import Loadingbox from "../../../component/loadingbox";
import { GET } from "../../../utils/apirequest";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSelector } from "react-redux";

function InSnapForm() {
  const navigate = useNavigate();
  const User = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();
  const location = useLocation();
  const EditData = location?.state?.item;
  const { Dragger } = Upload;
  const [imageSet, setImageSet] = useState<any>(EditData?.imageList || []);
  const [Coverimage, setCoverImage] = useState<any>(EditData?.image);
  const [showmedia, setShowmedia] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [tags, setTags] = useState<any>([]);
  const [images, setImage] = useState<any>(EditData?.imageList || []);

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    LoadTags();
  }, []);

  const LoadTags = async () => {
    let url = API.TAGS_LIST_ALL;
    let res: any = await GET(url, null, null);
    setTags(res);
  };

  const handleCoverImageChange = async (event: any) => {
    setLoading(true);
    const selectedFile = event;
    const name = event?.name.slice(0, event?.name.length - 4);
    const fileName = `${moment().format(
      "YYYY-MM-DDHH:MM:SS"
    )}suprabhaatham-news${name}.png`;
    const uploadImgS3 = await uploadImageToS3(selectedFile, fileName);
    setCoverImage(uploadImgS3.Location);
    setLoading(false);
  };

  const handleFileChange = async (event: any) => {
    const selectedFile = event;
    const uploadImgS3 = await uploadInsnap(selectedFile);

    if (uploadImgS3) {
      const imageLocations = uploadImgS3?.map((item: any) => item?.Location);

      const uniqueObjects = imageLocations
        .map((location: string | null, index: number) => {
          if (location) {
            return { image: location, caption: "" };
          }
          return null;
        })
        .filter((obj: any) => obj !== null);
      if (EditData?.imageList) {
        setImage((prevImages: any) => [...prevImages, ...uniqueObjects]);
        setImageSet((prevImages: any) => [...prevImages, ...uniqueObjects]);
      } else {
        setImage(uniqueObjects);
        setImageSet(uniqueObjects);
      }
    }
  };
  const uploadeToMedialibrary = async (location: any) => {
    let url = API.BASE_URL + API.UPLOADTO_MEDIALIBRARY;
    let obj = location;
    try {
      let response = await axios.post(url, obj);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = (indexToDelete: number) => {
    setImageSet((prevImageSet: any) => {
      const updatedImageSet = [...prevImageSet];
      updatedImageSet.splice(indexToDelete, 1);
      return updatedImageSet;
    });

    setImage((prevImages: any) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(indexToDelete, 1);
      return updatedImages;
    });
  };
  const onFinish = async (values: any) => {
    if (EditData === undefined) {
      uploadeToMedialibrary(imageSet);
    }

    let reqObj = {
      data: {
        caption: values.caption,
        imageList: imageSet,
        image: Coverimage,
        Bylane: values.byline,
        permalink: values.permalink,
        tags: values.tags,
        is_deleted: false,
        user: EditData ? EditData?.user?.id : User?.id,
      },
    };
    let url = EditData
      ? API.BASE_URL + API.INSNAP + `/${EditData.id}`
      : API.BASE_URL + API.INSNAP;
    let method = EditData ? "PUT" : "POST";
    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqObj),
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
          navigate("/Auth/inSnap");
          notification.success({
            message: `Insnap Saved`,
          });
        })
        .catch((error) => {
          reject(error);
          notification.success({
            message: error,
          });
        });
    });
  };

  const moreProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        handleFileChange(info.fileList);
      }
      if (status === "done") {
      } else if (status === "error") {
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const addImageToList = async (val: any) => {
    if (val?.type === "medialibrary") {
      setCoverImage(val?.val);
    } else {
      await handleCoverImageChange(val?.val?.file);
    }
  };

  return (
    <>
      <PageHeader title={"Create Insnap"} />
      <div className="inSnap-Main">
        <div className="inSnap-Scroll">
          <Form
            form={form}
            initialValues={{
              caption: EditData?.caption,
              byline: EditData?.Bylane,
              permalink: EditData?.permalink,
              tags:
                EditData?.tags && EditData?.tags.map((item: any) => item?.id),
            }}
            onFinish={onFinish}
          >
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <label className="newsFormLabels">Caption</label>
                    <Form.Item name="caption" {...formItemLayout}>
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <label className="newsFormLabels">Byline</label>
                    <Form.Item name="byline" {...formItemLayout}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <label className="newsFormLabels">Perma Link</label>
                    <Form.Item name="permalink" {...formItemLayout}>
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <label className="newsFormLabels">Tags</label>
                    <Form.Item name="tags">
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        filterOption={(input: any, option: any) =>
                          option?.children
                            ?.toLowerCase()
                            ?.indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {tags.length > 0 &&
                          tags.map((item: any, index: any) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.tag}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {loading ? (
                  <Loadingbox />
                ) : (
                  <Col md={12}>
                    <label className="newsFormLabels">Cover Image </label>{" "}
                    <div
                      className="medialibraryInSnap"
                      onClick={() => setShowmedia(true)}
                    >
                      <GrGallery size={30} />
                      Add cover Image
                    </div>
                  </Col>
                )}
                {Coverimage ? (
                  <Col md={12}>
                    <div className="PreviewCoverImage">
                      <img src={Coverimage} />
                    </div>
                  </Col>
                ) : null}
                <label className="newsFormLabels">Add collections</label>
                {loading ? (
                  <Loadingbox />
                ) : (
                  <>
                    <Dragger {...moreProps} height={200}>
                      <p className="ant-upload-drag-icon">
                        <GoInbox />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </>
                )}
                <br />
                <Row>
                  {images?.length
                    ? images?.map((item: any, index: any) => {
                        return (
                          <Col key={index} md={12}>
                            <div className="insnapPreviews">
                              <div className="insnapPreviewsImg">
                                <img src={item.image} />
                                <div className="insnap-delete">
                                  <RiDeleteBin6Line
                                    onClick={() => deleteImage(index)}
                                    color="white"
                                    size={18}
                                    cursor={"pointer"}
                                  />
                                </div>
                              </div>

                              <div className="insnapPreviewsTxt">
                                <Form.Item
                                  name={`textField-${index}`}
                                  initialValue={item.caption}
                                >
                                  <TextArea
                                    rows={3}
                                    onChange={(e) => {
                                      const updatedDescriptions = [...imageSet];
                                      updatedDescriptions[index] = {
                                        ...updatedDescriptions[index],
                                        image: item.image,
                                        caption: e?.target?.value
                                          ? e?.target?.value
                                          : "",
                                      };

                                      setImageSet(updatedDescriptions);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <br />
                          </Col>
                        );
                      })
                    : null}
                </Row>

                <br />
                <Row>
                  <Col md={6}></Col>
                  <Col md={6}>
                    {EditData ? (
                      <Button
                        htmlType="submit"
                        className="inSnap-SbmtBttn"
                        type="primary"
                        style={{ height: 50 }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        htmlType="submit"
                        className="inSnap-SbmtBttn"
                        type="primary"
                        style={{ height: 50 }}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Form>
        </div>
        {showmedia ? (
          <ImagePicker2
            visible={showmedia}
            close={() => setShowmedia(false)}
            onSubmit={(val: any) => addImageToList(val)}
          />
        ) : null}
      </div>
    </>
  );
}

export default InSnapForm;
