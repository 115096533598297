import { Navigate, useLocation } from "react-router-dom";
function ProtectedRoute({ isSignedIn, children }: any) {
  const location = useLocation();
  if (!isSignedIn) {
    localStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/" replace />;
  }
  return children;
}
export default ProtectedRoute;
