import { useState, useEffect } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import { HiRefresh } from "react-icons/hi";
import {
  Select,
  message,
  DatePicker,
  Button,
  Input,
  Tag,
  Radio,
  Spin,
} from "antd";
import moment from "moment";
import PageHeader from "../../component/pageHeaders";
import DataTable from "./component/datatable";
import Loadingbox from "../../component/loadingbox";
import { LoadCategories } from "../../utils/commonapis";
import NewsLog from "../../component/newsLog";
import OverWrite from "../../component/overwrite";

import axios from "axios";
import { API } from "../../config/API";
import { POST } from "../../utils/apirequest";

function HomeScreen() {
  const navigation = useNavigate();
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const User = useSelector((state: any) => state.User.user);

  const [isloading, setIsloading] = useState<boolean>(true);
  const [isloading2, setIsloading2] = useState<boolean>(true);
  const [indicator, setIndicator] = useState<boolean>(true);

  const [logShow, setLOgShow] = useState(false);
  const [settingsList, setSettingsList] = useState<any[]>([]);
  const [overWrite, setOverWrite] = useState(false);
  const [data, setData] = useState([]) as any;
  const [meta, setMeta] = useState() as any;
  const [page, setPage] = useState(query ? query?.get("page") : 1);
  const [pageSize, setPageSize] = useState(query ? query?.get("limit") : 10);
  const start =
    query && query.get("page") ? (query.get("page") - 1) * pageSize : 0;
  const [filter, setFilter] = useState({
    start: start,
    limit: pageSize,
  }) as any;
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [usersList, setusersList] = useState<any[]>([]);
  const [trashcount, setTrashcount] = useState();
  const [draftcount, setDraftcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [singlevalue, setSinglevalue] = useState<any>();
  const [overWriteData, setoverWriteData] = useState<any>();

  useEffect(() => {
    loadData(filter);
  }, []);

  useEffect(() => {
    setPage(query ? query?.get("page") : 1);
    setPageSize(query ? query?.get("limit") : 10);
  }, [query]);

  const loadData = async (filter: any) => {
    setIndicator(true);
    let URL = API.GET_NEWS;
    try {
      const res: any = await POST(URL, filter);
      if (res.status) {
        setData(res.data);
        getNewsMeta(filter);
        GET_USERS();
        getSettings();
        loadCategories();
        setIsloading(false);
        setIndicator(false);
      } else {
        setIsloading(false);
        setIndicator(false);
      }
    } catch (err) {
      setIsloading(false);
      setIndicator(false);
      console.log("err", err);
      message.error("Something went wrong...");
    }
  };
  const getSettings = () => {
    let url = API.BASE_URL + API.SETTINGS_LIST;
    axios.get(url).then((response) => {
      setSettingsList(response?.data?.data[0]?.attributes);
    });
  };
  const getNewsMeta = async (filter: any) => {
    try {
      let URL = API.GET_NEWS_META;
      const res: any = await POST(URL, filter);
      setMeta(res.totalCount);
      setPublishCount(res.publishedCount);
      setTrashcount(res.trashedCount);
      setDraftcount(res.draftCount);
      setIsloading2(false);
    } catch (err) {
      setIsloading2(false);
    }
  };

  const GET_USERS = () => {
    let url = API.BASE_URL + API.GET_USERS;
    axios.get(url).then((response) => {
      setusersList(response.data);
    });
  };

  const loadCategories = async () => {
    const res: any = await LoadCategories();
    setCategoryList(res.data);
  };

  const onEdit = (val: any) => {
    if (val.editing_Status) {
      setOverWrite(true);
      setoverWriteData(val);
    } else {
      navigation(`/Auth/home/news/${val.id}`, { state: { item: val.onlive } });
    }
  };

  const CategoryChange = (category: any) => {
    let filters = {
      ...filter,
      catId: category,
    };
    setFilter(filters);
    loadData(filters);
  };

  const PriorityChange = (prio: any) => {
    let filters = {
      ...filter,
      isHighPriority: prio,
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadByUser = (id: any) => {
    let filters = {
      ...filter,
      userId: id,
    };
    setFilter(filters);
    loadData(filters);
  };

  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadByFilter = (type: any) => {
    let filters = {
      ...filter,
      type: type,
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const onPageChange = (page: any, pageSize: any) => {
    query.set("page", page);
    query.set("limit", pageSize);
    navigation({ search: query.toString() });
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : (page - 1) * 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const addToTrash = async (val: any) => {
    let url = API.ADD_TO_TRASH;
    let body = {
      id: val?.id,
      userId: User?.id,
    };
    let res: any = await POST(url, body);
    if (res?.id) {
      loadData(filter);
      message.success("sucesssfully moved to trash");
    } else {
      message.error("Something went wrong..!");
    }
  };

  const UndoTrash = (val: any) => {
    let url = API.BASE_URL + API.UNTRASH + `/${val.id}`;
    axios
      .put(url)
      .then((response) => {
        loadData(filter);
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };

  const onlogShow = (val: any) => {
    setSinglevalue(val);
    setLOgShow(true);
  };

  return (
    <div>
      <PageHeader title={"News"} loader={indicator} length={`${meta} News`}>
        <div style={{ width: 200 }}>
          <Input
            allowClear
            placeholder="Search News "
            onChange={(e: any) => Search(e.target.value)}
          />
        </div>
        <div>
          <Select
            placeholder="Created By"
            onChange={(val) => LoadByUser(val)}
            showSearch={true}
            style={{ width: 200 }}
            allowClear
            filterOption={(input: any, option: any) =>
              option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {usersList?.map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.id}>
                  {item.username}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div>
          <DatePicker
            allowClear
            style={{ width: 150 }}
            onChange={onChangeDate}
          />
        </div>
        <div>
          <Select
            showSearch={true}
            placeholder="Category"
            onChange={(val) => CategoryChange(val)}
            style={{ width: 180 }}
            allowClear
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoryList?.map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.id}>
                  {item.attributes.category}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Select
            showSearch={true}
            placeholder="Priority"
            onChange={(val) => PriorityChange(val)}
            style={{ width: 150 }}
            allowClear
          >
            <Select.Option key="high">High Priority</Select.Option>
            <Select.Option key={"second"}>Second Lead</Select.Option>
          </Select>
        </div>

        <div>
          <Button type="primary" onClick={() => navigation("/Auth/home/news")}>
            News Entry +
          </Button>
        </div>
      </PageHeader>

      {isloading ? (
        <Loadingbox />
      ) : (
        <>
          <div style={{ padding: 4 }} />
          <div className="homescreen-pageItem">
            <div>
              {Object.keys(filter)?.length > 2 ? "Filter By :" : "All News"}{" "}
              &nbsp;
              {Object.keys(filter)?.map(
                (key, index) =>
                  key !== "start" &&
                  key !== "limit" && (
                    <Tag style={{ marginBottom: 5 }}>
                      {`${key}:  ${
                        key === "createdAt"
                          ? moment(filter[key]).format("DD-MM-YYYY")
                          : filter[key]
                      }`}{" "}
                      &nbsp;
                      <CloseCircleOutlined
                        color="red"
                        onClick={() => clearFilters(key)}
                      />
                    </Tag>
                  )
              )}
            </div>
            <div style={{ flex: 1 }} />
            <Radio.Group
              value={filter?.type}
              onChange={(val: any) => LoadByFilter(val?.target?.value)}
            >
              <Radio.Button value="published">
                <span style={{ color: "green" }}>Published</span> - &nbsp;{" "}
                {isloading2 ? <Spin size="small" /> : publishCount}
              </Radio.Button>
              <Radio.Button value="trash">
                <span style={{ color: "red" }}>Trashed</span> - &nbsp;{" "}
                {isloading2 ? <Spin size="small" /> : trashcount}
              </Radio.Button>
              <Radio.Button value="draft">
                <span style={{ color: "blue" }}>Draft</span> - &nbsp;{" "}
                {isloading2 ? <Spin size="small" /> : draftcount}
              </Radio.Button>
            </Radio.Group>
            <div>
              <Button
                type="primary"
                ghost
                icon={<HiRefresh />}
                onClick={() => loadData(filter)}
              >
                Refresh
              </Button>
            </div>
            <div>
              <Button icon={<HiRefresh />}>Cache</Button>
            </div>
          </div>
          <DataTable
            data={data && data}
            meta={meta}
            page={page}
            pagesize={pageSize}
            loading={isloading2}
            refresh={() => loadData(filter)}
            onEdit={(val: any) => onEdit(val)}
            onlog={(val: any) => onlogShow(val)}
            onPageChange={(page: any, pageSize: any) =>
              onPageChange(page, pageSize)
            }
            onTrash={(val: any) => addToTrash(val)}
            unTrash={(val: any) => UndoTrash(val)}
            settings={settingsList}
          />
        </>
      )}
      {logShow ? (
        <NewsLog
          visible={logShow}
          id={singlevalue}
          close={() => setLOgShow(false)}
        />
      ) : null}
      {overWrite ? (
        <OverWrite
          visible={overWrite}
          data={overWriteData}
          close={() => setOverWrite(false)}
        />
      ) : null}
      <br />
      <br />
      <br />
    </div>
  );
}

export default HomeScreen;
