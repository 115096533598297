import {
  Card,
  Space,
  Table,
  Tag,
  Popconfirm,
  Pagination,
  Tooltip,
  Button,
  notification,
} from "antd";
import "../style.css";
import { API } from "../../../config/API";
import axios from "axios";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  const [Notifications, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const onDelete = (id: any) => {
    let url = API.BASE_URL + API.BANNERS + `/${id}`;
    axios.delete(url).then((response) => {
      props?.refresh();
    });
  };

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: " Position",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record.type}
          </div>
        );
      },
    },
    {
      title: " categories",
      dataIndex: "categories",
      key: "categories",
      width: 150,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.categories.map((item: any) => {
              return <div>{item?.category}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: " tags",
      dataIndex: "tags",
      key: "tags",
      width: 150,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.tags.map((item: any) => {
              return <div>{item?.tag}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: "banner",
      key: "banner_image",
      dataIndex: "banner_image",
      render: (text, record: any) => {
        return (
          <div>
            <img
              key={record.banner_image}
              src={record.banner_image}
              alt="Table Image"
              style={{ width: 70, borderRadius: 6 }}
            />
          </div>
        );
      },
    },
    {
      title: "Live Status",
      key: "Show",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record.handleShow === true ? "lime" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record.handleShow === true ? "On Live" : "Not In Live"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record.publishedAt ? "lime" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        {contextHolder}
        <Table
          columns={columns}
          dataSource={props.data}
          className="commen-table-custom-scrollbar"
          pagination={false}
          size="small"
        />
        <br />
        <div style={{ float: "right" }}>
          <Pagination
            responsive
            defaultCurrent={props?.page}
            total={props?.meta}
            pageSize={props?.pagesize}
            onChange={(p, t) => {
              props.onPageChange(p, t);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default DataTable;
