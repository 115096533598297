import { useState } from "react";
import { Table } from "react-bootstrap";
import { Button, Form, Input,Checkbox } from "antd";
import LeadItems from "./leadItems";
import {
  IoIosArrowDown,
  IoMdArrowDropdown,
  IoMdArrowDropup,
} from "react-icons/io";

function LeadForm(props: any) {
  const [expand, setexpand] = useState<any>(0);
  return (
    <div>
      <label className="newsFormLabels">Leads Data</label>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th>Electoral Area</th>
            <th>Party</th>
            <th>Featured</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          <Form.List name="data" initialValue={[{ party: null, seats: 0 }]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <tr
                    style={{
                      border: index === expand ? "1px solid #bdbdbd" : "",
                      borderTop: index === expand ? "1.5px solid #bdbdbd" : "",
                    }}
                  >
                    <td
                      onClick={() =>
                        index === expand ? setexpand(null) : setexpand(index)
                      }
                    >
                      <div style={{ display: "flex" }}>
                        <div>{index + 1}</div>
                        <div>
                          {index === expand ? (
                            <IoMdArrowDropup size={20} color="grey" />
                          ) : (
                            <IoMdArrowDropdown size={20} color="grey" />
                          )}
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "40%" }}>
                      <Form.Item noStyle name={[name, "location"]}>
                        <Input
                          placeholder="Enter Electoral Area"
                          bordered={false}
                          style={{ marginBottom: -10 }}
                        />
                      </Form.Item>
                    </td>
                    <td style={{ width: "50%", padding: 0 }}>
                      {expand === index ? (
                        <LeadItems key_name={name} party={props?.party} loading={props.loading}/>
                      ) : (
                        <div
                          className="expandForm"
                          onClick={() =>
                            index === expand
                              ? setexpand(null)
                              : setexpand(index)
                          }
                        >
                          <div>Expand</div>
                          <div>
                            <IoIosArrowDown />
                          </div>
                        </div>
                      )}
                    </td>
                    <td>
                      <Form.Item noStyle name={[name, "featured"]} valuePropName="checked">
                        <Checkbox />
                      </Form.Item>
                    </td>
                    <td>
                      {fields?.length > 0 ? (
                        <Button
                          size="small"
                          onClick={() => {
                            setexpand(index === 0 ? index : index - 1);
                            remove(name);
                          }}
                          danger
                          block
                        >
                          Remove
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3}></td>
                  <td>
                    <Button
                      size="small"
                      onClick={() => {
                        setexpand(fields.length);
                        add();
                      }}
                      block
                      style={{ backgroundColor: "green", color: "#fff" }}
                    >
                      Add +
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </Form.List>
        </tbody>
      </Table>
    </div>
  );
}

export default LeadForm;
