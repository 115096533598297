import "./styles.css";
import Dropzone from "react-dropzone";
import { Button, Input } from "antd";
import { BsCloudUpload } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const PickImage = (props: any) => {
  const [Title, setTitle] = useState(props?.title);
  const [Desc, setDesc] = useState("");
  const [Cap, setCap] = useState("");

  const onSelectClick = () => {
    let values = {
      title: Title,
      description: Desc,
      caption: Cap,
    };
    props?.onFinish(values);
  };

  const handleDrop = (acceptedFiles: any) => {
    props.onChange(acceptedFiles);
  };
  return (
    <div className="">
      <Row>
        <Col md="8">
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps({
                    className: `ImagePicker-backgroud ${
                      props?.size ? "small" : ""
                    }`,
                  })}
                >
                  <input {...getInputProps()} />
                  {props?.fileURL ? (
                    <>
                      <img
                        src={props?.sasUrl}
                        alt="image"
                        className="Picker-Img"
                      />
                      <div style={{ margin: 10 }} />
                      <Button type="dashed" size="small">
                        Choose another image
                      </Button>
                    </>
                  ) : (
                    <>
                      <BsCloudUpload size={30} />
                      <h5 className="ImagePickertxt2">
                        Drag or click to select files
                      </h5>
                      <Button type="dashed" size="small">
                        Choose file . . .
                      </Button>
                    </>
                  )}
                </div>
                {props?.fileURL ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    {...getInputProps()}
                  >
                    <Button type="dashed" size="small">
                      Choose another image
                    </Button>
                  </div>
                ) : null}
              </section>
            )}
          </Dropzone>
        </Col>
        <Col md="4">
          <Row style={{ borderRadius: 8 }}>
            <Col md="12">
              <div className="newsFormLabels">Image </div>
              <div className="image-croppButton">
                <img src={props?.sasUrl} />
              </div>
            </Col>
            <Col md="12">
              <div className="newsFormLabels">Image title</div>
              <Input value={Title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
            <Col md="12">
              <div className="newsFormLabels">Caption</div>
              <TextArea
                value={Cap}
                onChange={(e) => setCap(e.target.value)}
                autoSize={{ maxRows: 2 }}
              />
            </Col>
            <Col md="12">
              <div className="newsFormLabels">Description</div>
              <TextArea
                value={Desc}
                autoSize={{ maxRows: 4, minRows: 4 }}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col sm={6} xs={12} />
        <Col sm={6} xs={12}>
          <Row>
            <Col sm={6} xs={12}>
              <Button size="large" block danger onClick={() => props.close()}>
                Close
              </Button>
            </Col>
            <Col sm={6} xs={12}>
              <Button
                loading={props?.loading}
                size="large"
                block
                type="primary"
                onClick={() => onSelectClick()}
              >
                Select
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PickImage;
