import { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders/index";
import Loadingbox from "../../../component/loadingbox";
import DataTable from "./datatable";
import { API } from "../../../config/API";
import { POST } from "../../../utils/apirequest";
import { Button, DatePicker, Input, Popover, Tag, message } from "antd";
import moment from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoAlertCircleOutline } from "react-icons/io5";
import * as XLSX from "xlsx";
import SelectModal from "./selectModal";

import Result from "./result";
function QuizAnswers(props: any) {
  const { id, qid } = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [isLoading2, setIsloading2] = useState(false);
  const [isLoading3, setIsloading3] = useState(false);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [winModal, setWinModal] = useState<boolean>(false);
  const [data, setData] = useState([]) as any;
  const [winners, setWinners] = useState([]) as any;
  const [winnersCount, setWinnersCount] = useState(0) as any;
  const [losersCount, setLosersCount] = useState(0) as any;
  const [item, setItem] = useState({});
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [filter, setFilter] = useState({
    masterId: id,
    itemId: qid,
    start: 0,
    limit: pageSize,
  }) as any;
  const [resultModal, setResultModal] = useState(false);

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      setIsloading3(true);
      setIndicator(true);
      let url = API.QUIZZ_ANSWER_LIST;
      const res: any = await POST(url, filter);
      if (res?.status) {
        setData(res?.data);
        let winners = res.data.filter((item: any) => item.winner === true);
        setWinners(winners);
        setWinnersCount(res?.winnerscount);
        setLosersCount(res?.loserscount);
        setMeta(res?.count);
        setIsloading(false);
        setIndicator(false);
        setIsloading3(false);
      } else {
        message.error("Something went wrong...");
      }
    } catch (err) {
      console.log("err", err);
      setIndicator(false);
      setIsloading(false);
      setIsloading3(false);
      message.error("Something went wrong...");
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };

  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadType = (type: any) => {
    let filters = {
      ...filter,
      type: type,
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const showResults = (val: any) => {
    setItem(val);
    setResultModal(true);
  };

  const generateExcel = async () => {
    try {
      setIsloading2(true);
      let obj = {
        masterId: id,
        itemId: qid,
      };
      let URL = API.GET_EXCEL_DATA;
      let data: any = await POST(URL, obj);
      const arr: any = [];

      data.forEach((item: any) => {
        let formattedItem = {
          id: item.id,
          createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
          name: item.name,
          address: item.address,
          phone: item.phone,
          class: item.class ? item.class : "-",
          district: item.district ? item.district : "-",
          winner: item.winner ? "WIN" : "FAIL",
        };
        arr.push([
          formattedItem.id,
          formattedItem.createdAt,
          formattedItem.name,
          formattedItem.address,
          formattedItem.phone,
          formattedItem.class,
          formattedItem.district,
          formattedItem.winner,
        ]);
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(arr);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "report.xlsx");
      setIsloading2(false);
    } catch (err) {
      console.log(err);
      setIsloading2(false);
    }
  };

  return (
    <>
      <div style={{ height: "90vh", overflow: "scroll" }}>
        <PageHeader
          title={"Quiz Answers"}
          loader={indicator}
          length={`${meta} Answers`}
        >
          <div className="homescreen-pageItem">
            <div className="homescreen-pageBox">
              <div
                className="homescreen-pageItem"
                onClick={() => LoadType("winners")}
              >
                <span style={{ color: "green" }}>Winners</span> : &nbsp;
                <b>{winnersCount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadType("losers")}
              >
                <span style={{ color: "red" }}>Losers</span> : &nbsp;
                <b>{losersCount}</b>
              </div>
            </div>
            &nbsp;
            <Button type="primary" onClick={() => setWinModal(true)}>
              Select Winner
            </Button>
            &nbsp;
            <Popover
              content={
                <div style={{ whiteSpace: "pre-line", width: "400px" }}>
                  <IoAlertCircleOutline size={15} /> Please Ensure Network
                  Stability and System Availability For Proper Report
                  Processing.
                </div>
              }
              title="Export Report"
            >
              <Button
                type="primary"
                loading={isLoading2}
                icon={<SiMicrosoftexcel />}
                onClick={() => generateExcel()}
              >
                Export
              </Button>
            </Popover>
            &nbsp;
            <Button danger>Recheck</Button>
          </div>
        </PageHeader>
        <div style={{ padding: 5 }} />
        <div className="homescreen-pageItem">
          <div
            style={{
              flex: 5,
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(filter)?.length > 4 ? "Filter By :" : "All Answers"}{" "}
            &nbsp;
            {Object.keys(filter)?.map(
              (key, index) =>
                key !== "start" &&
                key !== "limit" &&
                key !== "masterId" &&
                key !== "itemId" && (
                  <Tag style={{ marginBottom: 5 }}>
                    {`${key}:  ${
                      key === "createdAt"
                        ? moment(filter[key]).format("DD-MM-YYYY")
                        : filter[key]
                    }`}{" "}
                    <CloseCircleOutlined onClick={() => clearFilters(key)} />
                  </Tag>
                )
            )}
          </div>
          <div>
            <DatePicker
              allowClear
              style={{ width: 200 }}
              onChange={onChangeDate}
            />
          </div>
          &nbsp;
          <div style={{ width: 250 }}>
            <Input
              allowClear
              placeholder="Search Answers"
              onChange={(e: any) => Search(e.target.value)}
            />
          </div>
        </div>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <DataTable
            data={data}
            meta={meta}
            page={page}
            pagesize={pageSize}
            loading={isLoading3}
            showResults={(val: any) => showResults(val)}
            onPageChange={(page: any, pageSize: any) =>
              onPageChange(page, pageSize)
            }
          />
        )}
        {resultModal ? (
          <Result
            open={resultModal}
            item={item}
            onCancel={() => setResultModal(false)}
          />
        ) : null}
        {winModal ? (
          <SelectModal
            visible={winModal}
            winners={winners}
            onCancel={() => setWinModal(false)}
          />
        ) : null}
      </div>
    </>
  );
}

export default QuizAnswers;
