import { Button, Form, Input, Select } from "antd";
import { IoMdClose } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";

import { Col, Row, Table } from "react-bootstrap";

function LeadItems(props: any) {
  return (
    <Form.List
      name={[props?.key_name, "options"]}
      initialValue={[{ party: "", seats: "" }]}
    >
      {(fields, { add, remove }) => (
        <>
          <Table bordered style={{ margin: 0 }}>
            <tbody>
              {fields?.map(({ key, name, ...restField }, index) => (
                <tr>
                  <td style={{ width: "45%", padding: 0 }}>
                    <Form.Item noStyle name={[name, "party"]}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder={"Select a Party"}
                        bordered={false}
                        loading={props.loading}
                      >
                        {props?.party?.options &&
                          props?.party?.options?.map((item: any) => {
                            let val = JSON.stringify(item);
                            return (
                              <Select.Option key={val}>
                                {item.party}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </td>
                  <td style={{ width: "50%", padding: 0 }}>
                    <Form.Item noStyle name={[name, "seats"]}>
                      <Input placeholder="seats" bordered={false} />
                    </Form.Item>
                  </td>
                  <td style={{ width: "10%" }}>
                    {fields.length === index + 1 ? (
                      <IoAddCircleOutline
                        onClick={() => add()}
                        size={20}
                        color="green"
                      />
                    ) : (
                      <IoMdClose
                        size={20}
                        color="red"
                        onClick={() => remove(name)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Form.List>
  );
}

export default LeadItems;
