import { Button, Form, Input, Select, message, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { API } from "../../../config/API";
import { uploadImageToS3 } from "../../../config/s3config/s3fileUpload";
import { MdFileDownloadDone } from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
import { BsUpload } from "react-icons/bs";
import ImageUpload from "../../../assets/image/Group 225.svg";
import PageHeader from "../../../component/pageHeaders";
import { GET,POST } from "../../../utils/apirequest";
import { useSelector } from "react-redux";

function NewForm(props: any) {
  const User = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState<any>("");
  const [videoUrl, setVideoUrl] = useState<any>("");
  const [imageurl, setImageurl] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoading2, setIsLoading2] = useState<any>(false);
  const [isLoading3, setIsLoading3] = useState<any>(false);
  const [reelsId, setReelsId] = useState() as any;
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    LoadTags()
  },[])

  const LoadTags = async() => {
    let url =  API.TAGS_LIST_ALL;
    let res:any = await GET(url,null,null)
    setTags(res)
  }

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      let requestObj = {
        data: {
          head: imageurl,
          title: values.title,
          description: description,
          video: videoUrl,
          tags: values?.tags,
          permalink: values?.permalink,
          user: User?.id,
        },
      };
      let url = API.REELS;
      const res: any = await POST(url, requestObj);
      if (res?.data?.id) {
        setReelsId(res?.data?.id);
        setIsLoading(false);
        form.resetFields();
        notification.success({ message: "Saved" });
      } else {
        notification.error({ message: "Something went wrong...!" });
      }
    } catch (err) {
      console.log("err", err);
      notification.error({ message: "Something went wrong...!" });
    }
  };

  const onPublish = (id: any) => {
    setLoading(true);
    let url = API.BASE_URL + API.REELS + `/${id}`;
    let requestObj = {
      data: {
        publishedAt: new Date(),
      },
    };
    axios({
      method: "PUT",
      url: url,
      data: requestObj,
    })
      .then((response) => {
        message.success("Published");
        props?.onClose();
        props?.reload();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const onImageUpload = (value: any) => {
    setIsLoading2(true);
    let imagefolder = value?.target?.files[0];
    const fileName: any = moment().unix() + "-suprabhaatham.jpg";
    const file = imagefolder;
    const response = uploadImageToS3(file, fileName);
    response.then((location) => {
      setImageurl(location.Location);
      setIsLoading2(false);
    });
  };

  const onUpload = (val: any) => {
    setIsLoading3(true);
    const fileName = `${moment().unix()}-suprabhaatham-reels.mp4`;
    const file = val.target.files[0];
    const response = uploadImageToS3(file, fileName);
    response.then((location) => {
      setVideoUrl(location.Location);
      console.log("Upload Ended", location.Location);
      setIsLoading3(false);
    });
  };

  return (
    <>
      <PageHeader title={"Reel Form"} />
      <Col md={{ span: 11, offset: 1 }}>
        <Form {...formItemLayout} onFinish={onFinish} form={form}>
          <Row>
            <Col md={6}>
              <Row>
                <label className="reelScrn-formLabel">Title</label>
                <Form.Item name="title">
                  <Input
                    size="large"
                    style={{ maxWidth: 678 }}
                    placeholder="type the reel name"
                  />
                </Form.Item>
              </Row>
              <Row>
                <label className="reelScrn-formLabel">Cover-Image</label>
                <Form.Item name="image" {...formItemLayout}>
                  <div
                    className="imageuploadbox"
                    style={{
                      height: "150px",
                      maxWidth: 678,
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    {imageurl ? (
                      <>
                        <img
                          style={{ width: "50%", height: "100%" }}
                          src={imageurl}
                        />
                      </>
                    ) : (
                      <>
                        {isLoading2 ? (
                          <>Loading....</>
                        ) : (
                          <img
                            style={{ width: "50%", height: 50 }}
                            src={ImageUpload}
                          />
                        )}
                        <div className="reelScrn-Uploadtxt">
                          Upload cover Image
                        </div>
                        <Input
                          type="file"
                          onChange={(val) => onImageUpload(val)}
                        />
                      </>
                    )}
                  </div>
                </Form.Item>
              </Row>
              <Row>
                <label className="reelScrn-formLabel">Body</label>
                <Form.Item name="description" {...formItemLayout}>
                  <TextArea
                    rows={3}
                    placeholder="explain about the reels"
                    style={{ maxWidth: 678 }}
                  />
                </Form.Item>
              </Row>
              <Row>
                <div className="formLabel">Tags</div>
                <Form.Item name="tags">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tags.length > 0 &&
                      tags.map((item: any, index: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Row>
            </Col>
            <Col md={6}>
              <Row style={{ maxWidth: 480 }}>
                <div className="formLabel">Perma Link</div>
                <Form.Item name="permalink" {...formItemLayout}>
                  <Input placeholder="" />
                </Form.Item>
              </Row>
              <Row style={{}}>
                <label className="reelScrn-formLabel">Reel Video</label>
                <Form.Item name="video">
                  <div
                    className="imageuploadbox"
                    style={{
                      height: "190px",
                      maxWidth: 379,
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    {videoUrl ? (
                      <MdFileDownloadDone color="#0055a6" size={50} />
                    ) : (
                      <>
                        {isLoading3 ? (
                          <>Loading...</>
                        ) : (
                          <img
                            style={{ width: "50%", height: 100 }}
                            src={ImageUpload}
                          />
                        )}
                        <div className="reelScrn-Uploadtxt">
                          Upload Reel Video
                        </div>
                      </>
                    )}

                    <Input type="file" onChange={(val) => onUpload(val)} />
                    {videoUrl && (
                      <video controls width="400">
                        <source
                          className="reelList-Video"
                          src={videoUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </Form.Item>
              </Row>
              <Row style={{ padding: 10 }}>
                <div>
                  <Button className="podcast-Btn" htmlType="submit">
                    save
                  </Button>
                </div>
                <div style={{ marginTop: 30 }}>
                  <Button
                    className="podcast-Btn"
                    type="primary"
                    onClick={() => onPublish(reelsId)}
                    loading={loading}
                    icon={<BsUpload />}
                  >
                    Publish
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  );
}

export default NewForm;
