import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useNavigate, useLocation } from "react-router-dom";
import PageHeader from "../../component/pageHeaders";
import DataTable from "./components/datatable";
import { API } from "../../config/API";
import Loadingbox from "../../component/loadingbox";
import axios from "axios";
import { Button, Input, Select, Tag, message, notification } from "antd";
import { IoMdCloseCircle } from "react-icons/io";
import { POST } from "../../utils/apirequest";
import moment from "moment";

function UserScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading3, setIsLoading3] = useState({});
  const [indicator, setIndicator] = useState<boolean>(true);
  const [page, setPage] = useState(query ? query?.get("page") : 1);
  const [pageSize, setPageSize] = useState(query ? query?.get("limit") : 10);
  const [meta, setMeta] = useState() as any;
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  useEffect(() => {
    setPage(query ? query?.get("page") : 1);
    setPageSize(query ? query?.get("limit") : 10);
  }, [query]);

  const loadData = async (filter: any) => {
    setIndicator(true);
    let URL = API.GET_USERS_LIST;
    try {
      const res: any = await POST(URL, filter);
      if (res.status) {
        setData(res.data);
        setMeta(res.totalCount);
        setIsLoading(false);
        setIndicator(false);
      } else {
        setIsLoading(false);
        setIndicator(false);
      }
    } catch (err) {
      setIsLoading(false);
      setIndicator(false);
      console.log("err", err);
      message.error("Something went wrong...");
    }
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setTimeout(() => {
        loadData(filters);
      }, 500);
      setFilter(filters);
    }
  };

  const LoadByRole = (val: any) => {
    let filters = {
      ...filter,
      role: val,
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const onPageChange = (page: any, pageSize: any) => {
    query.set("page", page);
    query.set("limit", pageSize);
    navigate({ search: query.toString() });
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : (page - 1) * 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    setFilter(filters);
    loadData(filters);
  };

  const deleteData = (id: any) => {
    setIsLoading(true);
    let url = API.BASE_URL + API.DELETE_USER + `${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        loadData(filter);
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  const updateData = async (id: any, checked: any) => {
    try {
      let requestObj = {
        blocked: checked,
      };
      let url = API.BASE_URL + API.USERS + `/${id}`;
      const response = await axios.put(url, requestObj);
      notification.success({
        message: checked ? "Inactive" : "Active",
        description: checked ? "User Inactivated" : "User Activated",
      });
      loadData(filter);
    } catch (error) {
      notification.error({
        message: "sorry",
        description: "couldnt complete updating user.",
      });
      console.error("An error occurred:", error);
    }
  };
  const generateExcel = async (val: any) => {
    setIsLoading3((prev: any) => ({ ...prev, [val?.id]: true }));
    let URL = API.EXPORT_USER_DATA;
    let obj = {
      email: val?.email,
    };
    try {
      const res: any = await POST(URL, obj);
      if (res?.id) {
        await ExportExcel(res);
      }
      setIsLoading3((prev: any) => ({ ...prev, [val?.id]: false }));
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong...");
      setIsLoading3((prev: any) => ({ ...prev, [val?.id]: false }));
    }
  };
  const ExportExcel = async (data: any) => {
    try {
      // Function to create formatted data for each section

      const getTextContent = (htmlString: any) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || "";
      };
      const formatData = (items: any[], headers: any, keys: any) => {
        return items.map((item, index) => {
          return {
            "Sl. No.": index + 1,
            ...headers.reduce((acc: any, key: string) => {
              acc[key] = item[key] || ""; // Ensuring no undefined values
              return acc;
            }, {}),
            "Words Commited": getTextContent(
              keys === "vidyaprabhaadhams" ? item?.Body : item?.body
            )
              .trim()
              .split(/\s+/).length,
            "published At": item?.publishedAt
              ? moment(item.publishedAt).format("lll")
              : "Not Published",
          };
        });
      };

      const workbook = XLSX.utils.book_new();
      const sections = [
        {
          title: "News",
          key: "all_news",
          headers: ["head", "permalink", "image", "View", "newsTag"],
        },
        {
          title: "Editorial",
          key: "editorials",
          headers: ["head", "permalink", "image1", "EditorialTag"],
        },

        {
          title: "Articles",
          key: "articles",
          headers: ["head", "permalink", "author", "articleTag"],
        },
        {
          title: "Vidyaprabhaadhams",
          key: "vidyaprabhaadhams",
          headers: ["title", "permalink", "image1"],
        },
        {
          title: "Njayarprabhaadham Masters",
          key: "njayarprabhaadham_masters",
          headers: ["head", "permalink", "publishedAt"],
        },
        {
          title: "Epaper",
          key: "e_papers",
          headers: ["edition", "image", "date"],
        },
        {
          title: "In Snap",
          key: "in_snaps",
          headers: ["image", "caption", "permalink", "Bylane"],
        },
        {
          title: "Reels",
          key: "reels",
          headers: [
            "head",
            "title",
            "permalink",
            "video",
            "likes",
            "shares",
            "comments",
          ],
        },
        {
          title: "Web Stories",
          key: "web_stories",
          headers: ["image", "description", "permalink"],
        },
      ];

      sections.forEach((section) => {
        if (data[section.key] && data[section.key].length > 0) {
          // Prepare data for this section
          const sectionData = formatData(
            data[section.key],
            section.headers,
            section.key
          );

          // Create worksheet with section data
          const worksheet = XLSX.utils.json_to_sheet(sectionData);

          // Append worksheet to workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, section.title);
        }
      });

      // Write the workbook to a buffer
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Download the file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(dataBlob);
      link.download = `Suprabhaatham Report -${data?.username}-${moment(
        new Date()
      ).format("DD-MM-YYYY-HH-mm-ss")}.xlsx`;
      link.click();
    } catch (err) {
      console.error(err);
      message.error("Couldn't export data");
    }
  };

  return (
    <div>
      <PageHeader
        title={"Users"}
        loader={indicator}
        length={`Dashboard / Users`}
      >
        <div className="homescreen-pageItem">
          <Input
            style={{ width: "250px" }}
            allowClear
            placeholder="Search Users"
            onChange={(e: any) => Search(e.target.value)}
          />
          &nbsp;
          <Select
            placeholder="Role"
            onChange={(val) => LoadByRole(val)}
            showSearch={true}
            style={{ width: 170 }}
            allowClear
            filterOption={(input: any, option: any) =>
              option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option key={4}>Admin</Select.Option>
            <Select.Option key={3}>Reporter</Select.Option>
            <Select.Option key={5}>Contributer</Select.Option>
          </Select>
          &nbsp;
          <div>
            <Button
              type="primary"
              onClick={() => navigate("/Auth/users/form/create")}
            >
              Add New +
            </Button>
          </div>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="PageHeaders-box5">
        <div className="PageHeaders-txt3">Users ( {meta} )</div>
        {Object.keys(filter)?.length > 2 ? <div>|</div> : null}
        <div className="PageHeaders-txt2">
          {Object.keys(filter)?.length > 2 ? "Filter By : " : ``}
        </div>
        <div>
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag
                  onClick={() => clearFilters(key)}
                  icon={<IoMdCloseCircle color="red" />}
                >
                  &nbsp;{key} : {filter[key]}
                </Tag>
              )
          )}
        </div>
      </div>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          page={page}
          pagesize={pageSize}
          onDelete={(id: any) => deleteData(id)}
          onUpdate={(id: any, checked: any) => updateData(id, checked)}
          onPageChange={(page: any, pageSize: any) =>
            onPageChange(page, pageSize)
          }
          generateExcel={(val: any) => generateExcel(val)}
          loading3={isLoading3}
          refresh={() => loadData(filter)}
        />
      )}
    </div>
  );
}

export default UserScreen;
