import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import "../style.css";
const WebStoriesImageList = (props: any) => {
  // const [isLoading,setIsLoading] = useState(true)
  const location = useLocation();
  const navigate = useNavigate();
  let data = location?.state?.item;

  return (
    <>
      <div>
        <PageHeader
          title={"More Images"}
          goBack={() => navigate("/Auth/webstories")}
        />
        <div className="dataList-container">
          {data?.map((item: any) => {
            return (
              <>
                <div className="dataList-main">
                  <img className="dataList-Img" src={item?.imageUrl} alt="" />
                  <p className="dataList-title">{item?.caption}</p>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WebStoriesImageList;
