import { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import Loadingbox from "../../component/loadingbox";
import { API } from "../../config/API";
import WebDataTable from "./component/datatable";
import { Button, DatePicker, Input, Tag, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { PUT } from "../../utils/apirequest";
import moment from "moment";

const WebStories = () => {
  const navigate = useNavigate();
  const [meta, setMeta] = useState();
  const [indicator, setIndicator] = useState<boolean>(true);
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = (filter: any) => {
    let url = API.BASE_URL + API.GET_WEBSTRY;
    axios.post(url, filter).then((response: any) => {
      let imageData = response.data.data;
      let metaData = response.data?.totalCount;
      setData(imageData);
      setPublishCount(response.data?.publishedCount);
      setTrashcount(response.data?.trashedCount);
      setMeta(metaData);
      setIsloading(false);
      setIndicator(false);
    });
  };

  const handleClick = (item: any) => {
    navigate("/Auth/storiesList", {
      state: { item: item },
    });
  };

  const DeleteWebSory = (id: any) => {
    try {
      let url = API.BASE_URL + API.WEB_STORIES + `/${id}`;
      axios.delete(url).then((response: any) => {
        if (response) {
          loadData(filter);
        }
        setIsloading(false);
      });
    } catch (error) {
      throw error;
    }
  };
  const onEdit = (item: any) => {
    navigate(`/Auth/createwebStories`, { state: { item: item } });
  };
  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };
  const addToTrash = async (val: any) => {
    let url = API.WEB_STORIES + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const UndoTrash = async (val: any) => {
    let url = API.WEB_STORIES + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadPublished = () => {
    let filters = {
      ...filter,
      type: "published",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadTrashed = () => {
    let filters = {
      ...filter,
      type: "trash",
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };
  return (
    <>
      <div style={{ height: "95vh", overflow: "scroll" }}>
        <PageHeader title={"WebStories"} loader={indicator} length={`${meta} web stories`}>
          <div className="homescreen-pageItem">
            <div style={{ width: 250 }}>
              <Input
                allowClear
                placeholder="Search WebStories "
                onChange={(e: any) => Search(e.target.value)}
              />
            </div>
            &nbsp;
            <div className="homescreen-pageBox">
              <div
                className="homescreen-pageItem"
                onClick={() => LoadPublished()}
              >
                <span style={{ color: "green" }}>Published</span> : &nbsp;
                <b>{publishCount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadTrashed()}
              >
                <span style={{ color: "red" }}>Trashed</span> : &nbsp;
                <b>{trashcount}</b>
              </div>
            </div>
            &nbsp;
            <Button
              type="primary"
              onClick={() => navigate("/Auth/createwebStories")}
            >
              New Entry +
            </Button>
          </div>
        </PageHeader>
        <div style={{ padding: 5 }} />
        <div className="homescreen-pageItem">
          <div
            style={{
              flex: 5,
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(filter)?.length > 2 ? "Filter By :" : "All WebStories"}{" "}
            &nbsp;
            {Object.keys(filter)?.map(
              (key, index) =>
                key !== "start" &&
                key !== "limit" && (
                  <Tag style={{ marginBottom: 5 }}>
                    {`${key}:  ${
                      key === "createdAt"
                        ? moment(filter[key]).format("DD-MM-YYYY")
                        : filter[key]
                    }`}{" "}
                    <CloseCircleOutlined onClick={() => clearFilters(key)} />
                  </Tag>
                )
            )}
          </div>
          <div style={{ flex: 1 }}>
            <DatePicker
              allowClear
              style={{ width: 200 }}
              onChange={onChangeDate}
            />
          </div>
        </div>

        {isLoading ? (
          <Loadingbox />
        ) : (
          <>
            {/* <div className="webStories-container">
              {data?.map((item: any) => {
                return (
                  <>
                    <div className="webStories-main">
                      <img
                        className="webStories-Img"
                        src={item?.attributes?.image}
                        alt=""
                        onClick={() => handleClick(item?.attributes?.imageList)}
                      />
                      <p className="webStories-title">
                        {item?.attributes?.description}
                      </p>
                      <div
                        className="webStories-Delete"
                        onClick={() => DeleteWebSory(item.id)}
                      >
                        <MdDelete size={24} color="red" />
                      </div>
                      <div
                        className="webStories-Edit"
                        onClick={() => EditeWebSory(item)}
                      >
                        <MdModeEdit size={24} color="blue" />
                      </div>
                    </div>
                  </>
                );
              })}
            </div> */}
            <div style={{ height: "90vh", overflow: "scroll" }}>
              <WebDataTable
                meta={meta}
                data={data}
                page={page}
                pagesize={pageSize}
                onEdit={(val: any) => onEdit(val)}
                onTrash={(val: any) => addToTrash(val)}
                unTrash={(val: any) => UndoTrash(val)}
                refresh={() => loadData(filter)}
                onPageChange={(page: any, pageSize: any) =>
                  onPageChange(page, pageSize)
                }
                handleClick={(val: any) => {
                  handleClick(val);
                }}
                onDelete={(val: any) => DeleteWebSory(val)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WebStories;
