import { Modal } from "antd";
import moment from "moment";
import { Container, Col, Row } from "react-bootstrap";

function Preview(props: any) {
  return (
    <Modal
      title={
        <div style={{ fontSize: 20 }}>
          {props?.item?.edition} - {moment(props?.item?.date).format("l")}
        </div>
      }
      open={props.open}
      footer={null}
      onCancel={() => props.onCancel()}
      width={700}
    >
      <br />
      <Container fluid style={{ margin: 0, padding: 0 }}>
        <Row>
          {props?.item?.epapercollections?.map((item: any, index: number) => {
            return (
              <Col sm={3} xs={12} key={item?.index}>
                <div className="Epaper-previewBox">
                  <img src={item?.imageUrl} style={{ width: "100%" }} />
                  <strong>Page : {index + 1}</strong>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Modal>
  );
}

export default Preview;
