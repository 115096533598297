import {
  Button,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IoTrashOutline } from "react-icons/io5";
import { MdPreview } from "react-icons/md";
import { LuUndo2 } from "react-icons/lu";
import { useSelector } from "react-redux";
import { BiEdit } from "react-icons/bi";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  interface DataType {
    key: string;
    id: number;
    edition: string;
    image: string;
    attributes: JSON;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
      render: (text, record, index) => {
        return <strong>{index + 1}.</strong>;
      },
    },
    {
      title: "Preview",
      key: "image",
      dataIndex: "image",
      width: 20,
      render: (text, record: any) => {
        return (
          <div>
            {record?.image ? (
              <img
                key={record?.image}
                src={record?.image}
                alt="Table Image"
                style={{ height: 40, borderRadius: 6 }}
              />
            ) : (
              <div className="homescreen-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Edition",
      dataIndex: "edition",
      key: "edition",
      width: 300,
      render: (text, record: any, index) => {
        return <div>{record?.edition}</div>;
      },
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
      width: 300,
      render: (text, record: any) => {
        return <div>{record?.user?.username}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      render: (text, record: any, index) => {
        return <div>{moment(record?.createdAt).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "No.Pages",
      width: 50,
      render: (text, record: any, index) => {
        return (
          <div>
            <strong>{record?.epapercollections?.length}</strong> Pages
          </div>
        );
      },
    },
    {
      title: "Status",
      width: 30,
      render: (text, record: any, index) => {
        return record?.publishedAt ? (
          <Tag color="green">Published</Tag>
        ) : (
          <Tag color="blue">Draft</Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: 10,
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" onClick={() => props.showPreview(record)}>
              <MdPreview size={20} cursor={"pointer"} color="blue" />
            </Space>
            <Space>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => props.onDelete(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props?.meta}
          pageSize={props?.pagesize}
          onChange={props.onPageChange}
        />
      </div>
    </div>
  );
}

export default DataTable;
