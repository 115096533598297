import { Button, Checkbox, Form, Input, Select, Space, Tooltip } from "antd";
import { Col, Row } from "react-bootstrap";
import { IoInformationCircleOutline } from "react-icons/io5";
import "../styles.css";

function DynamicForm(props: any) {
  return (
    <div>
      <label className="newsFormLabels">Dynamic Form</label>
      <hr />
      <Row style={{marginBottom:-15}}>
        <Col sm="3">
          <div className="DynamicForm-txt1">
            Name
            <Tooltip
              title={`This should be the name of the form Item. eg: If "Label" is "Full Name"Provide "Name" as "fullName" with no spaces.  `}
            >
              <IoInformationCircleOutline size={20} />
            </Tooltip>
          </div>
        </Col>
        <Col sm="2">
          <div className="DynamicForm-txt1">
            Label
            <Tooltip title={`This should be the Label of the form Item.`}>
              <IoInformationCircleOutline size={20} />
            </Tooltip>
          </div>
        </Col>
        <Col sm="2">
          <div className="DynamicForm-txt1">
            Type
            <Tooltip title={`This should be the Type of the form Item.`}>
              <IoInformationCircleOutline size={20} />
            </Tooltip>
          </div>
        </Col>
        <Col sm="3">
          <div className="DynamicForm-txt1">
            Values
            <Tooltip title={`This should be the Values of the form Item.`}>
              <IoInformationCircleOutline size={20} />
            </Tooltip>
          </div>
        </Col>
        <Col sm="2">
          <div className="DynamicForm-txt1">
            Mandatory Field
            <Tooltip title={`Something is required, obligatory, or compulsory.`}>
              <IoInformationCircleOutline size={20} />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <hr/>
      <Form.List name={[props?.key_name, "fields"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row>
                <Col sm="3">
                  <Form.Item name={[name, "name"]}
                   rules={[{ required: true, message: "Required" }]}>
                    <Input
                      prefix={index + 1 + "."}
                      size="large"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col sm="2">
                  <Form.Item name={[name, "label"]}
                   rules={[{ required: true, message: "Required" }]}>
                    <Input   size="large" placeholder="Enter Label" />
                  </Form.Item>
                </Col>
                <Col sm="2">
                  <Form.Item name={[name, "type"]}
                   rules={[{ required: true, message: "Required" }]}>
                    <Select placeholder="Enter Type" size="large">
                      <Select.Option key={"Input"}>Input</Select.Option>
                      <Select.Option key={"Radio"}>Radio</Select.Option>
                      <Select.Option key={"Number"}>Number</Select.Option>
                      <Select.Option key={"PhoneNumber"}>
                        Phone Number
                      </Select.Option>
                      <Select.Option key={"TextArea"}>Text Area</Select.Option>
                      <Select.Option key={"Select"}>Select</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm="3">
                  <Form.Item name={[name, "values"]}>
                    <Input size="large" placeholder="Enter Values" />
                  </Form.Item>
                </Col>
                <Col sm="2">
                  <div className="DynamicForm-txt1">
                    <Form.Item name={[name, "mandatory"]} style={{width:"100%"}}>
                    <Select placeholder="Select" style={{width:"100%"}}>
                    <Select.Option key={"1"}>Yes</Select.Option>
                      <Select.Option key={"0"}>No</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button block danger onClick={() => remove(index)}>
                        Remove
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ))}
            <Row>
              <Col sm={8}></Col>
              <Col sm={4}>
                <Button block size="large" type="dashed" onClick={() => add()}>
                  Add Fields +
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );
}

export default DynamicForm;
