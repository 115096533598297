import React, { useState, useRef } from "react";
import moment from "moment";
import { Button, Modal, Tooltip } from "antd";
import { uploadAudioToS3 } from "../../config/s3config/s3fileUpload";
import { Container, Row, Col } from "react-bootstrap";
import recorder from "../../assets/image/audiowave.gif";
import { CiMicrophoneOn } from "react-icons/ci";
import { BsFillPlayFill } from "react-icons/bs";
import mic from "../../assets/image/microphone.png"
import "./styles.css";

function Audiorecorder(props: any) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<any>();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<any[]>([]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.addEventListener("dataavailable", handleDataAvailable);
      mediaRecorder.start();
      setIsRecording(true);
      const audioBlob = new Blob(chunksRef.current, { type: "audio/mpeg" });
      const audioUrl = URL.createObjectURL(audioBlob);

      setAudioURL(audioUrl);
    });
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
  };

  const handleDataAvailable = (e: BlobEvent) => {
    chunksRef.current.push(e.data);
  };

  const handlePlayback = () => {
    const audioBlob = new Blob(chunksRef.current, { type: "audio/mpeg" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioURL(audioUrl);
  };
  const onUpload = () => {
    const fileName = `${moment().format(
      "YYYY-MM-DDHH:MM:SS"
    )}suprabhaatham-news.`;
    const audioBlob = new Blob(chunksRef.current, { type: "audio/mpeg" });
    const audioFile = new File([audioBlob], fileName, {
      type: "audio/mpeg",
      lastModified: Date.now(),
    });

    const response = uploadAudioToS3("mpeg", "audio", audioFile, fileName);
    response
      .then((Promise) => {
        setAudioURL(Promise.Location);
      })
      .catch((error) => {
        console.log("Error uploading to S3:", error);
      });
  };
  let AudioModal = () => {
    return (
      <Container fluid>
        <Row>
          <Tooltip title={"Please Click to Start Recording / Stop Listening"}>
            {!isRecording ? (
              <>
              <Col md={12} sm={12}
                style={{ display: "flex", justifyContent: "center"}} 
                onClick={startRecording}              
              >
               <img 
               style={{width:"200px"}}
               src={mic}/>
            
              </Col>
              <Col  md={12} sm={12}
              style={{ display: "flex", justifyContent: "center",fontFamily:"Montserrat-Medium"}}
              >
                click to start
              </Col>
              </>
            ) : null}
          </Tooltip>
          
        </Row>
        
        <br />
        {isRecording ? (
          <>
            <Row>
              <Col>
                <img style={{ width: "100%" }} src={recorder} />
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent:  "center" }}>
              <Button className="stop" onClick={stopRecording}>
                stop
              </Button>
            </Row>
            <br />
          </>
        ) : null}

        {audioURL && (
          <>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={"please click to play "}>
                <Button
                  onClick={() => {
                    handlePlayback()
                    onUpload()
                  }}
                >
                  Play 
                </Button>
              </Tooltip>
            </Col>
            <br />
            <br />
            <Col md={12} sm={12}
            style={{display:"flex",justifyContent:"center"}}
            >
              <audio style={{width:"225px"}} src={audioURL} controls />
            </Col>
           
          </Row>
          <br/>
        <Row>
          <Col sm="6" xs="12">
            <button className="closebutton" onClick={() => props.close()}>
              Close
            </button>
          </Col>
          <Col
            sm="6"
            xs="12"
            className="submitbuttion"
            onClick={() => {
              handlePlayback()
              props.getData(audioURL)
              props.close()
            }}
          >
            Upload
          </Col>
        </Row>
        </>
         )}
      </Container>
    );
  };
  return (
    <Modal
      open={props.visible}
      centered
      width={450}
      onCancel={() => props.close()}
      closable={true}
      footer={false}
    >
      <AudioModal />
    </Modal>
  );
}

export default Audiorecorder;
