const getInitialValues = (items: any) => {
  const tags = items?.newsTag?.split("|");
  return {
    title: items && items.id && items.title,
    head: items?.head,
    permalink: items && items.id && items && items.permalink,
    user_id: items && items.id && items && items.user_id,
    image: items && items.id && items && items.image,
    categories:
      items &&
      items.id &&
      items &&
      items.categories &&
      items.categories.map((item: any) => {
        let obj = {
          id: item?.id,
          category: item?.category,
        };
        let category = JSON.stringify(obj);
        return category;
      }),
    subcategory:
      items &&
      items.id &&
      items.Subcategories &&
      items.Subcategories.map((item: any) => {
        return item.id;
      }),
    tags:
      tags &&
      tags.length &&
      tags.map((item: any) => {
        return item;
      }),
    author_name:
      (items && items.id && items && items.author_name) || "Web Desk",
    Show: items.onlive,
    isHighPriority:
      items?.isHighPriority === true
        ? 1
        : items?.isSecondLeads === true
        ? 2
        : false,
  };
};

const PopulateSubCategory = (val: any, subcategory: any) => {
  let arr: any[] = [];
  val.forEach((item: any) => {
    let cat = JSON.parse(item);
    const matchingCategories = subcategory.filter((category: any) => {
      return (
        category.attributes.category.data &&
        category.attributes.category.data.id === cat.id
      );
    });
    if (matchingCategories.length > 0) {
      arr.push(...matchingCategories);
    }
  });
  return arr;
};

const PreviewData = (form: any, body: any) => {
  const headValue = form.getFieldValue("head");
  const tagvalue = form.getFieldValue("tags");
  const categoryvalue = form.getFieldValue("categories");
  const byline = form.getFieldValue("author_name");

  const previewtag = body.tags?.filter((item: any) => {
    return tagvalue?.includes(item?.id);
  });

  const previewcategory2 = categoryvalue?.map((item: any) => {
    const prasedata = JSON.parse(item);
    return prasedata?.category;
  });

  let obj: any = {
    head: headValue,
    title: body.titleValue,
    body: body.body,
    tinyImage: body.imageBase64,
    image:
      body?.items?.id && body?.items?.image ? body?.items?.image : body.pic1,
    tag:
      body?.items?.id && body?.items?.tags
        ? body?.items?.tags?.data.map((item: any) => {
            return item?.name;
          })
        : previewtag?.map((item: any) => {
            return item?.tag;
          }),
    byline: byline,

    category: body?.items?.categories?.data
      ? body?.items?.categories?.data.map((item: any) => {
          return item?.category;
        })
      : previewcategory2?.map((item: any) => item),
  };
  let array = [] as any;
  array.push(obj);
  return array;
};

export { getInitialValues, PopulateSubCategory, PreviewData };
