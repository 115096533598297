import { API } from "../config/API";

const LoadTags = () => {
  let url = API.BASE_URL + API.TAGS_LIST_ALL;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueIds = new Set();
        const combinedResults: any = [];
        data.forEach((item: any) => {
          if (!uniqueIds.has(item.tag)) {
            uniqueIds.add(item.tag);
            combinedResults.push(item);
          }
        });
        resolve(combinedResults);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const LoadCategories = () => {
  let url = API.BASE_URL + API.CATEGORY;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const LoadSubCategories = () => {
  let url =
    API.BASE_URL +
    API.SUBCATEGORY +
    `?populate[0]=category&pagination[limit]=100`;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { LoadTags, LoadCategories, LoadSubCategories };
