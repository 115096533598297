import React, { useState } from "react";
import MediaLibrary from "../../../component/image-picker";
import "../styles.css";
import { Button } from "antd";
import { FiUploadCloud } from "react-icons/fi";
function ImagePicker(props: any) {
  const [show, setShow] = useState<any>(false);

  return (
    <>
      <div>
        {props?.image ? (
          <div className="ElectionItem-imageBox1">
            <img src={props?.image} className="ElectionItem-image1" />
            <div className="quiz-BannerImage4">
              <div>
                <Button
                  size="small"
                  danger
                  onClick={(val: any) => props?.onChagne(null)}
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="quiz-BannerImage" onClick={() => setShow(!show)}>
            <FiUploadCloud size={20} color="grey" />
            <div style={{ margin: 5 }} />
            <div>Select Image</div>
          </div>
        )}
      </div>
      {show ? (
        <MediaLibrary
          visible={show}
          close={() => setShow(false)}
          onSubmit={(val: any) => props?.onChagne(val?.image_url)}
        />
      ) : null}
    </>
  );
}

export default ImagePicker;
