import { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import DataTable from "./component/datatable";
import Loadingbox from "../../component/loadingbox";
import { API } from "../../config/API";
import { PUT } from "../../utils/apirequest";
import { Button, DatePicker, Input, Select, Tag, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { HiRefresh } from "react-icons/hi";

function NjayarScreen() {
  const navigate = useNavigate();
  const [isTable, setIsTable] = useState(true);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [meta, setMeta] = useState();
  const [settingsList, setSettingsList] = useState<any[]>([]);
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [tag, setTAg] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;
  const [usersList, setusersList] = useState<any[]>([]);

  useEffect(() => {
    loadData(filter);
    loadTag();
    GET_USERS();
  }, []);

  const loadData = (filter: any) => {
    setIsloading(true);
    let url = API.BASE_URL + API.GET_NJYAR;
    axios
      .post(url, filter)
      .then((response) => {
        let data = response.data.data;
        let metaData = response.data.totalCount;
        setData(data);
        setPublishCount(response.data?.publishedCount);
        setTrashcount(response.data?.trashedCount);
        setMeta(metaData);
        getSettings();
        setIsloading(false);
        setIndicator(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };
  const getSettings = () => {
    let url = API.BASE_URL + API.SETTINGS_LIST;
    axios.get(url).then((response) => {
      setSettingsList(response?.data?.data[0]?.attributes);
    });
  };
  const GET_USERS = () => {
    let url = API.BASE_URL + API.GET_USERS;
    axios.get(url).then((response) => {
      setusersList(response.data);
    });
  };

  const loadTag = () => {
    let url =
      API.BASE_URL +
      API.TAGS_LIST +
      `?pagination[page]=1&pagination[pageSize]=100`;
    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
          setTAg(json.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addToTrash = async (val: any) => {
    let url = API.NJAYAR_MASTER + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const UndoTrash = async (val: any) => {
    let url = API.NJAYAR_MASTER + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };
  const LoadByUser = (id: any) => {
    let filters = {
      ...filter,
      userId: id,
    };
    setFilter(filters);
    loadData(filters);
  };
  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadPublished = () => {
    let filters = {
      ...filter,
      type: "published",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadTrashed = () => {
    let filters = {
      ...filter,
      type: "trash",
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };
  const TAgChange = (tag: any) => {
    let filters = {
      ...filter,
      tagId: tag,
    };
    setFilter(filters);
    loadData(filters);
  };

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      {isTable ? (
        <PageHeader title={"Njayar-Pra."}>
          <div className="homescreen-pageItem">
            <div style={{ width: 250 }}>
              <Input
                allowClear
                placeholder="Search Article "
                onChange={(e: any) => Search(e.target.value)}
              />
            </div>
            <div style={{ width: 200 }}>
              <DatePicker
                allowClear
                style={{ width: 200 }}
                onChange={onChangeDate}
              />
            </div>
            <div>
              <Select
                placeholder="Created By"
                onChange={(val) => LoadByUser(val)}
                showSearch={true}
                style={{ width: 200 }}
                allowClear
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {usersList?.map((item: any, index: any) => {
                  return (
                    <Select.Option key={index} value={item.id}>
                      {item.username}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Button
              type="primary"
              onClick={() => navigate("/Auth/NjayarScreen/NjayarForm")}
            >
              Add New +
            </Button>
          </div>
        </PageHeader>
      ) : null}
      <div style={{ padding: 5 }} />
      <div className="homescreen-pageItem">
        <div
          style={{
            flex: 5,
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(filter)?.length > 2
            ? "Filter By :"
            : "Njayar-Prabhaatham"}{" "}
          &nbsp;
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag style={{ marginBottom: 5 }}>
                  {`${key}:  ${
                    key === "createdAt"
                      ? moment(filter[key]).format("DD-MM-YYYY")
                      : filter[key]
                  }`}{" "}
                  <CloseCircleOutlined onClick={() => clearFilters(key)} />
                </Tag>
              )
          )}
        </div>
        <div className="homescreen-pageBox">
          <div className="homescreen-pageItem" onClick={() => LoadPublished()}>
            &nbsp;
            <span style={{ color: "green" }}>Published</span> : &nbsp;
            <b>{publishCount}</b>
            &nbsp;
          </div>
          |
          <div className="homescreen-pageItem" onClick={() => LoadTrashed()}>
            &nbsp;
            <span style={{ color: "red" }}>Trashed</span> : &nbsp;
            <b>{trashcount}</b>
            &nbsp;
          </div>
        </div>
        &nbsp;
        <Select
          showSearch={true}
          placeholder="Tags"
          onChange={(val) => TAgChange(val)}
          style={{ width: 200 }}
          allowClear
          filterOption={(input: any, option: any) =>
            option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
          }
        >
          {tag.length > 0 &&
            tag.map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item.id}>
                  {item.attributes.tag}
                </Select.Option>
              );
            })}
        </Select>
        &nbsp;
        <div>
          <Button
            type="primary"
            ghost
            icon={<HiRefresh />}
            onClick={() => loadData(filter)}
          >
            Refresh
          </Button>
        </div>
        &nbsp;
      </div>
      <div className="">
        {isloading ? (
          <Loadingbox />
        ) : (
          <div className="mt5">
            <DataTable
              data={data}
              meta={meta}
              page={page}
              pagesize={pageSize}
              onTrash={(val: any) => addToTrash(val)}
              unTrash={(val: any) => UndoTrash(val)}
              onPageChange={(page: any, pageSize: any) =>
                onPageChange(page, pageSize)
              }
              refresh={() => loadData(filter)}
              settings={settingsList}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NjayarScreen;
