import { Button, Form, Input, Select, Tag, message, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Loadingbox from "../../../component/loadingbox";
import { API } from "../../../config/API";
import TextArea from "antd/es/input/TextArea";
import PageHeader from "../../../component/pageHeaders/index";
import { useParams } from "react-router-dom";
import { addTags, getNewsById } from "../../newsScreen/utils/apiRequest";
import { uploadImageToS3 } from "../../../config/s3config/s3fileUpload";
import moment from "moment";
// import ImagePicker from "../../../component/image-picker";
import ImagePicker from "../../../component/imagePicker";
import { CREATELOGS } from "../../../utils/apirequest";
import ImageItem from "../../../component/imageItem";
import { IoCopyOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { LoadTags } from "../../../utils/commonapis";

function NewForm(props: any) {
  const [form] = Form.useForm();
  let { id } = useParams();

  const User = useSelector((state: any) => state.User.user);
  const Tags = useSelector((state: any) => state.Tags?.data);

  const [content, setContent] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [type, setType] = useState(null) as any;
  const [imageUploader, setImageUploader] = useState(false);
  const [data, setData] = useState<any>();
  const [imageType, setImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageuploaddata, setImageuploaddata] = useState<any>({
    imageurl1: "",
    imageurl2: "",
    imageurl3: "",
  });
  const [returnedNewsId, setReturnedNewsId] = useState() as any;
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    loadTags();
    if (id !== undefined) {
      LoadDAta(id);
    }
  }, []);

  const loadTags = async () => {
    try {
      if (!Tags.length) {
        const res: any = await LoadTags();
        setTags(res);
      } else {
        setTags(Tags);
      }
    } catch (error) {
    } finally {
      setLoading3(false);
    }
  };

  const { imageurl1, imageurl2, imageurl3 } = imageuploaddata;
  let pic1 = imageurl1;
  let pic2 = imageurl2;
  let pic3 = imageurl3;

  const LoadDAta = async (id: any) => {
    let url = API.BASE_URL + API.ARTICLE + `/${id}?populate=*`;
    try {
      const response: any = await getNewsById(url);
      if (response) {
        const tags = response?.data?.data?.attributes?.articleTag?.split("|");
        setData(response?.data?.data);
        setContent(response?.data?.data?.attributes?.body);
        setImageuploaddata({
          imageurl1: response?.data?.data?.attributes?.image1
            ? response?.data?.data?.attributes?.image1
            : "",
          imageurl2: response?.data?.data?.attributes?.image2
            ? response?.data?.data?.attributes?.image2
            : "",
          imageurl3: response?.data?.data?.attributes?.profile
            ? response?.data?.data?.attributes?.profile
            : "",
        });
        form.setFieldsValue({
          title: response?.data?.data?.attributes?.head,
          author: response?.data?.data?.attributes?.author,
          series: response?.data?.data?.attributes?.seriesName,
          permalink: response?.data?.data?.attributes?.permalink,
          tags:
            tags &&
            tags.length &&
            tags.map((item: any) => {
              return item;
            }),
        });
      } else {
        console.log("=======error=======");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditorChange = (content: any, editor: any) => {
    setContent(content);
  };

  const onFinish = async (type: any) => {
    setLoading(true);
    setType(type);
    let values = form.getFieldsValue();

    const tagsfilteredArray = values?.tags?.join("|");

    let requestObj = {
      data: {
        head: values?.title,
        author: values?.author,
        body: content,
        profile: pic3,
        image1: pic1 ? pic1 : "",
        permalink: values?.permalink,
        articleTag: tagsfilteredArray,
        user: id !== undefined ? data?.user?.id : User?.id,
        publishedAt:
          type === "publish"
            ? new Date()
            : data?.attributes?.publishedAt || null,
      },
    };
    let url =
      id !== undefined
        ? API.BASE_URL + API.ARTICLE + `/${data?.id}`
        : API.BASE_URL + API.ARTICLE;
    let method = id !== undefined ? "PUT" : "POST";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        let obj = {
          userId: User?.id,
          itemId: response?.data?.data?.id,
          payload: requestObj,
          type: "articles",
        };
        CREATELOGS(obj);
        setReturnedNewsId(response?.data?.data?.id);
        setLoading(false);
        message.success(`Article ${id ? "Updated" : "Created"} Successfully.`);
        addTags(values?.tags);
        setTimeout(() => {
          window.location.replace(
            `/Auth/Article/createForm/${response?.data?.data?.id}`
          );
        }, 250);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const addImagesToList = (data: any) => {
    try {
      let DataObj = imageuploaddata;
      if (data.type === "image1") {
        DataObj["imageurl1"] = "";
        DataObj["imageurl1"] = data.image_url;
        DataObj["image1Value"] = "";
        DataObj["image1Value"] = data.image_desc;
      } else if (data.type === "image2") {
        DataObj["imageurl2"] = "";
        DataObj["imageurl2"] = data.image_url;
        DataObj["image2Value"] = "";
        DataObj["image2Value"] = data.image_desc;
      } else if (data.type === "image3") {
        DataObj["imageurl3"] = "";
        DataObj["imageurl3"] = data.image_url;
        DataObj["image3Value"] = "";
        DataObj["image3Value"] = data.image_desc;
      }
      setImageuploaddata(DataObj);
    } catch (err) {
      console.log(err);
    }
  };

  const addImage = (image: any) => {
    if (window.tinymce && window.tinymce.activeEditor) {
      const editor = window.tinymce.activeEditor;
      let Moment = `${moment().format(
        "YYYY-MM-DDHH:MM:SS"
      )}.suprabhaatham-news.png`;
      let alt = image?.image_desc ? image?.image_desc : Moment;
      editor.insertContent(
        `<div style="display: inline-block; text-align: center;">
        <img src="${image?.image_url}" alt="${alt}" style="max-width: 100%; display: block; margin: 0 auto;" onload="this.parentElement.style.width = this.width + 'px';" />
        <div style="display: flex; justify-content: space-between; margin-top: 5px;">
          <div style="flex: 1; text-align: left;">${image?.image_desc}</div>
          <div style="flex: 1; text-align: right;">${image?.image_Caption}</div>
        </div>
      </div>`
      );
      setImageUploader(false);
    }
  };

  function deleteImagesFromList(key: any) {
    try {
      setImageuploaddata((prevImages: any) => {
        const updatedImages = { ...prevImages };
        updatedImages[key] = "";
        return updatedImages;
      });
      notification.success({
        message: `Image Removed`,
      });
    } catch (err) {
      notification.error({
        message: `Failed to remove`,
      });
    }
  }

  const handleUrlCopy = (type: any) => {
    const perma = form.getFieldValue("permalink");
    let formattedPerma = perma?.replace(/\s+/g, "-");
    const link = `${API.APP_URL}todaysarticle?id=${
      returnedNewsId ? returnedNewsId : data?.id
    }&&${formattedPerma ? formattedPerma : ""}`;
    if (type === "copy") {
      navigator.clipboard.writeText(link).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(link, "_blank");
    }
  };

  const addNew = () => {
    window.location.replace(`/Auth/Article/createForm `);
  };

  const checkPermaLink = (e: any) => {
    const regex = /^[a-zA-Z0-9- ]*$/;
    const inputValue = e.target.value;
    const filteredValue = inputValue
      .split("")
      .filter((char: any) => regex.test(char))
      .join("");
    form.setFieldsValue({ permalink: filteredValue });
  };

  return (
    <div style={{ height: "90vh", overflowY: "scroll" }}>
      <PageHeader
        title={`${
          props?.id !== null && props?.id !== undefined ? "Edit" : "Create"
        } Article`}
      >
        <div className="homescreen-pageItem">
          {id !== null && id !== undefined ? (
            <div>
              <Tag
                style={{ padding: 5, paddingLeft: 20, paddingRight: 20 }}
                color={data?.attributes?.publishedAt ? "green" : "volcano"}
              >
                {data?.attributes?.publishedAt ? "Published" : " Draft"}
              </Tag>
              <Button
                onClick={() => handleUrlCopy("preview")}
                disabled={data && !data.id}
              >
                Website Preview
              </Button>
              &nbsp;
              <Button type="primary" onClick={addNew}>
                Create New +
              </Button>
            </div>
          ) : null}
        </div>
      </PageHeader>
      <Form
        onFinish={onFinish}
        form={form}
        name="add-editorials"
        scrollToFirstError
      >
        <Container fluid>
          <Row>
            <Col sm={9} xs={12}>
              <Row>
                <Col sm={6} xs={12}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item name="title">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm={6} xs={12}>
                  <label className="newsFormLabels">Author</label>
                  <Form.Item name="author">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={12}>
                  {loading2 && <Loadingbox />}
                  <Editor
                    // apiKey={API.EDITOR_API_KEY}
                    value={content}
                    init={{
                      height: 500,
                      menubar: true,
                      directionality: "ltr",
                      plugins:
                        "advlist autolink lists link image pageembed code preview charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount pageembed code preview image code",
                      toolbar:
                        "  | bold italic | \
                   alignleft aligncenter alignright | \
                   bullist numlist outdent indent |subscript superscript |link unlink| help | forecolor backcolor | \
                   \
                   \
                   \
                   | image | myCustomButton  ",
                      setup: (editor) => {
                        editor.ui.registry.addButton("myCustomButton", {
                          text: "Media Library",
                          icon: "gallery",
                          onAction: () => {
                            setImageType("Media");
                            setImageUploader(true);
                          },
                        });
                      },

                      file_browser_callback_types: "image",
                      file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "image") {
                          var input = document.getElementById("my-file") as any;
                          input.click();
                          input.onchange = function () {
                            const name = input.files[0].name;
                            const sliced = name.slice(0, name.length - 5);
                            var file = input.files[0];
                            const fileName = `${moment().format(
                              "YYYY-MM-DDHH:MM:SS"
                            )}${sliced}suprabhaatham-news.png`;
                            uploadImageToS3(file, fileName)
                              .then((s3ImageUrl: any) => {
                                callback(s3ImageUrl.Location, {
                                  alt: file.name,
                                });
                              })
                              .catch((error: any) => {
                                console.error(
                                  "Error uploading image to S3:",
                                  error
                                );
                              });
                          };
                        }
                      },
                      paste_data_images: true,
                    }}
                    onEditorChange={handleEditorChange}
                    onInit={() => setLoading2(false)}
                  />
                </Col>
                <br />
                <Col sm={4}>
                  <div className="newsFormLabels">News Image 1</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl1}
                    upload={() => {
                      if (imageuploaddata?.imageurl1 === "") {
                        setImageType("image1");
                        setImageName("Image 1");
                        setImageUploader(true);
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl1")}
                  />
                </Col>
                <Col sm={4}>
                  <div className="newsFormLabels">News Image 2</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl2}
                    upload={() => {
                      if (imageuploaddata?.imageurl2 === "") {
                        setImageType("image2");
                        setImageName("Image 2");
                        setImageUploader(true);
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl2")}
                  />
                </Col>
                <Col sm={4}>
                  <div className="newsFormLabels">Author Image</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl3}
                    upload={() => {
                      if (imageuploaddata?.imageurl3 === "") {
                        setImageType("image3");
                        setImageName("Social Media Image");
                        setImageUploader(true);
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl3")}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="4" />
                <Col sm="4">
                  <Button
                    block
                    style={{ height: 50 }}
                    onClick={() => onFinish("save")}
                    loading={loading && type === "save"}
                  >
                    {id !== undefined ? " Update" : "Save"}
                  </Button>
                </Col>
                <Col sm="4">
                  <Button
                    disabled={data?.attributes?.publishedAt ? true : false}
                    block
                    type="primary"
                    style={{ height: 50 }}
                    loading={loading && type === "publish"}
                    onClick={() => onFinish("publish")}
                  >
                    Publish
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={3} xs={12}>
              <div className="newsFormBox">
                <div className="newsFormLabels">Tags</div>
                <Form.Item name="tags">
                  <Select
                    mode="tags"
                    loading={loading3}
                    style={{ width: "100%" }}
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tags.length > 0 &&
                      tags.map((item: any, index: any) => (
                        <Select.Option key={index} value={item?.tag}>
                          {item?.tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <div className="homescreen-pageBox2">
                  <div className="newsFormLabels" style={{ flex: 1 }}>
                    Perma-Link
                  </div>
                  <div>
                    {returnedNewsId || data?.id ? (
                      <IoCopyOutline
                        size={18}
                        color="green"
                        onClick={() => handleUrlCopy("copy")}
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                </div>
                <Form.Item
                  name="permalink"
                  rules={[
                    {
                      validator: (_, value) => {
                        const regex = /^[a-zA-Z0-9- ]*$/;
                        return regex.test(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              "Only alphanumeric characters, spaces, and dashes are allowed."
                            );
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    onChange={checkPermaLink}
                    size="large"
                    lang="en"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
      {imageUploader ? (
        <ImagePicker
          mode="create"
          imageType={imageType}
          imageName={imageName}
          onSelect={addImage}
          onSubmit={(val: any) => addImagesToList(val)}
          visible={imageUploader}
          close={() => setImageUploader(false)}
        />
      ) : null}
    </div>
  );
}

export default NewForm;
