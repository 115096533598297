import React, { useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import "../styles.css";
import ImagePicker from "../../../component/image-picker";
import { Button } from "antd";
function BannerImage(props: any) {
  const [show, setShow] = useState<any>(false);
  return (
    <>
      <div>
        {props?.image ? (
          <div className="quiz-BannerImage2">
            <img src={props?.image} className="quiz-BannerImage3" />
            <div className="quiz-BannerImage4">
              <div className="quiz-txt1">{props?.image}</div>
              <div>
              <Button
                size="small"
                danger
                onClick={(val: any) => props?.onChagne(null)}
              >
                Remove
              </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="quiz-BannerImage" onClick={() => setShow(!show)}>
            <FiUploadCloud size={30} color="grey" />
            <div>Select Image</div>
          </div>
        )}
      </div>
      {show ? (
        <ImagePicker
          visible={show}
          close={() => setShow(false)}
          onSubmit={(val: any) => props?.onChagne(val?.image_url)}
        />
      ) : null}
    </>
  );
}

export default BannerImage;
