"use client";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LightLogo from "../../../assets/image/logo_light.png";
import { FiMenu } from "react-icons/fi";
import { RiTwitterFill } from "react-icons/ri";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoSunny } from "react-icons/io5";
import Timer from "./timer";
import "../styles.css";
import Category from "./category";
import { FaRegUserCircle } from "react-icons/fa";

export default function Header(props: any) {
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState<any>(0);
  const [showHeader, setshowHeader] = useState<any>(0);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (lastScrollY > window.scrollY) {
        setLastScrollY(false);
        setshowHeader(false);
      } else if (lastScrollY + 250 < window.scrollY) {
        setLastScrollY(true);
        setshowHeader(true);
      }
    },
    [lastScrollY, showHeader]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleNavigation);
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      <nav className="Header_MainBox">
        <div>
          <div className="Header_SubBox">
            <Container fluid={false} className="Header_Container">
              <Row>
                <Col sm={4} xs={2} md={4} lg={4} xl={4} xxl={4}>
                  <div className="Header_IconBox">
                    <div style={{ cursor: "pointer" }}>
                      <FiMenu
                        size={30}
                        color={"#0055A6"}
                        onClick={() => setShow(true)}
                      />
                    </div>
                    <div className="Header_Icons">
                      <Timer />
                    </div>
                    <div></div>
                  </div>
                </Col>
                <Col sm={4} xs={8} md={4} lg={4} xl={4} xxl={4}>
                  <div className="Header_LogoBox">
                    <div className="Header_space" />
                    <div
                      onClick={() => {
                        window.location.reload();
                        window.location.href = "/";
                      }}
                    >
                      <img className="Header_Logo" src={LightLogo} />
                    </div>
                    <div className="Header_Logotxt">
                      <a
                        className="Header_txt5"
                        href="https://epaper.suprabhaatham.com/"
                        target="_blank"
                      >
                        E-PAPER
                      </a>{" "}
                      |{" "}
                      <a
                        className="Header_txt5"
                        href="https://www.youtube.com/@Suprabhaatham2023"
                        target="_blank"
                      >
                        WEB CHANNEL
                      </a>{" "}
                      | <span className="Header_txt5">APP</span>
                    </div>
                  </div>
                </Col>
                <Col sm={4} xs={2} md={4} lg={4} xl={4} xxl={4}>
                  <div className="Header_IconBox">
                    <div style={{ width: "40%" }}></div>
                    <div className="Header_Icons">
                      <a
                        href="https://www.youtube.com/@Suprabhaatham2023"
                        target="_blank"
                      >
                        <AiOutlineYoutube size={20} color="#000" />
                      </a>
                    </div>
                    <div className="Header_Icons">
                      <a
                        href="https://twitter.com/suprabhaatham?lang=en"
                        target="_blank"
                      >
                        <RiTwitterFill size={18} color="#000" />
                      </a>
                    </div>
                    <div className="Header_Icons">
                      <a
                        href="https://www.instagram.com/suprabhaathamonline/?hl=en"
                        target="_blank"
                      >
                        <FaInstagram size={20} color="#000" />
                      </a>
                    </div>
                    <div className="Header_Icons">
                      <a
                        href="https://www.facebook.com/Suprabhaatham/"
                        target="_blank"
                      >
                        <FaFacebookF size={18} color="#000" />
                      </a>
                    </div>

                    <div className="Header_Icons2">
                      <div className="Header_Icons3">
                        <IoSunny size={22} color={"#FDB813"} />
                      </div>
                    </div>
                    <div className="Header_Icons2">
                      <div className="Header_Icons3">
                        <FaRegUserCircle size={22} color={"gray"} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Category />
        </div>
      </nav>
    </>
  );
}
