import { useEffect, useRef, useState } from "react";
import { Button, Input, message, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import QRCode from "react-qr-code";

import { IoCopyOutline } from "react-icons/io5";
import { MdDownload } from "react-icons/md";
import {
  AiOutlineFacebook,
  AiOutlineWhatsApp,
  AiOutlineTwitter,
} from "react-icons/ai";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { API } from "../../../config/API";

function ShareModal(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [linkso, setLinkso] = useState<any>(null);

  useEffect(() => {
    let link = props?.item?.permalink?.replace(/\s+/g, "-");
    let url = `${API.APP_URL}quizz?id=${props?.item?.id}&link=${link}`;
    setLinkso(url);
  }, [props?.item]);

  const copyLink = () => {
    navigator.clipboard.writeText(linkso).then(() => {
      message.success("link copied");
    });
  };

  const downloadQRCode = () => {
    try {
      const qrCodeElement = ref.current?.querySelector("svg");
      if (!qrCodeElement) return;
      const svgData = new XMLSerializer().serializeToString(qrCodeElement);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.href = pngFile;
        downloadLink.download = "QRCode.png";
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    } catch (err) {
      message.error("download Faild");
    }
  };

  return (
    <Modal
      visible={props?.visible}
      onCancel={() => props?.onCancel()}
      footer={false}
      width={350}
    >
      <div style={{ marginTop: -10 }}>{props?.item?.title}</div>
      <hr />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div ref={ref}>
          <QRCode value={linkso} />
        </div>
        <br />
        <Row>
          <Col sm={4}>
            <FacebookShareButton url={linkso}>
              <Button type="default" block>
                <AiOutlineFacebook color="#000" size={20} />
              </Button>
            </FacebookShareButton>
          </Col>
          <Col sm={4}>
            <WhatsappShareButton url={linkso}>
              <Button type="default" block>
                <AiOutlineWhatsApp color="#000" size={20} />
              </Button>
            </WhatsappShareButton>
          </Col>
          <Col sm={4}>
            <TwitterShareButton url={linkso}>
              <Button type="default" block>
                <AiOutlineTwitter color="#000" size={20} />
              </Button>
            </TwitterShareButton>
          </Col>
        </Row>

        <br />
        <Input
          size="large"
          value={linkso}
          suffix={
            <IoCopyOutline size={20} color="green" onClick={() => copyLink()} />
          }
        />
      </div>

      <br />
      <Row>
        <Col sm={6}>
          <Button
            block
            type="primary"
            ghost
            size="large"
            icon={<IoCopyOutline />}
            onClick={() => copyLink()}
          >
            Copy Link
          </Button>
        </Col>
        <Col sm={6}>
          <Button
            block
            type="primary"
            size="large"
            icon={<MdDownload />}
            onClick={downloadQRCode}
          >
            Download
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default ShareModal;
