import React, { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, Input, Select, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loadingbox from "../../../component/loadingbox";
import FrameImage from "./frameImages";
import { useParams } from "react-router-dom";
import { LoadTags } from "../../../utils/commonapis";
import { API } from "../../../config/API";
import { PUT, POST } from "../../../utils/apirequest";
import axios from "axios";

function NewForm() {
  let { id } = useParams();
  const [form] = Form.useForm();

  const [data, setData] = useState({}) as any;
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isLoading1, setIsLoading1] = useState<any>(false);
  const [type, setType] = useState(null) as any;
  const [tags, setTags] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [frameImg, setFrameImg] = useState("");

  useEffect(() => {
    if (id !== "create") {
      getData();
    } else {
      setIsLoading(false);
    }
    loadTags();
  }, []);

  const getData = async () => {
    try {
      let url = API.BASE_URL + API.FRAME_DETAILS + id + `?populate[0]=tags`;
      const results = await axios.get(url);
      if (results?.data?.data) {
        let data = results?.data?.data?.attributes;
        setData(data);
        setDescription(data?.description);
        setFrameImg(data?.image);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const loadTags = async () => {
    const res: any = await LoadTags();
    setTags(res);
  };

  const Finished = async (type: any) => {
    try {
      setIsLoading1(true);
      setType(type);
      form.submit();
      await form.validateFields();
      let values = form.getFieldsValue();
      console.log("values", values);
      var requestObj = {
        data: {
          head: values?.head,
          title: values?.title,
          permalink: values?.permalink,
          tags: values?.tags,
          description: description,
          image: frameImg,
          publishedAt:
            type === "publish" ? new Date() : data?.publishedAt || null,
        },
      };
      let url = id !== "create" ? API.FRAME_EDIT + id : API.FRAME_ADD;
      let METHOD = id !== "create" ? PUT : POST;
      let res: any = await METHOD(url, requestObj);
      if (res?.data?.id) {
        message.success(
          `Frames ${id !== "create" ? "Updated" : "Created"} Successfully.`
        );
        setTimeout(() => {
          window.location.replace(`/Auth/frames/${res?.data?.id}`);
        }, 200);
      }
      console.log("res", res);
    } catch (err) {
      console.log(err);
      setIsLoading1(false);
    }
  };

  return (
    <div style={{ height: "95vh", overflow: "scroll" }}>
      <PageHeader title={"Frames"} length={`Dashboard / Frames / Create`} />
      <br />
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div>
            <Form
              form={form}
              initialValues={{
                title: data?.title,
                head: data?.head,
                permalink: data?.permalink,
                description: data?.description,
                tags: data?.tags?.data?.map((item: any) => item?.id),
              }}
            >
              <Row>
                <Col sm={5}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item
                    name={"title"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Heading</label>
                  <Form.Item
                    name={"head"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Perma-Link</label>
                  <Form.Item
                    name={"permalink"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Tags</label>
                  <Form.Item
                    name="tags"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      style={{ background: "transparent" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {tags.length &&
                        tags.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item.id}>
                              {item.tag}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={7}>
                  <label className="newsFormLabels">Description</label>
                  <Form.Item name={"description"}>
                    <ReactQuill
                      style={{ height: "150px" }}
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                    />
                  </Form.Item>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <label className="newsFormLabels">Frame</label>
                      <FrameImage
                        image={frameImg}
                        onChagne={(value: any) => setFrameImg(value)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <Row>
                    <Col sm={6}>
                      <Button
                        size="large"
                        block
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "save"}
                        onClick={() => Finished("save")}
                      >
                        {id !== "create" ? "Update" : "Save"}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        disabled={data?.publishedAt ? true : false}
                        size="large"
                        block
                        type="primary"
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "publish"}
                        onClick={() => Finished("publish")}
                      >
                        Publish
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <br />
            <br />
          </div>
        )}
      </Container>
    </div>
  );
}

export default NewForm;
