import * as React from "react";
import { useState } from "react";
import AddsModal from "./addScreen";
import { Popconfirm, message } from "antd";
import { IoImagesOutline } from "react-icons/io5";
import AddsS from "../../../assets/image/addsens.png";
import { API } from "../../../config/API";
import { DELETE } from "../../../utils/apirequest";
function Picker(props: any) {
  let apiMode = props.value && props?.value?.id ? "PUT" : "POST";
  const [picker, setPicker] = useState(false);

  const close = () => {
    setPicker(false);
    props.refresh();
  };

  const deleteAd = async (id: any) => {
    let URL = API.ADVERTISEMENT_DELETE + id;
    let res: any = await DELETE(URL);
    if (res.data.id) {
      message.success("Deleted Successfully");
      props.refresh();
    } else {
      message.error("Something went wrong...");
    }
  };

  return (
    <div>
      <div className="addScreen-picker" style={props?.style}>
        {props.value && props?.value?.type ? (
          props?.value?.type === "custom" ? (
            <img
              src={props?.value?.desktop}
              className={"addScreen-homeImage1"}
            />
          ) : (
            <div style={{ margin: 10 }}>
              <img src={AddsS} style={{ height: 70 }} />
              <div style={{ fontSize: 12 }}>{props?.value?.iframe}</div>
            </div>
          )
        ) : (
          <div className="addScreen-pickerBox" onClick={() => setPicker(true)}>
            <IoImagesOutline size={30} />
            <div className="addScreen-pickertxt1">
              Click <span style={{ color: "#0055a6" }}>Here</span> Select Images
              or Google Addsense
            </div>
            <div className="addScreen-pickertxt2">
              DeskTop = {props?.desktopSize} | Mobile = {props?.mobileSize}
            </div>
          </div>
        )}
      </div>
      {picker ? (
        <AddsModal
          mode={apiMode}
          desktopSize={props?.desktopSize}
          mobileSize={props?.mobileSize}
          visible={picker}
          value={props}
          close={() => close()}
        />
      ) : null}
      {props.value && props?.value?.type ? (
        <div className="addScreen-pickerIcon">
          <div className="addScreen-pickerIcon2">
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteAd(props?.value?.id)}
            >
              <div style={{ color: "red" }}>Remove</div>
            </Popconfirm>
            <div className="addScreen-lineHor" />
            <div
              color="orange"
              onClick={() => setPicker(true)}
              style={{ color: "orange" }}
            >
              Update
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Picker;
