import * as React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Picker from "../components/picker";
import { POST } from "../../../utils/apirequest";
import { API } from "../../../config/API";
import { IoNewspaperOutline } from "react-icons/io5";

function Home() {
  const [section1, setSection1] = useState({}) as any;
  const [section2, setSection2] = useState({}) as any;
  const [section3, setSection3] = useState({}) as any;
  const [section4, setSection4] = useState({}) as any;
  const [section5, setSection5] = useState({}) as any;
  const [section6, setSection6] = useState({}) as any;
  const [section7, setSection7] = useState({}) as any;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "home",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find(
          (item: any) => item.section === "section1" 
        );
        let sec2 = res.data.find(
          (item: any) => item.section === "section2"
        );
        let sec3 = res.data.find(
          (item: any) => item.section === "section3"
        );
        let sec4 = res.data.find(
          (item: any) => item.section === "section4"
        );
        let sec5 = res.data.find(
          (item: any) => item.section === "section5" 
        );
        let sec6 = res.data.find(
          (item: any) => item.section === "section6" 
        );
        let sec7 = res.data.find(
          (item: any) => item.section === "section7"
        );
        setSection1(sec1);
        setSection2(sec2);
        setSection3(sec3);
        setSection4(sec4);
        setSection5(sec5);
        setSection6(sec6);
        setSection7(sec7);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Container fluid>
      <div>
        <Picker
          value={section1}
          screen={"home"}
          field={"section1"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          desktopSize={""}
          mobileSize={""}
          style={{ height: 120 }}
          refresh={() => loadData()}
        />
      </div>
      <div className="addScreen-header">Header</div>
      <div className="addScreen-header">Quizz Banner</div>
      <div>
        <Picker
          value={section2}
          screen={"home"}
          field={"section2"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          desktopSize={"1024x500 , 2:1"}
          mobileSize={"1024x500 , 2:1"}
          style={{ height: 150 }}
          refresh={() => loadData()}
        />
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;Priority 2
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;Priority 1
          </div>
        </Col>
        <Col sm={4}>
          <Picker
            value={section3}
            screen={"home"}
            field={"section3"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            desktopSize={"1500x1000 , 1.5:1"}
            mobileSize={"1500x1000 , 1.5:1"}
            style={{ aspectRatio: 1.6 / 1 }}
            refresh={() => loadData()}
          />
          <br />
          <div className="addScreen-box" style={{ height: 200 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;Poll
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;JUST IN
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;LATEST NEWS
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;TRENDING NEWS
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 150 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;EDITORIAL
          </div>
        </Col>
        <Col sm={8}>
          <div className="addScreen-box" style={{ height: 150 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;TODAY'S ARTICLE
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;KERALA
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;NATIONAL
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;INTERNATIONAL
          </div>
        </Col>
      </Row>
      <br />
      <div>
        <Picker
          value={section4}
          screen={"home"}
          field={"section4"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          style={{ height: 200 }}
          refresh={() => loadData()}
        />
      </div>
      <br />
      <div className="addScreen-box" style={{ height: 200 }}>
      <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;SHORTS
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;SPORTS
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;GULF
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;CURRENCY BUSINESS
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;EDUCATION
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;CAREER
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 170 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;PRAYER TIME{" "}
          </div>
          <br />
          <Picker
            value={section5}
            screen={"home"}
            field={"section5"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            desktopSize={"2000x1000 , 2:1"}
            mobileSize={"2000x1000 , 2:1"}
            style={{ height: 300 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <div className="addScreen-box" style={{ height: 200 }}>
      <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;LIFE STYLE
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;ORGANIZATION
          </div>
        </Col>
        <Col sm={8}>
          <Row>
            <Col sm={6}>
              <div className="addScreen-box" style={{ height: 300 }}>
              <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;TECH
              </div>
            </Col>
            <Col sm={6}>
              <div className="addScreen-box" style={{ height: 300 }}>
              <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;AUTO MOBILE{" "}
              </div>
            </Col>
          </Row>
          <br />
          <Picker
            value={section6}
            screen={"home"}
            field={"section6"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            desktopSize={"700x100 , 7:1"}
            mobileSize={"700x100 , 7:1"}
            style={{ height: 180 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <div className="addScreen-box" style={{ height: 200 }}>
      <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;WEB STORIES
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;വിദ്യ പ്രഭാതം
          </div>
          <br />
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;TRAVEL
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;TOURISM
          </div>
          <br />
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp; ഞായർ പ്രഭാതം
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;CRIME
          </div>
          <br />
          <Picker
            value={section7}
            screen={"home"}
            field={"section7"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            desktopSize={"1600x1000 , 1.6:1"}
            mobileSize={"1600x1000 , 1.6:1"}
            style={{ height: 250 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <div className="addScreen-box" style={{ height: 250 }}>
      <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp; REELS
      </div>
      <br />
      <Row>
        <Col sm={6}>
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;IN SNAP
          </div>
        </Col>
        <Col sm={6}>
          <div className="addScreen-box" style={{ height: 250 }}>
          <IoNewspaperOutline size={30} color="#0055a6"/> &nbsp;IN SNAP
          </div>
        </Col>
      </Row>
      <br /> <br />
    </Container>
  );
}

export default Home;
