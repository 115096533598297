import {
  Card,
  Modal,
  Table,
} from "antd";
import "./styles.css";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { GetHistoryLog } from "../../screens/newsScreen/queries/getSearchNews";

export default function NewsLog(props: any) {
  const Log = () => {
    const [data, setData] = useState<any>();

    useEffect(() => {
      LoaderData(props.id);
    }, []);

    const LoaderData = async (id: any) => {
      try {
        let response = await GetHistoryLog(id);
        let item = response?.data?.data?.logs?.data;
        setData(item);
      } catch (error) {
        console.log(error);
      }
    };

    interface DataType {
      key: string;
      id: number;
      news: string;
      newshead: string;
      address: string;
      imageurl: string[];
    }
    const columns: ColumnsType<DataType> = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 50,
        render: (text, record, index) => {
          return <div className="">{index + 1}</div>;
        },
      },

      {
        title: "Meta",
        dataIndex: "Meta",
        key: "Meta",
        width: 500,
        render: (text, record: any) => {
          return (
            <div style={{ fontSize: "12px" }}>
              {record?.attributes?.payload?.head}
            </div>
          );
        },
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        width: 200,
        render: (text, record: any) => {
          return (
            <div style={{ fontSize: "12px" }}>
              {record?.attributes?.user?.data?.attributes?.username}
            </div>
          );
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 200,
        render: (text, record: any) => {
          return (
            <div style={{ fontSize: "10px" }}>
              {moment(record?.attributes?.payload?.createdAt).format(
                "DD-MM-YYYY-h:mm a"
              )}
            </div>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "action",
        render: (text, record: any, index: any) => {
          return (
            <div style={{ color: "#0055a6" }}>
              {record?.attributes?.actionType
                ? record?.attributes?.actionType
                : index === 0
                ? "Created"
                : "Updated"}
            </div>
          );
        },
      },
    ];

    return (
      <Table
      columns={columns}
      dataSource={data}
      className="commen-table-custom-scrollbar"
      pagination={false}
      size="small"
    />
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      style={{ top: 20 }}
      width={800}
      footer={false}
      centered
      title={"Edit Log"}
    ><div style={{minHeight:"50vh"}}>

<Log />
    </div>
    </Modal>
  );
}
