import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import "../styles.css";

function NewsItem3(props: any) {
  return (
    <div className="NewsItem3">
      <Row>
        <Col sm={5} xs={5}>
          <img
            src={props?.item?.attributes?.image}
            className="NewsItem3Img"
            alt="No Image"
          />
        </Col>
        <Col sm={7} xs={7}>
          <div className="NewsItem3_itemBox1">
            <div className="NewsItem3_itemhead">
              {props?.item?.attributes?.head}
            </div>
            <div className="NewsItem3_itemBox2">
              <div className="NewsItem3_itemtxt1">
                {props?.item?.attributes?.Subcategories?.data[0] &&
                  props?.item?.attributes?.Subcategories?.data[0]?.attributes
                    ?.name}{" "}
                &#x2022; {moment(props?.item?.attributes?.createdAt).fromNow()}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default NewsItem3;
