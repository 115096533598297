import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../../component/pageHeaders";
import { Button, DatePicker, Form, Select, Tag, message } from "antd";
import ThumbnailPicker from "./thumbnailPicker";
import PagesList from "./pagesList";
import axios from "axios";
import dayjs from "dayjs";
import { API } from "../../../config/API";
import { useParams } from "react-router-dom";
import Loadingbox from "../../../component/loadingbox";
import editons from "./editions.json";
import { convertApi } from "../helpers/compressUpload";
import moment from "moment";
import { useSelector } from "react-redux";

function EpaperForm(props: any) {
  let { id }: any = useParams();
  const User = useSelector((state: any) => state.User.user);
  const [isloading, setIsloading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null) as any;
  const [data, setData] = useState([]) as any;
  const [currentUploadIndex, setCurrentUploadIndex] = useState<any>(null);
  const [form] = Form.useForm();

  const [edition, setEdition] = useState(null);
  const [date, setDate] = useState();
  const [thumbnail, setThumbnail] = useState(null);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      if (id !== "create") {
        let url = API.BASE_URL + API.EPAPER_DETAILS + id;
        const results = await axios.get(url);
        if (results?.data?.data) {
          let data = results?.data?.data?.attributes;
          setData(data);
          setEdition(data?.edition);
          setDate(data?.date);
          setThumbnail(data?.image);
          setPages(data?.epapercollections);
        }
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    } catch (err) {
      setIsloading(false);
    }
  };

  const Finished = async (type: any) => {
    try {
      if (pages?.length) {
        let values = form.getFieldsValue();
        setLoading(true);
        setType(type);
        let currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 6);
        var uploadPage = await uploadPages(pages, currentDate);
        
        var requestObj = {
          data: {
            edition: values?.edition,
            image: thumbnail,
            epapercollections: uploadPage,
            date: moment(date).endOf("date")?.toISOString(),
            user: id !== "create" ? data?.user?.id : User?.id,
            publishedAt:
              type === "publish" ? new Date() : data?.publishedAt || null,
          },
        };

        let url = id !== "create" ? API.EPAPER_EDIT + id: API.EPAPER_ADD;
        let method = id !== "create" ? "PUT" : "POST";
        axios({
          method: method,
          url: API.BASE_URL + url,
          data: requestObj,
        })
          .then((response) => {
            setLoading(false);
            if (response?.data?.data?.id) {
              message.success(
                `Epaper ${id ? "Updated" : "Created"} Successfully.`
              );
              setTimeout(() => {
                window.location.replace(
                  `/Auth/EpaperForm/${response?.data?.data?.id}`
                );
              }, 200);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        message.error("Sorry Pages Not Found");
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const uploadPages = async (pages: any, date: any) => {
    try {
      var arro: any = [];
      for (let i = 0; i < pages?.length; i++) {
        if (pages[i].uploaded === false) {
          setCurrentUploadIndex(i);
          let file = pages[i]?.file;
          const up: any = await convertApi(file, "page", i, 4, edition, date);
          let obj = {
            index: pages[i].index,
            imageUrl: up?.image_url,
            image: up?.image_name,
            name: pages[i]?.name,
            uploaded: true,
            server: "scaleway",
          };
          arro.push(obj);
          setCurrentUploadIndex(null);
        } else {
          arro.push(pages[i]);
        }
      }
      return arro;
    } catch (err) {
      console.log("err = = = >", err);
      return [];
    }
  };

  return (
    <div style={{ height: "95vh", overflowY: "scroll" }}>
      <PageHeader title={"E-Paper"} length={"Add New Epaper"}>
        {id !== "create" ? (
          <>
            <Tag style={{ fontSize: 14 }}>
              {moment(data.publishedAt).format("l")}
            </Tag>
            <Tag style={{ fontSize: 14 }}>{data.edition}</Tag>
            <Tag color={data.publishedAt ? "green" : "blue"}>
              {data.publishedAt ? "Published" : "Draft"}
            </Tag>
          </>
        ) : (
          <Tag style={{ fontSize: 14 }}>{moment().format("l")}</Tag>
        )}
      </PageHeader>
      {isloading ? (
        <Loadingbox />
      ) : (
        <Container>
          <br />
          <Row>
            <Col sm={12}>
              <Form
                form={form}
                onFinish={Finished}
                initialValues={{
                  edition: edition,
                  date: date ? dayjs(date) : null,
                }}
              >
                <Row>
                  <Col sm={4}>
                    <label className="newsFormLabels">Edition</label>
                    <Form.Item name="edition" rules={[{ required: true }]}>
                      <Select
                        size="large"
                        placeholder="Select Edition"
                        onChange={(value) => setEdition(value)}
                      >
                        {editons.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <label className="newsFormLabels">Publish Date</label>
                    <Form.Item name="date" rules={[{ required: true }]}>
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        defaultValue={dayjs(date)}
                        size="large"
                        style={{ width: "100%" }}
                        onChange={(value: any) => setDate(value?.$d)}
                      />
                    </Form.Item>
                    <label className="newsFormLabels">Thumbnail</label>
                    <Form.Item>
                      <ThumbnailPicker
                        fileURL={thumbnail}
                        edition={edition}
                        date={date}
                        onChagnge={(value: any) => setThumbnail(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={8}>
                    <PagesList
                      pages={pages}
                      edition={edition}
                      date={date}
                      uploadIndx={currentUploadIndex}
                      onChagnge={(value: any) => setPages(value)}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6}>
                    <Row>
                      <Col sm={6}>
                        <Button
                          block
                          style={{ height: 50 }}
                          onClick={() => Finished("save")}
                          loading={loading && type === "save"}
                        >
                          {id !== "create" ? " Update" : "Save"}
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          disabled={data.publishedAt ? true : false}
                          block
                          type="primary"
                          style={{ height: 50 }}
                          loading={loading && type === "publish"}
                          onClick={() => Finished("publish")}
                        >
                          Publish
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <br /> <br /> <br />
            </Col>
            <Col></Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default EpaperForm;
