import { useSelector } from "react-redux";
import Clock from "react-live-clock";
import ProfilePopup from "../component/profilePopup";
import { useNavigate } from "react-router-dom";
import { LuPointer } from "react-icons/lu";
import { FaRegClock } from "react-icons/fa";
import { Popover } from "antd";
import { IoMdAdd } from "react-icons/io";
import { MdHelpCenter } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
const Header = () => {
  const navigate = useNavigate();
  const User = useSelector((state: any) => state.User.user);
  return (
    <div className="Admin-Header">
      <div className="Admin-Headertxt1 " onClick={() => navigate("/Auth")}>
        Suprabhaatham Admin{" "}
        <span style={{ fontSize: 12, color: "grey" }}>V1</span>
      </div>
      <div style={{ flex: 1 }} />
      <Popover
        content={
          <div>
            <div
              className="Admin-HeaderItem"
              onClick={() => navigate("/Auth/home/news")}
            >
              <IoMdAdd size={20} color="#0055a6" />
              &nbsp; Create News
            </div>
            <div
              className="Admin-HeaderItem"
              onClick={() => navigate("/Auth/Article/createForm")}
            >
              <IoMdAdd size={20} color="#0055a6" />
              &nbsp; Create Articles
            </div>
            <div
              className="Admin-HeaderItem"
              onClick={() => navigate("/Auth/editorial/createform")}
            >
              <IoMdAdd size={20} color="#0055a6" />
              &nbsp; Create Editorial
            </div>
            <div
              className="Admin-HeaderItem"
              onClick={() => navigate("/Auth/EpaperForm/create")}
            >
              <IoMdAdd size={20} color="#0055a6" />
              &nbsp; Add E-Paper
            </div>
          </div>
        }
      >
        <div className="Admin-QuickBtn">
          <LuPointer /> &nbsp; Quick
        </div>
      </Popover>

      <div className="Admin-HeaderTime">
        <FaRegClock /> &nbsp;
        <Clock format={"h:mm:ss A"} ticking={true} />
      </div>
      <Popover
        content={
          <a href="mailto:contact@bairuhatech.com">
          <div className="Admin-HeaderItem" >
            <MdMailOutline size={20} color="#0055a6" />
            &nbsp; Connect Support
          </div>
          </a>
        }
      >
        <div style={{ marginRight: 20 }}>
          <MdHelpCenter size={25} color="grey" />
        </div>
      </Popover>

      <div className="Admin-HeaderBox">
        <div>
          <div className="Admin-HeaderTxt1">{User.username}</div>
          <div style={{ fontSize: 10, color: "grey" }}>{User.email}</div>
        </div>
        &nbsp;&nbsp;
        <div>
          <ProfilePopup />
        </div>
      </div>
    </div>
  );
};

export default Header;
