import { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Col, Row, Button, message, Input, Select } from "antd";
import uploadImg from "../../../assets/image/Group 225.svg";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { uploadImageToS3WebStories } from "../../../config/s3config/s3fileUpload";
import Loadingbox from "../../../component/loadingbox";
import { LuImagePlus } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { API } from "../../../config/API";
import axios from "axios";
import ImagePicker2 from "../../../component/image-pickersecond";
import { GET } from "../../../utils/apirequest";
import { useSelector } from "react-redux";

const WebStoriesForm = () => {
  const location = useLocation();
  const User = useSelector((state: any) => state.User.user);
  const props = location?.state?.item;  

  const [tags, setTags] = useState<any>([]);
  const [imageUrl, setImageurl] = useState(props?.image || "");
  const [imageUrls, setImageUrls] = useState<any[]>(props?.imageList || []);
  const [textImage, setTextImage] = useState<any[]>(props?.imageList || []);

  const [isLoading, setIsloading] = useState(false);
  const [isLoading2, setIsloading2] = useState(false);
  const [imageUploader, setImageUploader] = useState(false);
  const [uploadeMore, setUploadMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    LoadTags();
  }, []);

  const LoadTags = async () => {
    let url = API.TAGS_LIST_ALL;
    let res: any = await GET(url, null, null);
    setTags(res);
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const uploadImage = async (val: any) => {
    setIsloading2(true);
    const fileName = `${moment().format(
      "YYYY-MM-DD HH:mm:ss"
    )}suprabhatham-webstories.png`;
    const file = val;
    const response = await uploadImageToS3WebStories(file, fileName);
    setImageurl(response.Location);
    setIsloading2(false);
  };

  const uploadImage2 = async (val: any) => {
    setIsloading(true);
    if (val?.val?.file && val?.val?.file) {
      const fileName = `${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}suprabhatham-webstories.png`;
      const file = val?.val?.file;
      const response = await uploadImageToS3WebStories(file, fileName);
      const imageUrlObject = { imageUrl: response.Location };
      const TextImg = { imageUrl: response.Location, caption: "" };
      setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrlObject]);
      setTextImage((prevImageUrls) => [...prevImageUrls, TextImg]);
    } else if (val?.val) {
      const imageUrlObject = { imageUrl: val?.val };
      const TextImg = { imageUrl: val?.val, caption: "" };
      setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrlObject]);
      setTextImage((prevImageUrls) => [...prevImageUrls, TextImg]);
    }
    setIsloading(false);
  };

  const deleteImage = (indexToDelete: number) => {
    setImageUrls((prevImageUrls) => {
      const updatedImageUrls = [...prevImageUrls];
      updatedImageUrls.splice(indexToDelete, 1);
      return updatedImageUrls;
    });
    setTextImage((prevTextImage) => {
      const updatedTextImage = [...prevTextImage];
      updatedTextImage.splice(indexToDelete, 1);
      return updatedTextImage;
    });
  };

  const onFinish = async (values: any) => {
    const textimg = textImage.map((item: any) => ({
      imagetext: item,
    }));

    let requestObj = {
      data: {
        description: values.description || "",
        image: imageUrl,
        imageList: textImage,
        textField: textimg,
        tags: values?.tags,
        permalink: values?.permalink,
        user: props?.id ? props?.user?.id: User?.id,
        publishedAt: props?.id ? props?.publishedAt : new Date(),
      },
    };
    let url = props?.id
      ? API.BASE_URL + API.WEB_STORIES + `/${props?.id}`
      : API.BASE_URL + API.WEB_STORIES;
    let method = props?.id ? "put" : "post";

    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        message.success("Sucessfull");
        navigate("/Auth/webstories");
      })
      .catch((error) => {
        console.log(error);
        message.error("Please try again");
      });
  };

  const addImageToList = async (val: any) => {
    if (val?.type === "medialibrary") {
      setImageurl(val?.val);
    } else {
      await uploadImage(val?.val?.file);
    }
  };
  const addImagesToList = async (val: any) => {
    await uploadImage2(val);
  };

  return (
    <>
      <div>
        <PageHeader
          title={props?.id ? "Update Web Stories" : "Create Web Stories"}
        />
        <br />
        <div style={{ height: "70vh", overflowY: "scroll" }}>
          <Form
            onFinish={onFinish}
            initialValues={{
              description: props?.description,
              tags:
                props?.tags &&
                props?.tags.data &&
                props?.tags?.data.map((item: any) => item?.id),
              permalink: props?.permalink,
            }}
          >
            <Col md={24} xs={24}>
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  <Col md={24} xs={24}>
                    <div className="formItem">
                      <div className="formLabel">title</div>
                      <Form.Item name={"description"} {...formItemLayout}>
                        <TextArea rows={4} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={24}>
                    {isLoading2 ? (
                      <Loadingbox />
                    ) : (
                      <div className="formItem">
                        <div className="formLabel">Image</div>
                        <div
                          className="webStoriesForm-imgUpldr"
                          onClick={() => setImageUploader(true)}
                        >
                          <img src={uploadImg} alt="" />
                          <div className="webstories-imgTxt">
                            Upload cover Image Here
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                  {imageUrl ? (
                    <Col md={24}>
                      <div className="PreviewCoverImage">
                        <img src={imageUrl} />
                      </div>
                    </Col>
                  ) : null}
                </Col>
                <Col md={{ span: 10, offset: 1 }}>
                  <div className="formLabel">Tags</div>
                  <Form.Item name="tags">
                    <Select
                       mode="multiple"
                      style={{ width: "100%" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {tags.length > 0 &&
                        tags.map((item: any, index: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.tag}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <div className="formLabel">Perma Link</div>
                  <Form.Item name="permalink" {...formItemLayout}>
                    <Input placeholder="" />
                  </Form.Item>
                  <div className="formLabel">Add More Image</div>
                  <div
                    className="webStorisFrm-moreImg"
                    onClick={() => setUploadMore(true)}
                  >
                    <div className="webstories-imgTxt">Upload More</div>
                    <LuImagePlus size={32} />
                  </div>
                  <div className="webStories-addedImgMain">
                    {imageUrls.map((imageUrl2, index) => {
                      return (
                        <>
                          <div
                            className="webStorisFrm-AddedImg"
                            style={{ marginTop: 20, marginBottom: 50 }}
                            key={index}
                          >
                            <img
                              className="webStories-img"
                              src={imageUrl2.imageUrl}
                              alt=""
                            />
                            <div className="webStories-delete">
                              <RiDeleteBin6Line
                                onClick={() => deleteImage(index)}
                                color="white"
                                size={18}
                                cursor={"pointer"}
                              />
                            </div>
                            <Form.Item
                              name={`textField-${index}`}
                              initialValue={imageUrl2.caption}
                            >
                              <TextArea
                                rows={2}
                                onChange={(e) => {
                                  const updatedDescriptions = [...textImage];
                                  updatedDescriptions[index] = {
                                    ...updatedDescriptions[index],
                                    imageUrl: imageUrl2.imageUrl,
                                    caption: e?.target?.value
                                      ? e?.target?.value
                                      : "",
                                  };

                                  setTextImage(updatedDescriptions);
                                }}
                              />
                            </Form.Item>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4, offset: 18 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="webStories-publish"
                  >
                    {props?.id ? "Update" : "Publish"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form>
        </div>
      </div>
      {imageUploader ? (
        <ImagePicker2
          onSubmit={(val: any) => addImageToList(val)}
          visible={imageUploader}
          close={() => setImageUploader(false)}
        />
      ) : null}
      {uploadeMore ? (
        <ImagePicker2
          onSubmit={(val: any) => addImagesToList(val)}
          visible={uploadeMore}
          close={() => setUploadMore(false)}
        />
      ) : null}
    </>
  );
};

export default WebStoriesForm;
