import  { useState,useEffect } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, message,DatePicker } from "antd";
import {Row, Col } from "react-bootstrap";
import { API } from "../../../config/API";
import { GET, POST,PUT } from "../../../utils/apirequest";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../component/pageHeaders";
import dayjs from "dayjs";
import { GetPolls } from "../queries/getPolls";

function NewForm(props: any) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    if (id !== "create") {
      LoadData(id);
    }
    LoadTags()
  },[])

  const LoadData = async (id: any) => {
    let result = await GetPolls(id, 0, 10);
    let initialValues={
      questions: result?.data?.data &&
      result?.data?.data[0]?.attributes?.questions && result?.data?.data[0]?.attributes.questions,
      permalink: result?.data?.data && 
      result?.data?.data[0]?.attributes?.permalink && result?.data?.data[0]?.attributes?.permalink,
        startDate:
        result?.data?.data &&  result?.data?.data[0]?.attributes?.startDate && dayjs(result?.data?.data[0]?.attributes?.startDate),
        endDate:
        result?.data?.data &&  result?.data?.data[0]?.attributes?.endDate && dayjs(result?.data?.data[0]?.attributes?.endDate),
      poll_options: 
      result?.data?.data &&
      result?.data?.data[0]?.attributes?.poll_options && result?.data?.data[0]?.attributes.poll_options &&  result?.data?.data[0]?.attributes?.poll_options?.data && result?.data?.data[0]?.attributes?.poll_options?.data.map(((item: any) =>  item?.attributes?.options)),
      tags:
      result?.data?.data &&
      result?.data?.data[0]?.attributes?.tags && result?.data?.data[0]?.attributes.tags &&  result?.data?.data[0]?.attributes?.tags?.data && result?.data?.data[0]?.attributes?.tags?.data.map(((item: any) => Number(item?.id))) 
    }
    form.setFieldsValue(initialValues);
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    let requestObj = {
      questions: {
        data: {
          questions: values.questions,
          tags:values.tags,
          permalink:values.permalink,
          publishedAt: moment().format(),
          startDate:values.startDate,
          endDate:values.endDate,
        },
      },
      options: values.poll_options.map((item: any) => ({
        options: item,
        publishedAt: moment().format(),
      })),
    }

    try {
      let url = id  ==="create"? API.CREATE_POLL : API.EDIT_POLL + id
      const METHOD =  id  ==="create" ? POST : PUT
      const response: any = await METHOD(url, requestObj);
      if (response.status) {
        setIsLoading(false);
        let type = id  ==="create" ? "Created" : "Updated"
        message.success(`SucessFully ${type}`);
        setTimeout(() => {
          navigate(`/Auth/polls`)
        },500)
    
      }else{
        setIsLoading(false);
        message.error("Something went wrong......!");
      }
    } catch (error) {
      message.error("Something went wrong......!");
    }
  };
  
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };

  const LoadTags = async() => {
    let url =  API.TAGS_LIST_ALL;
    let res:any = await GET(url,null,null)
    setTags(res)
  }

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader
        title={"Poll Form"}
        onBtnPress={() => navigate("/")}
        goBack={() => props.onClose()}
      />
      <Form
       form={form}
        onFinish={onFinish}
      >
        <Col md={{ span: 6, offset: 3 }}>
          <Col md={12} style={{ marginTop: 30 }}>
            <label className="newsFormLabels">Heading</label>
            <Form.Item name="questions" {...formItemLayout}>
              <Input placeholder="Type..." />
            </Form.Item>
          </Col>
          <Col md={10}>
            <label className="newsFormLabels">Tags</label>
            <Form.Item name="tags">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                filterOption={(input: any, option: any) =>
                  option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {tags.length > 0 &&
                  tags.map((item: any, index: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.tag}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <label className="newsFormLabels">Perma Link</label>
            <Form.Item name="permalink" {...formItemLayout}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Row>
            <Col xs="5">
              <label className="newsFormLabels">Start Date</label>
              <Form.Item name="startDate" >
                <DatePicker style={{width:"100%"}}/>
              </Form.Item>
            </Col>
            <Col xs="5">
              <label className="newsFormLabels">End Date</label>
              <Form.Item name="endDate">
                <DatePicker style={{width:"100%"}} />
              </Form.Item>
            </Col>
          </Row>
          <Col md={10}>
            <label className="newsFormLabels">Option</label>
            <Form.List
              {...formItemLayout}
              name="poll_options"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 2) {
                      return Promise.reject(new Error("At least 2 options"));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div className="pollScreen-box1">
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input options or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="options" />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add options
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Row>
            <Col xs="6"></Col>
            <Col xs="2" sm="2">
              <Button size="large" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Col>
            <Col xs="2" sm="2">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </div>
  );
}

export default NewForm;
