import { Form, Input, ColorPicker, Button } from "antd";
import { Table } from "react-bootstrap";
import { CiCircleRemove } from "react-icons/ci";

function PartyItems() {
  return (
    <Form.List
      name={["parties", "options"]}
      initialValue={[{ party: "", color: "#fff" }]}
    >
      {(fields, { add, remove }) => (
        <>
          <Table bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Color</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ key, name, ...restField }, index) => (
                <tr key={key}>
                  <td style={{ width: "100%" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "party"]}
                      fieldKey={[key, "party"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing party name",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Name" bordered={false} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "color"]}
                      fieldKey={[key, "color"]}
                      getValueFromEvent={(color) => "#" + color.toHex()}
                      rules={[{ required: true, message: "Missing color" }]}
                    >
                      <ColorPicker format={"hex"} />
                    </Form.Item>
                  </td>
                  <td>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => remove(name)}
                      style={{ cursor: "pointer" }}
                    >
                      <CiCircleRemove size={20} color="red" />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "100%" }}
                  >
                    Add Party +
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Form.List>
  );
}

export default PartyItems;
