import { Button, Form, message, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Loadingbox from "../../../component/loadingbox";
import { API } from "../../../config/API";
import TextArea from "antd/es/input/TextArea";
import PageHeader from "../../../component/pageHeaders/index";
// import ImagePicker from "../../../component/image-picker";
import ImagePicker from "../../../component/imagePicker";
import { RxClipboardCopy } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { addTags, getNewsById } from "../../newsScreen/utils/apiRequest";
import moment from "moment";
import { uploadImageToS3 } from "../../../config/s3config/s3fileUpload";
import ImageItem from "./imageItem";
import { LoadTags } from "../../../utils/commonapis";
function NewForm(props: any) {
  const navigate = useNavigate();
  let { id } = useParams();
  const [form] = Form.useForm();

  const User = useSelector((state: any) => state.User.user);
  const Tags = useSelector((state: any) => state.Tags?.data);

  const [data, setData] = useState<any>();
  const [content, setContent] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(false);
  const [returnedNewsId, setReturnedNewsId] = useState() as any;
  const [imageUploader, setImageUploader] = useState(false);
  const [datamodal, setDatamodal] = useState<any>({});
  const [audiouploader, setAudiouploader] = useState<any>();
  const [imageType, setImageType] = useState("");
  const [authorImg, setAuthorImg] = useState();
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = async () => {
    try {
      if (!Tags.length) {
        const res: any = await LoadTags();
        setTags(res);
      } else {
        setTags(Tags);
      }
    } catch (error) {
    } finally {
      setLoading3(false);
    }
  };

  const [imageuploaddata, setImageuploaddata] = useState<any>({
    imageurl1: "",
    imageurl2: "",
    imageurl3: "",
  });

  const [imageName, setImageName] = useState("");

  useEffect(() => {
    if (id !== undefined) {
      LoadDAta(id);
    }
  }, []);

  const LoadDAta = async (id: any) => {
    let url = API.BASE_URL + API.EDITORIALS + `/${id}?populate[0]=tags`;
    try {
      const response: any = await getNewsById(url);
      if (response) {
        setData(response?.data?.data);
        const tags = response?.data?.data?.attributes?.EditorialTag?.split("|");

        setContent(response?.data?.data?.attributes?.body);
        setImageuploaddata({
          imageurl1: response?.data?.data?.attributes?.image1
            ? response?.data?.data?.attributes?.image1
            : "",
          imageurl2: response?.data?.data?.attributes?.image2
            ? response?.data?.data?.attributes?.image2
            : "",
          imageurl3: response?.data?.data?.attributes?.profile
            ? response?.data?.data?.attributes?.profile
            : "",
        });
        setReturnedNewsId(response?.data?.data?.id);
        form.setFieldsValue({
          title: response?.data?.data?.attributes?.title,
          head: response?.data?.data?.attributes?.head,
          author: response?.data?.data?.attributes?.author,
          seriesName: response?.data?.data?.attributes?.seriesName,
          permalink: response?.data?.data?.attributes?.permalink,
          tags:
            tags &&
            tags.length &&
            tags.map((item: any) => {
              return item;
            }),
        });
      } else {
        console.log("=======error=======");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const { imageurl1, imageurl2, imageurl3 } = imageuploaddata;
  let pic1 = imageurl1;
  let pic2 = imageurl2;
  let pic3 = imageurl3;

  const handleEditorChange = (content: any, editor: any) => {
    setContent(content);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const tagsfilteredArray = values?.tags?.join("|");
    let requestObj = {
      data: {
        title: values?.title,
        head: values?.head,
        author: values?.author,
        seriesName: values?.seriesName,
        body: content,
        image1: pic1,
        image2: pic2,
        profile: pic3,
        publishedAt: data?.id ? data?.attributes?.publishedAt : null,
        image1_description: datamodal.image1Value,
        image2_description: datamodal.image2Value,
        image3_description: datamodal.image3Value,
        audio: audiouploader,
        EditorialTag: tagsfilteredArray,
        user: id !== undefined ? data?.user?.id : User?.id,
        permalink: values.permalink,
      },
    };

    let url = data?.id
      ? API.BASE_URL + API.EDITORIALS + `/${data?.id}`
      : API.BASE_URL + API.EDITORIALS;
    let method = data?.id ? "PUT" : "POST";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        setReturnedNewsId(response?.data?.data?.id);
        setLoading(false);
        addTags(values?.tags);
        message.success(
          <>
            Success : <b>Saved</b>
          </>
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onPublish = (id: any) => {
    setLoading3(true);
    let url = API.BASE_URL + API.EDITORIALS + `/${id}`;
    let requestObj = {
      data: {
        publishedAt: new Date(),
      },
    };
    axios({
      method: "PUT",
      url: url,
      data: requestObj,
    })
      .then((response) => {
        message.success("Published");
        setLoading3(false);
        navigate(`/Auth/editorial`);
      })
      .catch((error) => {
        setLoading3(false);
        console.log(error);
      });
  };

  const addImagesToList = (data: any) => {
    try {
      let DataObj = imageuploaddata;
      if (data.type === "image1") {
        DataObj["imageurl1"] = "";
        DataObj["imageurl1"] = data.image_url;
        DataObj["image1Value"] = "";
        DataObj["image1Value"] = data.image_desc;
      } else if (data.type === "image2") {
        DataObj["imageurl2"] = "";
        DataObj["imageurl2"] = data.image_url;
        DataObj["image2Value"] = "";
        DataObj["image2Value"] = data.image_desc;
      } else if (data.type === "image3") {
        DataObj["imageurl3"] = "";
        DataObj["imageurl3"] = data.image_url;
        DataObj["image3Value"] = "";
        DataObj["image3Value"] = data.image_desc;
      } else if (data.type === "authorImg") {
        setAuthorImg(data.image_url);
      }
      setImageuploaddata(DataObj);
    } catch (err) {
      console.log(err);
    }
  };

  const addImage = (image: any) => {
    if (window.tinymce && window.tinymce.activeEditor) {
      const editor = window.tinymce.activeEditor;
      let Moment = `${moment().format(
        "YYYY-MM-DDHH:MM:SS"
      )}.suprabhaatham-news.png`;
      let alt = image?.image_desc ? image?.image_desc : Moment;
      editor.insertContent(
        `<div style="display: inline-block; text-align: center;">
        <img src="${image?.image_url}" alt="${alt}" style="max-width: 100%; display: block; margin: 0 auto;" onload="this.parentElement.style.width = this.width + 'px';" />
        <div style="display: flex; justify-content: space-between; margin-top: 5px;">
          <div style="flex: 1; text-align: left;">${image?.image_desc}</div>
          <div style="flex: 1; text-align: right;">${image?.image_Caption}</div>
        </div>
      </div>`
      );
      setImageUploader(false);
    }
  };

  function deleteImagesFromList(key: any) {
    try {
      setImageuploaddata((prevImages: any) => {
        const updatedImages = { ...prevImages };
        updatedImages[key] = "";
        return updatedImages;
      });
      notification.success({
        message: `Image Removed`,
      });
    } catch (err) {
      notification.error({
        message: `Failed to remove`,
      });
    }
  }

  const handleUrlCopy = (type: any) => {
    const perma = form.getFieldValue("permalink");
    let formattedPerma = perma?.replace(/\s+/g, "-");
    const link = `${API.APP_URL}editorial?id=${
      returnedNewsId ? returnedNewsId : data?.id
    }&link=${formattedPerma ? formattedPerma : ""}`;
    if (type === "copy") {
      navigator.clipboard.writeText(link).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(link, "_blank");
    }
  };

  const checkPermaLink = (e: any) => {
    const regex = /^[a-zA-Z0-9- ]*$/;
    const inputValue = e.target.value;
    const filteredValue = inputValue
      .split("")
      .filter((char: any) => regex.test(char))
      .join("");
    form.setFieldsValue({ permalink: filteredValue });
  };

  return (
    <div style={{ height: "90vh", overflowY: "scroll" }}>
      <PageHeader
        title={`${
          id !== null && id !== undefined ? "Edit" : "Create"
        } Editorial`}
      />
      <Form onFinish={onFinish} form={form} name="add-editorials">
        <Container fluid>
          <Row>
            <Col sm={9} xs={12}>
              <Row>
                <Col sm="6">
                  <label className="newsFormLabels">Title</label>
                  <Form.Item name="title">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm="6">
                  <label className="newsFormLabels">Heading</label>
                  <Form.Item name="head">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm="6">
                  <label className="newsFormLabels">Author</label>
                  <Form.Item name="author">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm="6">
                  <label className="newsFormLabels">Series-Name</label>
                  <Form.Item name="seriesName">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm="12">
                  {loading2 && <Loadingbox />}
                  <Editor
                    // apiKey={API.EDITOR_API_KEY}
                    value={content}
                    init={{
                      height: 500,
                      menubar: true,
                      directionality: "ltr",
                      plugins:
                        "advlist autolink lists link image pageembed code preview charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount pageembed code preview image code",
                      toolbar:
                        "  | bold italic | \
                   alignleft aligncenter alignright | \
                   bullist numlist outdent indent |subscript superscript |link unlink| help | forecolor backcolor | \
                   \
                   \
                   \
                   | image | myCustomButton  ",
                      setup: (editor) => {
                        editor.ui.registry.addButton("myCustomButton", {
                          text: "Media Library",
                          icon: "gallery",
                          onAction: () => {
                            setImageType("Media");
                            setImageUploader(true);
                          },
                        });
                      },

                      file_browser_callback_types: "image",
                      file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "image") {
                          var input = document.getElementById("my-file") as any;
                          input.click();
                          input.onchange = function () {
                            const name = input.files[0].name;
                            const sliced = name.slice(0, name.length - 5);
                            var file = input.files[0];
                            const fileName = `${moment().format(
                              "YYYY-MM-DDHH:MM:SS"
                            )}${sliced}suprabhaatham-news.png`;
                            uploadImageToS3(file, fileName)
                              .then((s3ImageUrl: any) => {
                                callback(s3ImageUrl.Location, {
                                  alt: file.name,
                                });
                              })
                              .catch((error: any) => {
                                console.error(
                                  "Error uploading image to S3:",
                                  error
                                );
                              });
                          };
                        }
                      },
                      paste_data_images: true,
                    }}
                    onEditorChange={handleEditorChange}
                    onInit={() => setLoading2(false)}
                  />
                </Col>
                <Col sm={4} xs={12}>
                  <div className="newsFormLabels">News Image 1</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl1}
                    upload={() => {
                      if (imageuploaddata?.imageurl1 === "") {
                        setImageType("image1");
                        setImageName("Image 1");
                        setImageUploader(true);
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl1")}
                  />
                </Col>
                <Col sm={4} xs={12}>
                  <div className="newsFormLabels">News Image 2</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl2}
                    upload={() => {
                      if (imageuploaddata?.imageurl2 === "") {
                        setImageType("image2");
                        setImageName("Image 2");
                        setImageUploader(true);
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl2")}
                  />
                </Col>
                <Col sm={4} xs={12}>
                  <div className="newsFormLabels">Author Image</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl3}
                    upload={() => {
                      if (imageuploaddata?.imageurl3 === "") {
                        setImageType("image3");
                        setImageName("Social Media Image");
                        setImageUploader(true);
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl3")}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <hr />
                <Col sm="4" />
                <Col sm="4">
                  <Button
                    className="editorailFrm-bttn"
                    loading={loading3}
                    disabled={!returnedNewsId}
                    onClick={() => onPublish(returnedNewsId)}
                  >
                    Publish
                  </Button>
                </Col>
                <Col sm="4">
                  <Button
                    className="editorailFrm-bttn"
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                  >
                    {data?.id ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
              <br />
            </Col>
            <Col sm={3} xs={12}>
              <div className="newsFormBox">
                <label className="newsFormLabels">Tags</label>
                <Form.Item name="tags">
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tags.length > 0 &&
                      tags.map((item: any, index: any) => (
                        <Select.Option key={index} value={item.tag}>
                          {item.tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <div className="PermaBox">
                  <div className="newsFormLabels">Perma-Link</div>
                  {returnedNewsId || data?.id ? (
                    <>
                      <div className="RxClipboardCopy">
                        <RxClipboardCopy
                          size={20}
                          onClick={() => handleUrlCopy("copy")}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <Form.Item
                  name="permalink"
                  rules={[
                    {
                      validator: (_, value) => {
                        const regex = /^[a-zA-Z0-9- ]*$/;
                        return regex.test(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              "Only alphanumeric characters, spaces, and dashes are allowed."
                            );
                      },
                    },
                  ]}
                >
                  <TextArea onChange={checkPermaLink} lang="en" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
      {imageUploader ? (
        <ImagePicker
          mode="create"
          imageType={imageType}
          imageName={imageName}
          onSelect={addImage}
          onSubmit={(val: any) => addImagesToList(val)}
          visible={imageUploader}
          close={() => setImageUploader(false)}
        />
      ) : null}
    </div>
  );
}

export default NewForm;
