import "../styles.css";
import { useState, useEffect } from "react";
import { Button, Form, Input, Switch, message } from "antd";
import { useParams } from "react-router-dom";
import PageHeader from "../../../component/pageHeaders";
import Loadingbox from "../../../component/loadingbox";
import { Container, Row, Col, Table } from "react-bootstrap";
import QuestionOption from "../components/questionOption";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API } from "../../../config/API";
import { GET, POST } from "../../../utils/apirequest";

function QuizCampForm(props: any) {
  const [form] = Form.useForm();
  let { id, qid } = useParams();
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isLoading1, setIsLoading1] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [type, setType] = useState(null) as any;
  const [hint, setHint] = useState("");

  useEffect(() => {
    if (qid !== "create") {
      getData();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getData = async () => {
    try {
      if (qid !== "create") {
        let url = API.QUIZZ_ITEM_DETAILS + qid + `?populate=*`;
        let res: any = await GET(url, null, null);
        if (res?.data?.id) {
          let data = res?.data?.attributes;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const Finished = async (type: any) => {
    try {
      setIsLoading1(true);
      setType(type);
      form.submit();
      await form.validateFields();
      let values = form.getFieldsValue();
      let requestObj = {
        ...(qid !== "create" && { id: Number(qid) }),
        quiz_master_id: id,
        publishedAt:
          type === "publish" ? new Date() : data?.publishedAt || null,
        ...values,
      };
      let URL = qid !== "create" ? API.QUIZZ_ITEM_EDIT : API.QUIZZ_ITEM_ADD;
      let res: any = await POST(URL, requestObj);
      if (res.status) {
        setIsLoading1(false);
        message.success(
          `Quizz Items ${
            qid !== "create" ? "Updated" : "Created"
          } Successfully.`
        );
        setTimeout(() => {
          window.location.replace(
            `/Auth/QuestionForm/${id}/${res.quizItems.id}`
          );
        }, 200);
      } else {
        setIsLoading1(false);
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log(err);
      setIsLoading1(false);
      message.error("Something went wrong...!");
    }
  };

  return (
    <div style={{ height: "95vh", overflow: "scroll" }}>
      <PageHeader title={"quiz Questions"} length={"Add New Questions"} />
      <br />
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div>
            <Form
              form={form}
              onFinish={Finished}
              initialValues={{
                title: data?.title,
                hint: data?.hint,
                winner_selected: data?.winner_selected ? true : false,
                winner_name: data?.winner_name,
                winner_phone: data?.winner_phone,
                winner_address: data?.winner_address,
                questions:
                  data?.quiz_questions &&
                  data?.quiz_questions?.data &&
                  data?.quiz_questions?.data?.map((item: any) => {
                    return {
                      question: item?.attributes?.question,
                      options: item?.attributes?.options,
                      answer: item?.attributes?.answer,
                    };
                  }),
              }}
            >
              <Row>
                <Col sm={5}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item name={"title"}>
                    <Input size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Hint</label>
                  <Form.Item name={"hint"}>
                    <ReactQuill
                      style={{ height: "150px" }}
                      theme="snow"
                      value={hint}
                      onChange={setHint}
                    />
                  </Form.Item>
                  <br />
                  <h5>
                    <strong>Winner Information</strong>
                  </h5>
                  <hr />
                  <Form.Item
                    name={"winner_selected"}
                    valuePropName={data.winner_selected ? "checked" : "null"}
                  >
                    <Switch />
                  </Form.Item>
                  <label className="newsFormLabels">Full Name</label>
                  <Form.Item name={"winner_name"}>
                    <Input size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Phone Number</label>
                  <Form.Item name={"winner_phone"}>
                    <Input size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Address</label>
                  <Form.Item name={"winner_address"}>
                    <Input.TextArea size="large" />
                  </Form.Item>
                </Col>
                <Col sm={7}>
                  <label className="newsFormLabels">Questions</label>
                  <hr />
                  <Form.List name="questions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Table className="quiz-formtable">
                            <tr>
                              <td className="quiz-td1">{index + 1}.</td>
                              <td className="quiz-td2">
                                <Form.Item noStyle name={[name, "question"]}>
                                  <Input.TextArea
                                    placeholder="Enter Question"
                                    size="large"
                                    bordered={false}
                                  />
                                </Form.Item>
                              </td>
                              <td
                                className="quiz-td3"
                                onClick={() => remove(name)}
                              >
                                <MdOutlineRemoveCircleOutline
                                  color="red"
                                  size={37}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="quiz-td1"></td>
                              <td colSpan={2} className="quiz-td2">
                                <QuestionOption
                                  key={key}
                                  form={form}
                                  key_name={name}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="quiz-td1"></td>
                              <td colSpan={2} className="quiz-td2">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  Answer:{" "}
                                  <Form.Item noStyle name={[name, "answer"]}>
                                    <Input
                                      placeholder="Enter Answer"
                                      bordered={false}
                                    />
                                  </Form.Item>
                                </div>
                              </td>
                            </tr>
                          </Table>
                        ))}
                        <Row>
                          <Col sm={7}></Col>
                          <Col sm={5}>
                            <Button block onClick={() => add()} size="large">
                              Add Questions +{" "}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <Row>
                    <Col sm={6}>
                      <Button
                        size="large"
                        block
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "save"}
                        onClick={() => Finished("save")}
                      >
                        {qid !== "create" ? "Update" : "Save"}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        disabled={data?.publishedAt ? true : false}
                        size="large"
                        block
                        type="primary"
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "publish"}
                        onClick={() => Finished("publish")}
                      >
                        Publish
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
}

export default QuizCampForm;
