import "../style.css";
import { API } from "../../../config/API";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import {
  Space,
  Table,
  Tag,
  Popconfirm,
  message,
  Pagination,
  Tooltip,
  Button,
} from "antd";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { LuUndo2 } from "react-icons/lu";
import { useSelector } from "react-redux";
import moment from "moment";

const InSnapDataTable = (props: any) => {
  const User = useSelector((state: any) => state.User.user);
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Bylane",
      dataIndex: "Bylane",
      key: "Bylane",
      width: 300,
      render: (text, record: any) => {
        return (
          <div
            onClick={() => props.onEdit(record)}
            style={{ fontSize: "10px", cursor: "pointer" }}
          >
            {record?.Bylane}
          </div>
        );
      },
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
      width: 300,
      render: (text, record: any) => {
        return (
          <div
            onClick={() => props.onEdit(record)}
            style={{ fontSize: "10px", cursor: "pointer" }}
          >
            {record?.caption}
          </div>
        );
      },
    },
    {
      title: "Cover Image",
      key: "image",
      dataIndex: "image",
      width: 200,
      render: (text, record: any) => {
        return (
          <div>
            {record.image ? (
              <img
                key={record?.image}
                src={record?.image}
                alt="Table Image"
                style={{ width: "70px", borderRadius: 6 }}
              />
            ) : (
              <div className="Insnap-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 10 }}
            onClick={() => props.onData(record?.user?.id)}
          >
            {record?.user?.username}
          </div>
        );
      },
    },
    {
      title: "Date-Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 300,
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 10 }}
            onClick={() => props.onEdit(record)}
          >
            {moment(record?.createdAt).format("DD-MM-YYYY-h:mm a")}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record?.publishedAt ? "lime" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record?.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
              cursor: "pointer",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record?.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteItem(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.INSNAP + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  return (
    <div className="mt-2">
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props?.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
};

export default InSnapDataTable;
