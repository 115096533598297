import {
  Card,
  Space,
  Table,
  Tag,
  Popconfirm,
  message,
  Pagination,
  Button,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import { API } from "../../../config/API";
import { BiEdit } from "react-icons/bi";
import { IoCopyOutline, IoTrashOutline } from "react-icons/io5";
import { LuUndo2 } from "react-icons/lu";
import { useSelector } from "react-redux";
import "../styles.css";
import { IoMdOpen } from "react-icons/io";
import moment from "moment";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const generateLink = (record: any, type: any) => {
    let link = record?.permalink?.replace(/\s+/g, "-");
    let url = `${API.APP_URL}Prabhaatham?id=${record.id}&link=${link}`;
    let body = `${record?.title} \nRead more at: ${url} ${
      props?.settings?.whatsappJoinLink
        ? `\nStay updated with the latest news! Join our WhatsApp group: ${props?.settings?.whatsappJoinLink}`
        : null
    }`;
    if (type === "copy") {
      navigator.clipboard.writeText(body).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(url, "_blank");
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: " Photos",
      key: "image1",
      dataIndex: "image1",
      render: (text, record: any) => {
        return (
          <div>
            {record.image1 ? (
              <img
                key={record?.image1}
                src={record?.image1}
                alt="Table Image"
                style={{ width: 70, borderRadius: 6 }}
              />
            ) : (
              <div className="Vidya-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 500,
      render: (text, record: any) => {
        return (
          <div
            onClick={() => props.onEdit(record)}
            style={{ fontSize: "10px" }}
          >
            {record.title}
          </div>
        );
      },
    },
    {
      title: "Date-Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 12 }}
            onClick={() => props.onEdit(record)}
          >
            {moment(record?.createdAt).format("DD-MM-YYYY-h:mm a")}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record.publishedAt ? "green" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            {record.publishedAt && (
              <>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoMdOpen
                    color="#666"
                    cursor={"pointer"}
                    size={20}
                    onClick={() => generateLink(record, "preview")}
                  />
                </Space>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoCopyOutline
                    size={20}
                    color="green"
                    cursor={"pointer"}
                    onClick={() => generateLink(record, "copy")}
                  />
                </Space>
              </>
            )}
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteItem(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.VIDYA_PRABHAADHAM + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  return (
    <div className="mt-3">
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
