import {
  Space,
  Table,
  Tag,
  Popconfirm,
  message,
  Pagination,
  Switch,
  Tooltip,
  Button,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import { API } from "../../../config/API";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { LuUndo2 } from "react-icons/lu";
import { IoMdEye } from "react-icons/io";
import { useSelector } from "react-redux";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Questions",
      dataIndex: "questions",
      key: "questions",
      width: 500,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }}>{record.attributes.questions}</div>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record?.attributes?.publishedAt ? "lime" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record?.attributes.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Publish",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Switch
              onClick={() => props.upload(record)}
              checked={record?.attributes?.isActive}
            />
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      width:50,
      render: (_, record:any) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignContent: "center",
              }}
            >
              <Space size="middle" style={{ marginRight: 10 }}>
                <IoMdEye
                  size={20}
                  cursor={"pointer"}
                  onClick={() => props.showResults(record)}
                />
              </Space>
              <Space size="middle" style={{ marginRight: 10 }}>
                <BiEdit
                  size={20}
                  color="#f5a442"
                  cursor={"pointer"}
                  onClick={() => props.onEdit(record)}
                />
              </Space>
              <Space size="middle" style={{ marginRight: 10 }}>
                <Tooltip
                  title={`${
                    record?.attributes?.is_deleted === true ? "Recover" : "Move"
                  } From Trash`}
                  color={"black"}
                >
                  {record?.attributes?.is_deleted === true ? (
                    <LuUndo2
                      color="blue"
                      size={20}
                      cursor={"pointer"}
                      onClick={() => props.unTrash(record)}
                    />
                  ) : (
                    <IoTrashOutline
                      color="red"
                      size={20}
                      cursor={"pointer"}
                      onClick={() => props.onTrash(record)}
                    />
                  )}
                </Tooltip>
              </Space>
              {record.attributes.is_deleted === true && User.role.id === 4 ? (
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onDeleteItem(record.id)}
                >
                  <Button size="small" danger style={{ fontSize: 12 }}>
                    Remove
                  </Button>
                </Popconfirm>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.POLLS + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.total}
          pageSize={props.meta && props.meta.pageSize ? props.meta.pageSize : 0}
          onChange={props.onPageChange}
        />
      </div>
    </div>
  );
}

export default DataTable;
