import { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../../component/pageheader";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import Loadingbox from "../../component/loadingbox";
import { API } from "../../config/API";
import { MdDelete } from "react-icons/md";
import { DELETE } from "../../utils/apirequest";
import { Popconfirm, message } from "antd";

function HighLights() {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsloading(true);
    let filters = `?pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=createdAt:DESC`;
    let url = API.BASE_URL + API.HIGHLIGHTS + filters;
    axios
      .get(url)
      .then((response) => {
        let data = response.data.data;
        let metaData = response.data.meta.pagination;
        setData(data);
        setMeta(metaData);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const handleDelete = async (id:any) => {
    let url = `highlights/${id}`;
    const res: any = await DELETE(url);
    if(res?.data?.id){
      message.success("Item Deleted")
      loadData();
    }else{
      message.error("Something went wrong")
    }
  }

  return (
    <>
      <div style={{ height: "100vh", overflow: "scroll" }}>
        <PageHeader
          total={data.length}
          item={"Highlights"}
          title={"Highlights"}
          buttontext="Create Highlights"
          goBack={() => {
            navigate("/Auth");
            loadData();
          }}
          onBtnPress={() => navigate("/Auth/highlights/form")}
        />
        {isloading ? (
          <Loadingbox />
        ) : (
          <>
            <div className="webStories-container">
              {data?.map((item: any) => {
                return (
                  <>
                    <div className="webStories-main">
                      <img
                        className="webStories-Img"
                        src={item?.attributes?.media}
                        alt=""
                      />
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this Item?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(item.id)}
                      >
                        <div
                          className="webStories-Delete"
                        >
                          <MdDelete size={24} color="red" />
                        </div>
                      </Popconfirm>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default HighLights;
