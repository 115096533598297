import { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import DataTable from "./component/datatable";
import Loadingbox from "../../component/loadingbox";
import { API } from "../../config/API";
import { PUT } from "../../utils/apirequest";
import { Button, DatePicker, Input, Tag, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { HiRefresh } from "react-icons/hi";

function VidyaScreeen() {
  const navigate = useNavigate();
  const [isTable, setIsTable] = useState(true);
  const [isloading, setIsloading] = useState(true);
  const [settingsList, setSettingsList] = useState<any[]>([]);
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [meta, setMeta] = useState() as any;
  const [inputValue, setInputValue] = useState("");
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [indicator, setIndicator] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = (filter: any) => {
    let url = API.BASE_URL + API.GET_VIDYA;
    axios
      .post(url, filter)
      .then((response) => {
        let data = response.data.data;
        let metaData = response.data.totalCount;
        setData(data);
        setMeta(metaData);
        setPublishCount(response.data?.publishedCount);
        setTrashcount(response.data?.trashedCount);
        getSettings();
        setIsloading(false);
        setIndicator(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const getSettings = () => {
    let url = API.BASE_URL + API.SETTINGS_LIST;
    axios.get(url).then((response) => {
      setSettingsList(response?.data?.data[0]?.attributes);
    });
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/VidyaScreeen/NewFormVidyaArticles/${val.id}`, {
      state: { item: val },
    });
    setItem(val);
    setIsTable(false);
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };
  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadPublished = () => {
    let filters = {
      ...filter,
      type: "published",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadTrashed = () => {
    let filters = {
      ...filter,
      type: "trash",
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const addToTrash = async (val: any) => {
    let url = API.VIDYA_PRABHAADHAM + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const UndoTrash = async (val: any) => {
    let url = API.VIDYA_PRABHAADHAM + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Vidya-Prabhaadham"} length={meta?.total}>
        <div className="homescreen-pageItem">
          <div style={{ width: 250 }}>
            <Input
              allowClear
              placeholder="Search Article "
              onChange={(e: any) => Search(e.target.value)}
            />
          </div>
          <div style={{ width: 250 }}>
            <DatePicker
              allowClear
              style={{ width: 200 }}
              onChange={onChangeDate}
            />
          </div>
          <Button
            type="primary"
            onClick={() => navigate("/Auth/VidyaScreeen/NewFormVidyaArticles")}
          >
            Add New +
          </Button>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="homescreen-pageItem">
        <div
          style={{
            flex: 5,
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(filter)?.length > 2
            ? "Filter By :"
            : "Vidya-Prabhaatham"}{" "}
          &nbsp;
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag style={{ marginBottom: 5 }}>
                  {`${key}:  ${
                    key === "createdAt"
                      ? moment(filter[key]).format("DD-MM-YYYY")
                      : filter[key]
                  }`}{" "}
                  <CloseCircleOutlined onClick={() => clearFilters(key)} />
                </Tag>
              )
          )}
        </div>
        <div className="homescreen-pageBox">
          <div className="homescreen-pageItem" onClick={() => LoadPublished()}>
            &nbsp;
            <span style={{ color: "green" }}>Published</span> : &nbsp;
            <b>{publishCount}</b>
            &nbsp;
          </div>
          |
          <div className="homescreen-pageItem" onClick={() => LoadTrashed()}>
            &nbsp;
            <span style={{ color: "red" }}>Trashed</span> : &nbsp;
            <b>{trashcount}</b>
            &nbsp;
          </div>
        </div>
        &nbsp;
        <div>
          <Button
            type="primary"
            ghost
            icon={<HiRefresh />}
            onClick={() => loadData(filter)}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div className="">
        {isloading ? (
          <Loadingbox />
        ) : (
          <DataTable
            data={data}
            meta={meta}
            page={page}
            pagesize={pageSize}
            onTrash={(val: any) => addToTrash(val)}
            unTrash={(val: any) => UndoTrash(val)}
            onEdit={(val: any) => onEdit(val)}
            onPageChange={onPageChange}
            refresh={() => loadData(filter)}
            settings={settingsList}
          />
        )}
      </div>
    </div>
  );
}

export default VidyaScreeen;
