import { Button, Card, Modal } from "antd";
import { useEffect, useRef, useState } from "react";

interface Prop {
  open: boolean;
  onCancel: () => void;
  onSaveClick: (rectangles: any) => void;
  imageUrl: any;
  initialCoords?: any[];
}

const CanvasModal = ({
  open,
  onCancel,
  imageUrl,
  onSaveClick,
  initialCoords,
}: Prop) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentRect, setCurrentRect] = useState({
    startX: 0,
    startY: 0,
    width: 0,
    height: 0,
  });
  const [rectangles, setRectangles] = useState<any>(initialCoords || []);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImage(img);
        setLoading(false);
      };
    }
  }, [imageUrl]);

  useEffect(() => {
    if (image && canvasRef.current) {
      redrawCanvas();
    }
  }, [image, rectangles]);

  useEffect(() => {
    if (image && initialCoords) {
      setRectangles(initialCoords);
    }
  }, [image, initialCoords]);

  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    
    if (canvas && image) {
      const context: any = canvas.getContext("2d");
      context?.clearRect(0, 0, canvas.width, canvas.height);
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
      rectangles.forEach((rect: any) => {
        context.strokeStyle = "red";
        context.lineWidth = 2;
        context?.strokeRect(
          rect.startX * canvas.width,
          rect.startY * canvas.height,
          rect.width * canvas.width,
          rect.height * canvas.height
        );
      });
    }
  };

  const handleMouseDown = (e: any) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect) {
      setCurrentRect({
        startX: (e.clientX - rect.left) / rect.width,
        startY: (e.clientY - rect.top) / rect.height,
        width: 0,
        height: 0,
      });
      setIsDrawing(true);
    }
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing) return;
    const canvas: any = canvasRef.current;
    const context: any = canvas?.getContext("2d");
    const rect = canvas?.getBoundingClientRect();
    if (rect) {
      const newRect = {
        startX: currentRect.startX,
        startY: currentRect.startY,
        width: (e.clientX - rect.left) / rect.width - currentRect.startX,
        height: (e.clientY - rect.top) / rect.height - currentRect.startY,
      };
      setCurrentRect(newRect);
      redrawCanvas();
      context.strokeStyle = "red";
      context.lineWidth = 1;
      context.strokeRect(
        newRect.startX * canvas.width,
        newRect.startY * canvas.height,
        newRect.width * canvas.width,
        newRect.height * canvas.height
      );
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    setRectangles((prevRectangles: any) => [...prevRectangles, currentRect]);
  };

  const handleDelete = (index: number) => {
    setRectangles((prevRectangles: any) => {
      const newRectangles = [...prevRectangles];
      newRectangles.splice(index, 1);
      return newRectangles;
    });
  };

  const drawRectangleOnCanvas = (rect: any, canvas: HTMLCanvasElement) => {
    if (canvas && image) {
      const context: any = canvas.getContext("2d");
      context?.clearRect(0, 0, canvas.width, canvas.height);
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
      context.strokeStyle = "red";
      context.lineWidth = 2;
      context?.strokeRect(
        rect.startX * canvas.width,
        rect.startY * canvas.height,
        rect.width * canvas.width,
        rect.height * canvas.height
      );
    }
  };

  return (
    <Modal
      title={"Mark Pages"}
      open={open}
      centered
      onCancel={() => onCancel()}
      footer={
        <div>
          <Button
            danger
            size="large"
            style={{ marginRight: "10px" }}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => onSaveClick(rectangles)}
          >
            Save
          </Button>
        </div>
      }
      width={"80%"}
    >
      <br />
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <div style={{ width: "70%" }}>
          {loading ? (
            "Loading..."
          ) : (
            <Card title={"Draw Boxes"}>
              <canvas
                ref={canvasRef}
                width={800}
                height={1000}
                style={{ width: "100%", height: "auto", cursor: "crosshair" }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              />
            </Card>
          )}
        </div>
        <div
          style={{
            width: "30%",
            overflowY: "scroll",
            maxHeight: "100vh",
          }}
        >
          <Card title={"Crop"} extra={`Crops ${rectangles?.length} `}>
            {rectangles?.reverse()?.map((rect: any, index: number) => (
              <div className="Epaper-cropItem">
                <div>{index + 1} . </div>
                <div style={{ flex: 1 }}>
                  <canvas
                    width={"100%"}
                    height={150}
                    style={{ border: "1px solid black" }}
                    ref={(el) => {
                      if (el) drawRectangleOnCanvas(rect, el);
                    }}
                  />
                </div>

                <Button size="small" danger onClick={() => handleDelete(index)}>
                  Remove
                </Button>
              </div>
            ))}
          </Card>
        </div>
      </div>
      <hr />
    </Modal>
  );
};

export default CanvasModal;
