import { Button, Form, Input, Modal } from "antd";
import { Col, Row } from "react-bootstrap";

export default function AlsoRead(props: any) {
  const [form] = Form.useForm();
  const onFinish = (val: any) => {
    props.onSubmit(val);
    props.close();
  };
  return (
    <Modal
      title={<div style={{ textAlign: "center" }}>Also Read</div>}
      open={props.visible}
      onCancel={() => props.close()}
      style={{ top: 20 }}
      width={500}
      footer={false}
    >
      <Row>
        <Form form={form} onFinish={onFinish}>
          <Col md={24}>
            <div className="newsFormLabels">News Link</div>
            <Form.Item name="link">
              <Input />
            </Form.Item>
            <div className="newsFormLabels">Preffered Content</div>
            <Form.Item name="content">
              <Input />
            </Form.Item>
            <Button
              className="hmScrn-SaveEditBtn"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
}
