import React, { useState } from "react";
import Datatable from "./datatable";
import PageHeader from "../../../component/pageHeaders";
import { Button, Popover } from "antd";
import { IoAlertCircleOutline } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";

function FormAnswers() {
  
  const [isLoading, setIsloading] = useState(true);
  const [isLoading2, setIsloading2] = useState(false);

  const generateExcel = async () => {}

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Responses"} loader={0} length={0}>
        <Popover
          content={
            <div style={{ whiteSpace: "pre-line", width: "400px" }}>
              <IoAlertCircleOutline size={15} /> Please Ensure Network Stability
              and System Availability For Proper Report Processing.
            </div>
          }
          title="Export Report"
        >
          <Button
            type="primary"
            loading={isLoading2}
            icon={<SiMicrosoftexcel />}
            onClick={() => generateExcel()}
          >
            Export
          </Button>
        </Popover>
      </PageHeader>
      <Datatable />
    </div>
  );
}

export default FormAnswers;
