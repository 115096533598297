import { useEffect, useState } from "react";
import "../style.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  message,
  Input,
  Button,
  Select,
  Checkbox,
  Switch,
  Popconfirm,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../../../component/pageHeaders";
import { API } from "../../../config/API";
import { GET, POST, PUT } from "../../../utils/apirequest";
import axios from "axios";
import MENU from "../menu.json";

function CreateUser() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [error, setError] = useState({}) as any;
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    if (id !== "create") {
      LoadDAta(id);
    }
  }, []);

  const LoadDAta = async (id: any) => {
    setLoading2(true);
    let url = API.USERS + `/${id}?populate=role`;
    try {
      const response: any = await GET(url, null, null);
      if (response?.id) {
        form.setFieldsValue({
          name: response?.username,
          email: response?.email,
          phone_number: response?.phone_number,
          role: response?.role?.id,
        });
      }
      setLoading2(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading2(false);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let body = {
      username: values?.name,
      email: values?.email,
      password: values?.password,
      role: values?.role,
      phone_number: values?.phone_number,
      screens: selectedValues,
    };

    let url = id == "create" ? API.CREATE_USER : API.PUT_UPDATE_USER + `${id}`;
    try {
      let METHOD = id == "create" ? POST : PUT;
      const response: any = await METHOD(url, body);
      if (response?.username) {
        setLoading(false);
        navigate(-1);
        message.success("Created User SucessFully");
      } else {
        setLoading(false);
        message.error("Something went wrong....!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong......!");
    }
  };

  const checkUser = (event: any) => {
    if (event.target.value.length > 3) {
      const username = event.target.value;
      const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(username);
      if (!isAlphanumeric) {
        setError({ name: "Name can only contain alphanumeric characters!" });
        return;
      }
      let body = {
        email: event.target.value,
        username: event.target.value,
      };
      let Url = API.BASE_URL + API.GET_USERSEARCH;
      axios.post(Url, body).then((response) => {
        if (response.data.username.length) {
          setError({ name: "This Username already exists" });
        } else {
          setError("");
        }
      });
    }
  };

  const checkEmail = (event: any) => {
    if (event.target.value.length > 3) {
      let body = {
        email: event.target.value,
        username: event.target.value,
      };
      let Url = API.BASE_URL + API.GET_USERSEARCH;
      axios.post(Url, body).then((response) => {
        if (response.data.email.length) {
          setError({ email: "This Email already exists" });
        } else {
          setError("");
        }
      });
    }
  };

  const validatePassword = (rule: any, value: any, callback: any) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?]).{8,}$/.test(
      value
    )
      ? callback()
      : callback(
          "Password must contain at least 8 characters, including uppercase and lowercase letters, numbers, and special characters."
        );

  const handleCheckboxChange = (value: string) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter((item) => item !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
  };

  const handleSelectAll = () => {
    MENU?.map((item: any) => handleCheckboxChange(item.id));
  };
  const deleteData = (id: any) => {
    setIsLoading(true);
    let url = API.BASE_URL + API.DELETE_USER + `${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        setIsLoading(false);
        navigate(`/Auth/users?page=1&limit=10`);
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
        setIsLoading(false);
      });
  };

  return (
    <div>
      <PageHeader
        title={"Users"}
        loader={loading2}
        length={`Dashboard / Users / Create Users`}
      >
        <div>
          <Switch onChange={handleSelectAll} />
        </div>
        <div style={{ width: 10 }} />
        <div>
          <Popconfirm
            placement="bottom"
            title={"Delete User"}
            description={"Are you sure you want to Delete"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteData(id)}
          >
            <Button loading={isLoading} danger>
              Delete User
            </Button>
          </Popconfirm>
        </div>
      </PageHeader>
      <Form form={form} onFinish={onFinish} name="add-news" scrollToFirstError>
        <Container fluid>
          <Row style={{ padding: 10 }}>
            <Col sm={8} xs={12}>
              <Row>
                <Col sm="6" xs="12">
                  <label className="newsFormLabels">User Name</label>
                  <Form.Item
                    name="name"
                    validateStatus={error["name"] ? "error" : ""}
                    help={error["name"]}
                  >
                    <Input size="large" onChange={checkUser} />
                  </Form.Item>
                  <label className="newsFormLabels">Email</label>
                  <Form.Item
                    name="email"
                    validateStatus={error["email"] ? "error" : ""}
                    help={error["email"]}
                  >
                    <Input size="large" onChange={checkEmail} />
                  </Form.Item>
                  <label className="newsFormLabels">Phone number</label>
                  <Form.Item name="phone_number">
                    <Input size="large" type="number" />
                  </Form.Item>
                </Col>
                <Col sm="6" xs="12">
                  <label className="newsFormLabels">Role</label>
                  <Form.Item name="role">
                    <Select placeholder="Select Role" size="large">
                      <Select.Option value={4}>Admin</Select.Option>
                      <Select.Option value={3}>Reporter</Select.Option>
                      <Select.Option value={5}>Contributer</Select.Option>
                      <Select.Option value={6}>Intern</Select.Option>
                      <Select.Option value={7}>Editor</Select.Option>
                    </Select>
                  </Form.Item>
                  <label className="newsFormLabels">Password</label>
                  <Form.Item
                    name="password"
                    rules={[
                      id == "create" ? { validator: validatePassword } : {},
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Confirm Password</label>
                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: id == "create" ? true : false,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message: "Passwordconfirm your passwords!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const password = getFieldValue("password");
                          if (!value || password === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`please confirm your password`)
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col sm={4} xs={12}>
              <div className="userfrom-box">
                <label className="newsFormLabels">Manage Access</label>
                <div style={{ margin: 10 }} />
                {MENU?.map((item: any) => {
                  return (
                    <div className="userfrom-roles-item" key={item?.id}>
                      <Checkbox
                        checked={selectedValues.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      >
                        {item?.value}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
        <div style={{ height: "9vh" }} />
        <div className="FixedFooter">
          <Container fluid>
            <Row>
              <Col sm={9} xs={12}></Col>
              <Col sm={1} xs={6}>
                <Button
                  size="large"
                  type="text"
                  danger
                  block
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={2} xs={6}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Form>
    </div>
  );
}
export default CreateUser;
