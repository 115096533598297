import React, { useState, useEffect } from "react";
import "./styles.css";
import { Layout, theme } from "antd";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router";
import { logout } from "../redux/slices/userslice";

import Header from "./Header";
import Siders from "./sider";

import DashboardScreen from "../screens/dashboardScreen";
import HomeScreen from "../screens/newsScreen";

import Settings from "../screens/settingsScreen";

import PodcastScreen from "../screens/podcastscreen";
import ReelsScreen from "../screens/reelsscreen";
import UserScreen from "../screens/userscreen";
import CategoryScreen from "../screens/categoryscreen";
import EditorialScreen from "../screens/editorialscreen";
import CreateUser from "../screens/userscreen/components/newform";
import NewForm from "../screens/newsScreen/component/newform";
import PollScreen from "../screens/pollscreeen/index";
import PollsForm from "../screens/pollscreeen/component/newform";
import VidyaScreeen from "../screens/vidyaPrabhaadham/index";
import NjayarScreen from "../screens/njayarPrabhaadham/index";
import CategoryForm from "../screens/categoryscreen/component/categoryForm";
import BannerScreen from "../screens/bannerScreen";
import WebStories from "../screens/webStoriesScreen";
import WebStoriesForm from "../screens/webStoriesScreen/component/form";
import WebStoriesImageList from "../screens/webStoriesScreen/component/dataList";
import InSnap from "../screens/inSnapScreen";
import InSnapForm from "../screens/inSnapScreen/component/form";
import InSnapImageList from "../screens/inSnapScreen/component/imageList";
import ProfileScreen from "../screens/profileScreen";
import EditProfileScreen from "../screens/profileScreen/component/editForm";

import ChangePassword from "../screens/profileScreen/component/changePassword";
import SubCategoryscreen from "../screens/subCategoryscreen";
import TagScreen from "../screens/tagsScreen";
import ArticleScreen from "../screens/articlescreen";
import NjayarForm from "../screens/njayarPrabhaadham/component/createNjayarForm";
import NewFormNjayarArticles from "../screens/njayarPrabhaadham/component/newform";
import NjayarArticles from "../screens/njayarPrabhaadham/component/njayarArticles";
import NewFormEditoriL from "../screens/editorialscreen/component/newform";
import NewFormArticle from "../screens/articlescreen/component/newForm";
import BannerForm from "../screens/bannerScreen/component/newForm";
import NewFormVidyaArticles from "../screens/vidyaPrabhaadham/component/newform";

import AddScreen from "../screens/addScreen";

import EPaperScreeen from "../screens/Epaper";
import EpaperForm from "../screens/Epaper/component/newform";

import Quiz from "../screens/quiz/master";
import QuizCampForm from "../screens/quiz/master/campainForm";
import QuizQuestion from "../screens/quiz/questions";
import QuestionForm from "../screens/quiz/questions/questionForm";
import QuizAnswers from "../screens/quiz/answers";

import Form from "../screens/form";
import CreateForm from "../screens/form/form";
import FormAnswers from "../screens/form/answers";

import Frames from "../screens/framesScreen";
import CreateFrames from "../screens/framesScreen/components/form";

import ElectionScreen from "../screens/electionScreen";
import ElectionForm from "../screens/electionScreen/newForm";
import ElectionItem from "../screens/electionScreen/Items";
import ElectionItemForm from "../screens/electionScreen/Items/newForm";
import HighLights from "../screens/highlghtsscreen";
import HighlightsNewForm from "../screens/highlghtsscreen/components/newform";
import CampaignScreen from "../screens/campaignScreen";
import CreateCampaign from "../screens/campaignScreen/newForm";

function Routing() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [userActivity, setUserActivity] = useState(false);

  const navigate: any = useNavigate();
  const dispatch = useDispatch();

  const resetUserActivity = () => {
    setUserActivity(false);
  };

  useEffect(() => {
    const handleUserActivity = () => {
      setUserActivity(true);
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    const timeoutId = setTimeout(() => {
      if (!userActivity) {
        dispatch(logout(""));
        navigate("/");
      }
      resetUserActivity();
    }, 3600000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, [userActivity]);

  return (
    <Layout>
      <Layout>
        <Siders />
        <Layout.Content>
          <Header />
          <div className="routeBox">
            <Routes>
              <Route path="/" element={<DashboardScreen />} />
              <Route path="/home" element={<HomeScreen />} />
              <Route path="/home/news" element={<NewForm />} />
              <Route path="/home/news/:id" element={<NewForm />} />
              <Route path="/reels" element={<ReelsScreen />} />
              <Route path="/podcast" element={<PodcastScreen />} />

              <Route path="/categories" element={<CategoryScreen />} />
              <Route path="/categories-form" element={<CategoryForm />} />
              <Route path="/editorial" element={<EditorialScreen />} />
              <Route
                path="/editorial/createform"
                element={<NewFormEditoriL />}
              />
              <Route
                path="/editorial/createform/:id"
                element={<NewFormEditoriL />}
              />
              <Route path="/polls" element={<PollScreen />} />
              <Route path="/poll/form/:id" element={<PollsForm />} />
              <Route path="/banner" element={<BannerScreen />} />
              <Route path="/banner/Create" element={<BannerForm />} />
              <Route path="/webstories" element={<WebStories />} />
              <Route path="/createwebStories" element={<WebStoriesForm />} />
              <Route
                path="/createwebStories/:id"
                element={<WebStoriesForm />}
              />
              <Route path="/storiesList" element={<WebStoriesImageList />} />
              <Route path="/VidyaScreeen" element={<VidyaScreeen />} />
              <Route
                path="/VidyaScreeen/NewFormVidyaArticles/:id"
                element={<NewFormVidyaArticles />}
              />
              <Route
                path="/VidyaScreeen/NewFormVidyaArticles"
                element={<NewFormVidyaArticles />}
              />

              <Route path="/NjayarScreen" element={<NjayarScreen />} />
              <Route path="/tags" element={<TagScreen />} />
              <Route path="/inSnap" element={<InSnap />} />
              <Route path="/insnap-form" element={<InSnapForm />} />
              <Route path="/insnap-imageList" element={<InSnapImageList />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/editprofile" element={<EditProfileScreen />} />
              <Route path="/ChangePassword/form" element={<ChangePassword />} />
              <Route path="/SubCategory" element={<SubCategoryscreen />} />
              <Route path="/Article" element={<ArticleScreen />} />
              <Route path="/Article/createForm" element={<NewFormArticle />} />
              <Route
                path="/Article/createForm/:id"
                element={<NewFormArticle />}
              />
              <Route path="/NjayarScreen/NjayarForm" element={<NjayarForm />} />
              <Route
                path="/NjayarScreen/njayarArticles/:id"
                element={<NjayarArticles />}
              />
              <Route
                path="/NjayarScreen/NewFormNjayarArticles/:id"
                element={<NewFormNjayarArticles />}
              />

              <Route path="AddScreen" element={<AddScreen />} />

              <Route path="/Epaper" element={<EPaperScreeen />} />
              <Route path="/EpaperForm/:id" element={<EpaperForm />} />

              <Route path="/Quiz" element={<Quiz />} />
              <Route path="/QuizCampForm/:id" element={<QuizCampForm />} />
              <Route path="/QuizQuestion/:id" element={<QuizQuestion />} />
              <Route path="/QuestionForm/:id/:qid" element={<QuestionForm />} />
              <Route path="/QuizAnswers/:id/:qid" element={<QuizAnswers />} />

              <Route path="/form" element={<Form />} />
              <Route path="/form/:id" element={<CreateForm />} />
              <Route path="/form-answers/:id" element={<FormAnswers />} />

              <Route path="/frames" element={<Frames />} />
              <Route path="/frames/:id" element={<CreateFrames />} />

              <Route path="/election" element={<ElectionScreen />} />
              <Route path="/election/:id" element={<ElectionForm />} />
              <Route path="/election-items/:EID" element={<ElectionItem />} />
              <Route
                path="/election-items/:EID/:id"
                element={<ElectionItemForm />}
              />

              <Route path="/users" element={<UserScreen />} />
              <Route path="/users/form/:id" element={<CreateUser />} />

              <Route path="/highlights" element={<HighLights />} />
              <Route path="/highlights/form" element={<HighlightsNewForm />} />

              <Route path="/campaign" element={<CampaignScreen />} />
              <Route path="/campaign/form/:id" element={<CreateCampaign />} />
              <Route path="/campaign/:id" element={<CampaignScreen />} />

              <Route path="/settings" element={<Settings />} />
            </Routes>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default Routing;
