import { createSlice } from "@reduxjs/toolkit";
const FormSlice = createSlice({
  name: "Form",
  initialState: {
    data: {},
    auth: false,
  },
  reducers: {
    save: (state, action) => {
      state.data = action.payload;
    },
    clear: (state, action) => {
      state.data = {};
    },
  },
});

export const { save, clear } = FormSlice.actions;
export default FormSlice.reducer;
