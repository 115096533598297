import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Picker from "../components/picker";
import { API } from "../../../config/API";
import { POST } from "../../../utils/apirequest";
import "../styles.css";

function Quiz() {
  const [section1, setSection1] = useState({}) as any;
  const [section2, setSection2] = useState({}) as any;
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "quiz",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find((item: any) => item.section === "section1");
        let sec2 = res.data.find((item: any) => item.section === "section2");
        setSection1(sec1);
        setSection2(sec2);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <Container fluid>
    <Row>
      <div className="addScreen-header">Header</div>
      <div className="addScreen-header">Quizz Banner</div>
      <Col sm={6}>
        <div className="addScreen-Detaild">നിബന്ധനകള്‍</div>
        <div className="addScreen-Detaild">Questions</div>
      </Col>
      <Col sm={6}>
        <Picker
          value={section1}
          screen={"quiz"}
          field={"section1"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          desktopSize={"1500x1000 , 1.5:1"}
          mobileSize={"1500x1000 , 1.5:1"}
          style={{ aspectRatio: 1.6 / 1 }}
          refresh={() => loadData()}
        />
        <div className="addScreen-Detaild" style={{height:450}}>Winners</div>
        <Picker
          value={section2}
          screen={"quiz"}
          field={"section2"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          desktopSize={"1500x1000 , 1.5:1"}
          mobileSize={"1500x1000 , 1.5:1"}
          style={{ height: 150 }}
          refresh={() => loadData()}
        />
      </Col>
    </Row>
    <br />
  </Container>
  
  );
}

export default Quiz;
