import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import HoverVideoPlayer from "react-hover-video-player";
import CircularProgress from "@mui/material/CircularProgress";
import { AiFillHeart } from "react-icons/ai";
import { RiShareForwardFill } from "react-icons/ri";
import { FaComment } from "react-icons/fa";
import { FaVolumeMute } from "react-icons/fa";
import { AiFillSound } from "react-icons/ai";
import { AiFillPlayCircle } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Popover, Button } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";

import "../styles.css";

function ReelsList(props: any) {
  const [mute, setMute] = useState(true);
  return (
    <>
      <div className="reelList-mainDiv">
        <div className="reelList-ScndDiv">
          {props.data?.map((item: any) => {
            return (
              <div>
                <div
                  className="reelScrn-scndDiv"
                  style={{
                    display: "flex",
                    width: 200,
                    padding: "10px",
                  }}
                >
                  <HoverVideoPlayer
                    style={{ height: 320, background: "#F5F5F5" }}
                    videoSrc={item?.attributes?.video}
                    loadingOverlay={
                      <div className="loading-overlay">
                        <CircularProgress style={{ color: "#fff" }} />
                      </div>
                    }
                    volume={0.5}
                    muted={mute}
                    hoverOverlay={
                      <div
                        className="reelScrn-HvrLayDiv1"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="reelScrn-HvrLayDiv2">
                          <AiFillPlayCircle
                            onClick={() =>
                              window.open(
                                item.attributes.video,
                                "MsgWindow",
                                "width=450,height=1000"
                              )
                            }
                            size={50}
                            color="white"
                            style={{ opacity: ".8" }}
                          />
                        </div>
                        <div className="reelScrn-hvrLayBtnsDiv">
                          <div
                            onClick={() => setMute(!mute)}
                            className="like-item"
                          >
                            {mute ? (
                              <FaVolumeMute color="#ffffff" size={18} />
                            ) : (
                              <AiFillSound color="#ffffff" size={18} />
                            )}

                            <span style={{ fontSize: "11px" }}></span>
                          </div>
                          <div>
                            <RiDeleteBin5Fill
                              color="red"
                              size={20}
                              onClick={() => props.onDelete(item.id)}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ReelsList;
