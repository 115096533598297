import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DynamicIcon from "../../routes/DynamicIcon";
import { LoadTags } from "../../utils/commonapis";
import { save } from "../../redux/slices/tagSlice";
import MENU from "./menu.json";
import "./styles.css";

function DashboardScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((state: any) => state.User.user);
  const Tags = useSelector((state: any) => state.Tags?.data);

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = async () => {
      const res: any = await LoadTags();
      dispatch(save(res));
  };

  return (
    <Container fluid={true}>
      <br />
      <div className="DashboardScreen-row">
        <div>
          <div className="DashboardScreen-txt1">Good Morning,</div>
          <div className="DashboardScreen-txt2">
            Welcome to Suprabhaatham Dashboard
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div className="DashboardScreen-txt3">{new Date().toDateString()}</div>
      </div>
      <br />
      <Row>
        {MENU?.map((item: any) => {
          return item?.role.includes(User?.role?.id) ? (
            <Col
              sm={3}
              key={item.head}
              onClick={() => navigate(item?.navigate)}
            >
              <div className="DashboardScreen-item">
                <div>
                  <DynamicIcon name={item.icon} size={30} color={"#0055a6"} />
                </div>
                &nbsp; &nbsp;
                <div>
                  <div className="DashboardScreen-itemtxt1">{item?.head}</div>
                  <div className="DashboardScreen-itemtxt2">
                    {item?.description}
                  </div>
                </div>
              </div>
            </Col>
          ) : null;
        })}
      </Row>
    </Container>
  );
}

export default DashboardScreen;
