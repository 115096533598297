import React, { useEffect, useRef, useState } from "react";
import { Input, Modal, Popover } from "antd";
import { Button, Form, notification } from "antd";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import PickImage from "./pickImage";
import { Col, Row } from "react-bootstrap";
import Loadingbox from "../loadingbox";
import { API } from "../../config/API";
import { Pagination } from "antd";
import axios from "axios";
import "./styles.css";

const update = "update";

const ImagePicker = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [CropVisible, setCropVisible] = useState(false);
  const [uploadeAvailable, setUploadeAvailable] = useState(true);
  const [medialibraryclick, setMedialibraryclick] = useState(false);
  const [image, setImage] = useState<any>({});
  const [sasUrl, setsasUrl] = useState<any>();
  const [media, setMedia] = useState<any>();
  const [imageDetails, setimageDetails] = useState<any>();
  const type = props?.type;
  const fileInputRef = useRef(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  const { TextArea } = Input;

  const [loadingS3, setLoadingS3] = useState(false);

  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<any>();
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(30);
  const [skip, setSkip] = useState<any>(0);
  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);

  useEffect(() => {
    loadData(page, pageSize, skip);
  }, [imageDetails, media]);

  const loadData = async (page: any, pageSize: any, skip: any) => {
    setLoading(true);
    try {
      let url =
        API.BASE_URL +
        API.GET_ALL_LIBRARIES +
        `?page=${page}&pageSize=${pageSize}&skip=${skip}`;
      const results = await axios.get(url);

      if (results) {
        const result = results.data.data;
        const Meta = results.data.totalPages;
        setData(result);
        setMeta(Meta);
        setResult(result);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const search = async (val: any) => {
    setLoading(true);
    let key = val.target.value;
    try {
      if (key === "") {
        loadData(page, pageSize, skip);
      } else {
        let url = API.BASE_URL + API.GET_ALL_SEARCHLIBRARY + `/${key}`;

        const results: any = await axios.get(url);
        setResult(results.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type == update) {
      form.setFieldsValue({
        name: props?.data?.name,
        image: props?.data?.image,
        description: props?.data?.description,
      });
    } else {
      form.resetFields();
    }
  }, [props?.data, type]);

  const SelectFromLibrary = (val: any, file: any) => {
    const match = file.imageUrl.match(/\/(\d{4}-\d{2}-\d{2})([a-zA-Z]+)/);
    const name = match && match[2];

    form.setFieldValue("imageTittle", file.imageName || name);

    setMedialibraryclick(true);
    setMedia(val);
    setsasUrl(val);
    setUploadeAvailable(false);
    setImage({ url: val });

    let obj = {
      image_url: val,
    };
    props.onSubmit(obj);
  };

  const handleCrop = async () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob(async (blob) => {
          if (blob) {
            const fle = new File(
              [blob],
              `-${moment().unix()}-suprbhatham.jpg`,
              {
                type: "image/jpeg",
              }
            );

            let fil = {
              file: fle,
            };
            form.setFieldsValue({
              image: fil,
            });

            const url = URL.createObjectURL(fle);
            setsasUrl(url);
            setUploadeAvailable(true);
          }
        }, "image/jpeg");
      }
    }
  };

  const uploadImagesToS3 = async (file: any, info: any) => {
    let details = JSON.stringify(info);
    setLoadingS3(true);
    try {
      let data = new FormData();
      data.append("file", file);
      data.append("details", details);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: API.BASE_URL + API.IMAGE_UPLODER,
        data: data,
      };
      let response = await axios.request(config);
      if (response) {
        setImageUrl(response.data);
        Notifications["success"]({
          message: "Success",
          description: `Uploaded Image Successfully`,
        });
      } else {
        Notifications["error"]({
          message: "Oops !",
          description: `Failed to upload image. Please try again`,
        });
      }

      setLoadingS3(false);
      return response;
    } catch (error) {
      Notifications["error"]({
        message: "Oops !",
        description: `Failed to upload image. Try with another image`,
      });
      console.log(error);
      setLoadingS3(false);
    }
  };
  const onfinish = async (val: any) => {
    let info = {
      title: val.imageTittle,
      captionn: val.caption,
      discription: val.discription,
    };

    if (uploadeAvailable) {
      const response = await uploadImagesToS3(val.image.file, info);
      if (response) {
        let obj = {
          image_url: response?.data,
          type: props.imageType,
          image_desc: val?.discription,
          image_Caption: val?.caption,
        };

        props.onSubmit(obj);
        if (props.imageType === "Media") {
          props.onSelect(response?.data, val?.discription);
        }
        setCropVisible(false);
        setMedialibraryclick(false);
        setDescription("");
        setImageUrl("");
        setImage("");
        setsasUrl("");
        setMedia("");
        props.close();
      }
    } else {
      let obj = {
        image_url: media,
        type: props.imageType,
        image_desc: val?.discription,
        image_Caption: val?.caption,
      };
      if (props.imageType === "Media") {
        props.onSelect(media, val?.discription);
      }
      props.onSubmit(obj);
      setCropVisible(false);
      setMedialibraryclick(false);
      setDescription("");
      setImageUrl("");
      setImage("");
      setsasUrl("");
      setMedia("");
      props.close();
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    let take = page === 1 ? 0 : page * pageSize - 30;

    setPage(page);
    setPageSize(pageSize);
    setSkip(take);
    loadData(page, pageSize, take);
  };

  return (
    <div className="">
      {contextHolder}
      <Modal
        centered
        width={1200}
        footer={false}
        style={{ maxHeight: "80vh" }}
        title={`Choose ${props.imageName}`}
        open={props.visible}
        onOk={() => props.close()}
        onCancel={() => {
          setCropVisible(false);
          setMedialibraryclick(false);
          props.close();
          setDescription("");
          setImageUrl("");
          setImage("");
          setsasUrl("");
          setMedia("");
        }}
      >
        <div className="">
          <Form
            form={form}
            layout="vertical"
            onFinish={onfinish}
            initialValues={{
              imageTittle: imageDetails?.file?.name,
            }}
          >
            {/* -------------- Image Picker Start -------------- */}
            <Row>
              <Col md="6">
                {CropVisible ? (
                  <div className="">
                    <Form.Item name="image">
                      <Input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <Cropper
                        ref={cropperRef as React.RefObject<ReactCropperElement>}
                        src={image?.url}
                        style={{ height: 200, width: "100%" }}
                        guides={false}
                      />
                      <div style={{ textAlign: "center", marginTop: 16 }}>
                        <Button
                          type="primary"
                          onClick={handleCrop}
                          style={{ marginLeft: 16 }}
                        >
                          Crop Image
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                ) : medialibraryclick ? (
                  <>
                    <Form.Item name="image">
                      <PickImage
                        onChange={(file: any) => {
                          setImage(file ? file : media);
                          setimageDetails(file ? file : "");
                          setImageUrl(media);
                          setsasUrl(file ? file?.url : media);
                          form.setFieldsValue({
                            imageTittle: file.file.name,
                            discription: "",
                            caption: "",
                          });
                          file
                            ? setUploadeAvailable(true)
                            : setUploadeAvailable(false);
                        }}
                        fileURL={
                          image?.url
                            ? image.url
                            : type == update
                            ? props?.data?.image
                            : media
                        }
                        type="medialibrary"
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    name="image"
                    rules={[
                      {
                        required: true,
                        message: "Please Upload image",
                      },
                    ]}
                  >
                    <PickImage
                      onChange={(file: any) => {
                        setImage(file);
                        setimageDetails(file);
                        setsasUrl(file?.url);
                        form.setFieldValue("imageTittle", file.file.name);
                      }}
                      fileURL={
                        image?.url
                          ? image.url
                          : type == update
                          ? props?.data?.image
                          : null
                      }
                    />
                  </Form.Item>
                )}
              </Col>

              {sasUrl ? (
                <Col md="6">
                  <Row className="croppButton">
                    <Col md="6">
                      <div className="newsFormLabels">Image </div>
                      <Form.Item name="image">
                        <div className="image-croppButton">
                          <img src={sasUrl} />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col md="6">
                      <div>{imageDetails?.file?.type}</div>
                      <div
                        onClick={() => setCropVisible(true)}
                        style={{ color: "#0055a6", cursor: "pointer" }}
                      >
                        Edit Image
                      </div>
                    </Col>

                    <Col md="12">
                      <br />
                      <div className="newsFormLabels">Image title</div>
                      <Form.Item name="imageTittle">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md="12">
                      <div className="newsFormLabels">Description</div>
                      <Form.Item name="discription">
                        <TextArea />
                      </Form.Item>
                    </Col>
                    <Col md="12">
                      <div className="newsFormLabels">Caption</div>
                      <Form.Item name="caption">
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Row>
            {/* -------------- Image Picker End -------------- */}

            {/* -------------- Media Library Start -------------- */}
            <br />
            <Row>
              <div style={{ overflow: "scroll", height: "300px" }}>
                <Row>
                  <Col md="6">
                    <div className="MediaLibrary-txt1">Media Library</div>
                  </Col>
                  <Col md="6">
                    <Input.Search
                      placeholder="Search image"
                      onChange={search}
                      // suffix={
                      //   <span onClick={() => loadData(page, pageSize, skip)}>
                      //     <IoCloseCircleOutline size={20} color="gray" />
                      //   </span>
                      // }
                    />
                  </Col>
                </Row>

                {loading ? (
                  <Loadingbox />
                ) : (
                  <>
                    <Row>
                      {result.map((item: any, index) => {
                        return (
                          <Col sm="2" xs="6" style={{ padding: 10 }}>
                            <Popover placement="top" content={item.Key}>
                              <img
                                alt=""
                                className="MediaLibrary-image"
                                src={item.imageUrl}
                                onClick={() =>
                                  SelectFromLibrary(item.imageUrl, item)
                                }
                              />
                            </Popover>
                          </Col>
                        );
                      })}
                    </Row>
                    {result?.length > 29 ? (
                      <div className="paginationImagePicker">
                        <Pagination
                          defaultCurrent={page}
                          total={meta}
                          pageSize={pageSize}
                          onChange={(val, val2) => onPageChange(val, val2)}
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </Row>

            {/* -------------- Media Library End -------------- */}
            <br />
            <Row>
              <Col md="6"></Col>
              <Col md="6">
                <div className="d-flex gap-2 justify-content-end">
                  <Button
                    style={{ width: "49%", height: 40 }}
                    onClick={() => {
                      setCropVisible(false);
                      setMedialibraryclick(false);
                      props.close();
                      setDescription("");
                      setImageUrl("");
                      setImage("");
                      setsasUrl("");
                      setMedia("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ width: "49%", height: 40 }}
                    type="primary"
                    loading={loadingS3}
                    htmlType="submit"
                  >
                    {type == update ? "Update" : "Add"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ImagePicker;
