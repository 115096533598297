import { IoIosCloseCircleOutline } from "react-icons/io";

import { Modal, Progress } from "antd";
import { Table } from "react-bootstrap";

function Result(props: any) {
  return (
    <Modal
      open={props.open}
      onCancel={props.onCancel}
      closeIcon={<IoIosCloseCircleOutline />}
      footer={false}
      title={<h6>Answers</h6>}
      width={600}
    >
     

      <br />
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Id.</th>
            <th>Question</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {props.item?.quiz_answer_items?.map((item: any, index: number) => {
            return (
              <tr key={item?.id}>
                <th>{index + 1}.</th>
                <td>{item?.question}</td>
                <td>{item?.answer}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <br/>
       <div>
        <pre>{JSON.stringify(props.item, null, 2)}</pre>
      </div>
    </Modal>
  );
}
export default Result;
