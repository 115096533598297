import { Button, Form, Input } from "antd";
import "../styles.css";
import { IoMdClose } from "react-icons/io";
import { Col, Row } from "react-bootstrap";
function QuestionOption(props: any) {
  return (
      <Form.List name={[props?.key_name, "options"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField },index) => (
              <tr>
                <td className="quiz-td4">{index + 1}.</td>
                <td className="quiz-td2">
                  <Form.Item noStyle name={[name, "option"]}>
                    <Input placeholder="Enter Options" bordered={false} />
                  </Form.Item>
                </td>
                <td>
                  <Button
                    type="link"
                    danger
                    size="small"
                    onClick={() => remove(name)}
                  >
                    <IoMdClose size={20} />
                  </Button>
                </td>
              </tr>
            ))}
            <Row>
              <Col sm={8}></Col>
              <Col sm={4}>
                <Button
                  block
                  size="small"
                  type="dashed"
                  onClick={() => add()}
                  style={{ backgroundColor: "#dde6ed",marginTop:5}}
                >
                  Add Option +
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
  );
}

export default QuestionOption;
