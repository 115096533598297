import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import PageHeader from "../../component/pageHeaders";
import Loadingbox from "../../component/loadingbox";
import DataTable from "./dataTable";
import { API } from "../../config/API";
import { DELETE, POST, PUT } from "../../utils/apirequest";

function ElectionScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      setIndicator(true);
      let url = API.ELECTION_MASTER_LIST;
      const res: any = await POST(url, filter);
      if (res?.data) {
        setData(res?.data);
        setIsloading(false);
        setIndicator(false);
      }
    } catch (err) {
      console.log("err", err);
      setIsloading(false);
      setIndicator(false);
      message.error("Something went wrong...");
    }
  };

  const toggleStatus = async (val: any) => {
    let URL = API.ELECTION_MASTER_EDIT + val?.id;
    let obj = {
      data: {
        publishedAt: val?.publishedAt ? null : new Date(),
      },
    };
    let res: any = await PUT(URL, obj);
    if (res?.data?.id) {
      message.success("Status Changed");
      loadData(filter);
    }
  };

  const openElectItems = (val: any) => {
    navigate(`/Auth/election-items/${val?.id}`);
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/election/${val?.id}`);
  };

  const addToTrash = async (val: any, type: any) => {
    let url = API.ELECTION_MASTER_EDIT + `${val.id}`;
    let obj = { data: { is_deleted: type } };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.ELECTION_MASTER_EDIT + `${val.id}`;
      let response: any = await DELETE(url);
      if (response) {
        loadData(filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Election"} loader={indicator} length={meta}>
        <div className="homescreen-pageItem">
          <Button
            type="primary"
            onClick={() => navigate("/Auth/election/create")}
          >
            New Campaign +
          </Button>
        </div>
      </PageHeader>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <div style={{ height: "80vh", overflow: "scroll" }}>
          <DataTable
            data={data}
            meta={meta}
            page={page}
            pagesize={pageSize}
            toggleStatus={(val: any) => toggleStatus(val)}
            openElectItems={(val: any) => openElectItems(val)}
            onEdit={(val: any) => onEdit(val)}
            onTrash={(val: any) => addToTrash(val, true)}
            unTrash={(val: any) => addToTrash(val,false)}
            onDelete={(val: any) => onDelete(val)}
          />
        </div>
      )}
    </div>
  );
}

export default ElectionScreen;
