export const API = {
  BASE_URL: "https://suprabhaathamnews-apis.com/api/",
  // BASE_URL: "http://localhost:8080/api/",
  GRAPHQL: "https://suprabhaathamnews-apis.com/graphql",
  // GRAPHQL: "http://localhost:8080/graphql",
  APP_URL: "https://www.suprabhaatham.com/",
  EDITOR_API_KEY: "a88vqf05b0e8l04vr9rgei6lnsno0j02twz9wml46yyltwi7",
  WHATS_APP_LINK: "https://chat.whatsapp.com/Ebq27HufM0NHH37t6blQtO",

  CACHE_SERVER_1: "http://51.159.194.148/",
  CACHE_SERVER_2: "http://51.159.97.6/",
  CACHE_SERVER_3: "http://62.210.171.34/",
  CACHE_SERVER_4: "http://3.110.62.60/", //TEST

  EPAPER_PDF: "https://e-files.suprabhaatham.com/pdf/convert",
  EPAPER_IMAGE: "https://e-files.suprabhaatham.com/img/upload",

  // ---------endpoints----- --
  // LOGIN: "auth/local",
  LOGIN: "userLogin",
  IMAGE_UPLODER: "s3upload",
  UPLOADTO_MEDIALIBRARY: "insertMediaLibrary",
  VERIFY_OTP: "verifyOTP",
  REGISTER: "auth/local/register",
  CREATE_USER: "createUser",
  GET_ROLE: "users/me?populate=*",

  ALL_NEWS: "all-news",
  GET_NEWS: "filterNews",
  GET_NEWS_META: "newsMeta",
  REFRESH_CACHE: "api/revalidate?path=details/",

  GET_USERS_LIST: "filterUsers",
  EXPORT_USER_DATA: "ExportLog",
  PUBLISH_NEWS: "publish",
  EDITORIALS: "editorials",
  GET_EDITORIALS: "EditorialFilter",
  USERS: "users",
  CREATENEWS: "createNews",
  CATEGORY: "categories",
  GET_CATEGORY: "CategoryFilter",
  GET_TAGFILTER: "TagFilter",
  USER_ROLE: "users-permissions/roles",
  PODCAST: "podcasts",
  REELS: "reels",

  POLLS: "polls",
  POLL_OPTION: "poll-options",
  CREATE_POLL: "createPolls",
  EDIT_POLL: "editPolls/",

  MAKE_VISIBLE: "polls/makeVisible",
  BANNERS: "bannners",
  GET_BANNERS: "filterBanner",
  GET_NJYAR: "NJayarFilter",
  GET_VIDYA: "VidyaFilter",
  WEB_STORIES: "webstories",
  ADD_TO_TRASH: "trash",
  UNTRASH: "unTrash",
  VIDYA_PRABHAADHAM: "vidyaprabhaadhams",
  NJAYAR_PRABHAADHAM: "njayarprabhaadhams",
  INSNAP: "in-snaps",
  GET_TRASHED: "getTrashedItems",
  PUT_UPDATE_USER: "updateUser/",
  GET_USERS: "getusers",
  GET_USERSEARCH: "usersearch",
  DELETE_USER: "deleteUser/",
  SUBCATEGORY: "subcategories",

  GET_ALL_LIBRARIES: "getAllLibraries",
  GET_ALL_SEARCHLIBRARY: "getAllSearchLibraries",
  GET_ALLNEWS_ID: "getAllNewsById/",
  ARTICLE: "articles",
  GET_ARTICLE: "ArticleFilter",
  HIGHLIGHTS: "highlights",
  UPDATE_NEWS: "updateNews",
  NJAYAR_MASTER: "njayarprabhaadham-masters",
  VIDYA_MASTER: "vidyaprabhaadham-masters",
  GET_INSNAP: "insnapFilter",
  GET_WEBSTRY: "WebStoriesFilter",
  GET_ADD: "advertisements",

  CREATE_LOGS: "logs",

  EPAPER_LIST: "filterEpaper", //POST
  EPAPER_ADD: "e-papers", // POST
  EPAPER_EDIT: "e-papers/", // PUT
  EPAPER_DELETE: "e-papers/", // DELETE
  EPAPER_PUBLISH: "e-papers", //PUT
  EPAPER_DETAILS: "e-papers/", //GET

  QUIZZ_MASTER_LIST: "filterQuiz",
  QUIZZ_MASTER_ADD: "quiz-masters",
  QUIZZ_MASTER_EDIT: "quiz-masters/", //PUT
  QUIZZ_MASTER_DELETE: "quiz-masters/", // DELETE
  QUIZZ_MASTER_PUBLISH: "quiz-masters", //PUT
  QUIZZ_MASTER_DETAILS: "quiz-masters/", //GET
  QUIZZ_MASTER_ACTIVE: "quiz-masters/activate/", //GET

  QUIZZ_ITEM_LIST: "filterQuizItems",
  QUIZZ_ITEM_ADD: "addQuiz",
  QUIZZ_ITEM_EDIT: "editQuiz",
  QUIZZ_ITEM_DELETE: "quiz-items/",
  QUIZZ_ITEM_DETAILS: "quiz-items/",
  QUIZZ_ITEM_ACTIVATE: "quiz-item/activate-quiz/",
  QUIZZ_SELECT_WINNER: "quiz/select-winner",
  GET_EXCEL_DATA: "quiz-answers/getExcelData",
  QUIZZ_ANSWER_LIST: "filterQuizAnswers",

  ADVERTISEMENT_ADD: "advertisements",
  ADVERTISEMENT_EDIT: "advertisements/",
  ADVERTISEMENT_DELETE: "advertisements/",
  ADVERTISEMENT_LIST: "advertisements/filter",

  DYNAMIC_FORM_ADD: "forms",
  DYNAMIC_FORM_EDIT: "forms/",
  DYNAMIC_FORM_DETAILS: "forms/",
  DYNAMIC_FORM_LIST: "form/filter",
  DYNAMIC_FORM_RESPONSES: "form/getExcelData",

  FRAME_ADD: "frames",
  FRAME_EDIT: "frames/",
  FRAME_DETAILS: "frames/",
  FRAME_LIST: "frames",

  ELECTION_MASTER_ADD: "election-masters",
  ELECTION_MASTER_EDIT: "election-masters/",
  ELECTION_MASTER_DETAILS: "election-masters/",
  ELECTION_MASTER_LIST: "election-master/filter",

  ELECTION_ITEMS_ADD: "election-items",
  ELECTION_ITEMS_EDIT: "election-items/",
  ELECTION_ITEMS_DETAILS: "election-items/",
  ELECTION_ITEMS_LIST: "election-items/filter",

  SETTINGS_ADD: "settings",
  SETTINGS_EDIT: "settings/",
  SETTINGS_DETAILS: "settings/",
  SETTINGS_LIST: "settings",

  TAGS_LIST: "tags",
  TAGS_DELETE: "tags/",
  TAGS_LIST_ALL: "getTags",
  TAGS_ADD: "tag/create",
};
