import { API } from "../../../config/API";

const homeCache = async () => {};

const refreshNewsCache = async (id: any) => {
  let url = API.CACHE_SERVER_4 + API.REFRESH_CACHE + id;
  let res = await fetch(url, {
    method: 'GET',
    referrerPolicy: 'unsafe-url'
  });
  let data = await res.json();
  if (data?.revalidated) {
    console.log("Cache revalidated for id:", id);
  } else {
    console.log("Couldn't refresh cache for id:", id);
  }
};

export { homeCache, refreshNewsCache };
