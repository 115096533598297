import { useEffect, useState } from "react";
import PageHeader from "../../../component/pageheader";
import { useNavigate } from "react-router-dom";
import { Row ,Col} from "react-bootstrap";
import { Form, Input,  Button, message, Select } from "antd";
import uploadImg from "../../../assets/image/Group 225.svg";

import Loadingbox from "../../../component/loadingbox";
import moment from "moment";
import { uploadImageToS3WebStories } from "../../../config/s3config/s3fileUpload";
import { API } from "../../../config/API";
import { GET, POST } from "../../../utils/apirequest";

const HighlightsNewForm = (props: any) => {
  const [imageUrl, setImageurl] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    LoadTags()
  },[])

  const LoadTags = async() => {
    let url =  API.TAGS_LIST_ALL;
    let res:any = await GET(url,null,null)
    setTags(res)
  }

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    let requestObj = {
      data: {
        media: imageUrl ? imageUrl : "",
        likes: 0,
        comments: "",
        shares: 0,
        tags:values.tags,
        permalink:values.permalink,
      },
    };
    let url = API.HIGHLIGHTS;
    const response = await POST(url, requestObj);
    if (response) {
      message.success("Successfully Created");
      navigate("/Auth/highlights");
    }
  };

  const uploadImage = async (val: any, files: any) => {
    const fileName = `${moment().format(
      "YYYY-MM-DD HH:mm:ss"
    )}suprabhatham-webstories.png`;
    const file = val.target.files[0];
    const response = await uploadImageToS3WebStories(file, fileName);
    setImageurl(response.Location);
    setIsloading(false);
  };

  return (
    <>
      <div>
        <PageHeader
          title={"Create Highlights"}
          goBack={() => navigate("/Auth/highLights")}
        />
        <div style={{ height: "90vh", overflowY: "scroll" }}>
          <Form onFinish={onFinish}>
            <Row>
              <Col xs={3} />
              <Col xs={6}>
                {imageUrl ? (
                  <>
                    {isLoading ? (
                      <Loadingbox />
                    ) : (
                      <div className="webStories-main">
                        <img className="webStories-Img" src={imageUrl} alt="" />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="formItem">
                      <div className="formLabel">Image</div>
                      <div className="webStoriesForm-imgUpldr">
                        <img src={uploadImg} alt="" />
                        <Input
                          className="inputFile"
                          type="file"
                          onChange={(val) => uploadImage(val, __filename)}
                        />
                        <div className="webstories-imgTxt">
                          Upload Image Here
                        </div>
                      </div>
                    </div>
                    <br />
                  </>
                )}
                <label className="newsFormLabels">Tags</label>
                <Form.Item name="tags">
                  <Select
                   mode="multiple"
                    style={{ width: "100%" }}
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tags.length > 0 &&
                      tags.map((item: any, index: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <label className="newsFormLabels">Perma Link</label>
                <Form.Item name="permalink">
                  <Input placeholder="" />
                </Form.Item>
                <Row>
                  <Col xs={6}></Col>
                  <Col xs={3}>
                  <Button block onClick={() => navigate("/Auth/highLights")}>
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <Button block type="primary" htmlType="submit">
                      publish
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={3} />
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default HighlightsNewForm;
