import { Button, Modal } from "antd";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
export default function OverWrite(props: any) {
  const navigate = useNavigate();
  const Overwrite = () => {
    return (
      <main style={{textAlign:'center'}}>
        <BiEdit style={{fontSize:50}}/>
        <br/>  <br/>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Montserrat-SemiBold",
            fontSize: "16px",
          }}
        >
          {`Update Ongoing By ${props?.data?.liveEditingUser}`}
        </div>
        <br />
        <Row>
          <Col sm={6} xs={12}>
            <Button danger block onClick={() => props.close()}>
              Cancel
            </Button>
          </Col>
          <Col sm={6} xs={12}>
            <Button
              block
              type="primary"
              onClick={() =>
                navigate(`/Auth/home/news/${props?.data?.id}`, {
                  state: { item: props?.data?.onlive },
                })
              }
            >
              Overwrite
            </Button>
          </Col>
        </Row>
      </main>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      style={{ top: 20 }}
      width={400}
      footer={false}
      centered
    >
      <br/>
      <Overwrite />
    </Modal>
  );
}
