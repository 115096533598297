import { createSlice } from "@reduxjs/toolkit";

const TagSlice = createSlice({
  name: "Tags",
  initialState: {
    data: {},
  },
  reducers: {
    save: (state, action) => {
      state.data = action.payload;
    },
    clear: (state, action) => {
      state.data = {};
    },
  },
});


export const {save,clear} = TagSlice.actions;
export default TagSlice.reducer