import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles.css";
import Picker from "../components/picker";
import { API } from "../../../config/API";
import { POST } from "../../../utils/apirequest";
function Details() {
  const [section1, setSection1] = useState({}) as any;
  const [section2, setSection2] = useState({}) as any;
  const [section3, setSection3] = useState({}) as any;
  const [section4, setSection4] = useState({}) as any;
  const [section5, setSection5] = useState({}) as any;
  const [section6, setSection6] = useState({}) as any;
  const [section7, setSection7] = useState({}) as any;
  const [section8, setSection8] = useState({}) as any;
  const [section9, setSection9] = useState({}) as any;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "details-screen",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find((item: any) => item.section === "section1");
        let sec2 = res.data.find((item: any) => item.section === "section2");
        let sec3 = res.data.find((item: any) => item.section === "section3");
        let sec4 = res.data.find((item: any) => item.section === "section4");
        let sec5 = res.data.find((item: any) => item.section === "section5");
        let sec6 = res.data.find((item: any) => item.section === "section6");
        let sec7 = res.data.find((item: any) => item.section === "section7");
        let sec8 = res.data.find((item: any) => item.section === "section8");
        let sec9 = res.data.find((item: any) => item.section === "section9");
        setSection1(sec1);
        setSection2(sec2);
        setSection3(sec3);
        setSection4(sec4);
        setSection5(sec5);
        setSection6(sec6);
        setSection7(sec7);
        setSection8(sec8);
        setSection9(sec9);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Container fluid>
      <Row>
        <div className="addScreen-header">Header</div>
        <Picker
          value={section1}
          screen={"details-screen"}
          field={"section1"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          style={{ height: 150 }}
          refresh={() => loadData()}
        />
        <Col md={8}>
          <div className="addScreen-header">News Title</div>
          <Picker
            value={section2}
            screen={"details-screen"}
            field={"section2"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
          <div className="addScreen-Detaild" style={{ aspectRatio: 1 / 0 }}>
            Image
          </div>
          <Picker
            value={section3}
            screen={"details-screen"}
            field={"section3"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
          <div className="addScreen-Detaild">Detailed</div>
          <Picker
            value={section4}
            screen={"details-screen"}
            field={"section4"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
          <div className="addScreen-header">Comments</div>
          <Picker
            value={section5}
            screen={"details-screen"}
            field={"section5"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
          <Row>
            <Col md={6}>
              <br />
              <Picker
                value={section6}
                screen={"details-screen"}
                field={"section6"}
                title={"Top Screen"}
                desc={"This add will Show above header"}
                style={{ height: 150 }}
                refresh={() => loadData()}
              />
              <div className="addScreen-Related">Related News</div>
            </Col>
            <Col md={6}>
              <br />
              <Picker
                value={section7}
                screen={"details-screen"}
                field={"section7"}
                title={"Top Screen"}
                desc={"This add will Show above header"}
                style={{ height: 150 }}
                refresh={() => loadData()}
              />
              <div className="addScreen-Related">Related News</div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <br />
          <Picker
            value={section8}
            screen={"details-screen"}
            field={"section8"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
          <div className="addScreen-Detaild">Related News</div>
          <div className="addScreen-Detaild">Related News</div>
          <div className="addScreen-Detaild">Related News</div>
          <Picker
            value={section9}
            screen={"details-screen"}
            field={"section9"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default Details;
