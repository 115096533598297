import { useRef } from "react";
import { Button, Input, message, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import { IoCopyOutline } from "react-icons/io5";
import { MdDownload } from "react-icons/md";
import { API } from "../../../config/API";

function ShareModal(props: any) {
  const ref = useRef<HTMLDivElement>(null);

  const copyLink = () => {
    let link = props?.item?.permalink?.replace(/\s+/g, "-");
    let url = `${API.APP_URL}form?id=${props?.item?.id}&link=${link}`;
    navigator.clipboard.writeText(url).then(() => {
      message.success("link copied");
    });
  };

  const downloadQRCode = () => {
    const qrCodeElement = ref.current?.querySelector("svg");
    if (!qrCodeElement) return;

    const svgData = new XMLSerializer().serializeToString(qrCodeElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");

      const downloadLink = document.createElement("a");
      downloadLink.href = pngFile;
      downloadLink.download = "QRCode.png";
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Modal
      visible={props?.visible}
      onCancel={() => props?.onCancel()}
      footer={false}
      width={350}
    >
      <div style={{ marginTop: -10 }}>{props?.item?.title}</div>
      <hr />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div ref={ref}>
          <QRCode
            value={`${API.APP_URL}form?id=${props?.item?.id}&link=${props?.item?.permalink}`}
          />
        </div>
        <br />
        <Input
          size="large"
          value={`${API.APP_URL}form?id=${props?.item?.id}&link=${props?.item?.permalink}`}
          suffix={
            <IoCopyOutline size={20} color="green" onClick={() => copyLink()} />
          }
        />
      </div>
      <br />
      <Row>
        <Col sm={6}>
          <Button
            block
            type="primary"
            ghost
            icon={<IoCopyOutline />}
            onClick={() => copyLink()}
          >
            Copy Link
          </Button>
        </Col>
        <Col sm={6}>
          <Button
            block
            type="primary"
            icon={<MdDownload />}
            onClick={downloadQRCode}
          >
            Download
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default ShareModal;
