import { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../../component/pageHeaders";
import { API } from "../../config/API";
import "./styles.css";
import { Button, message } from "antd";
import NewForm from "./component/newform";
import ReelsList from "./component/reelsList";
import Loadingbox from "../../component/loadingbox";

function ReelsScreen() {
  const [isReels, setIsReels] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    let url = API.BASE_URL + API.REELS;
    axios.get(url).then((response) => {
      let data = response.data.data;
      setData(data);
      setIsLoading(false);
    });
  };
  const deleteData = (id: any) => {
    let url = API.BASE_URL + API.REELS + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        setIsLoading(false);
        message.success("Successfully deleted");
        loadData();
      })
      .catch((error: any) => {
        console.log(error);
        message.destroy("Something went wrong...!");
      });
  };

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      {isReels ? (
        <PageHeader title={"Reels"} length={data.length}>
          <Button type="primary" onClick={() => setIsReels(false)}>
            Add New +
          </Button>
        </PageHeader>
      ) : null}
      <div className="mt-3">
          {isReels ? (
            isLoading ? (
              <Loadingbox />
            ) : (
              <ReelsList data={data} onDelete={(id: any) => deleteData(id)} />
            )
          ) : (
            <NewForm
              onClose={() => setIsReels(true)}
              reload={() => loadData()}
            />
          )}
      </div>
    </div>
  );
}

export default ReelsScreen;
