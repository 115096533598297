import { Button, Form, Input, Select } from "antd";
import moment from "moment";
import { Table } from "react-bootstrap";

function HighlightForm(props: any) {
  return (
    <div>
      <label className="newsFormLabels">HighLights</label>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Link</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <Form.List
            name="data"
            initialValue={[{ createdAt: moment().format("LT"), id: 0 }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ width: "50%" }}>
                      <Form.Item
                        name={[name, "title"]}
                        rules={[{ required: true, message: "Required" }]}
                        noStyle
                      >
                        <Input
                          placeholder="title"
                          bordered={false}
                          style={{ marginBottom: -10 }}
                        />
                      </Form.Item>
                    </td>
                    <td style={{ width: "50%" }}>
                      <Form.Item name={[name, "link"]} noStyle>
                        <Input
                          placeholder="link"
                          bordered={false}
                          style={{ marginBottom: -10 }}
                        />
                      </Form.Item>
                    </td>
                    <Form.Item
                      name={[name, "createdAt"]}
                      noStyle
                      style={{ display: "none" }}
                    >
                      <Input
                        placeholder="time"
                        bordered={false}
                        defaultValue={moment().format("HH:mm")}
                        readOnly
                        style={{ display: "none" }}
                      />
                    </Form.Item>
                    <td>
                      <Button
                        size="small"
                        block
                        danger
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={4}></td>
                  <td>
                    <Button
                      size="small"
                      block
                      // onClick={() => add()}
                      onClick={() =>
                        add({ 
                          id: fields.length, 
                          createdAt: moment().format("HH:mm") })
                      }
                      style={{ backgroundColor: "green", color: "#fff" }}
                    >
                      Add +
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </Form.List>
        </tbody>
      </Table>
    </div>
  );
}

export default HighlightForm;
