import { Button, Form, Input, Select } from "antd";
import { Table } from "react-bootstrap";

function LocationForm(props: any) {
  return (
    <div>
      <label className="newsFormLabels">Locations</label>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th>Location Name</th>
            <th>Party</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <Form.List
            name={[props?.key_name, "data"]}
            initialValue={[{ party: "" }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ width: "50%" }}>
                      <Form.Item
                        name={[name, "name"]}
                        rules={[{ required: true, message: "Required" }]}
                        noStyle
                      >
                        <Input
                          placeholder="Enter Name"
                          bordered={false}
                          style={{ marginBottom: -10 }}
                        />
                      </Form.Item>
                    </td>
                    <td style={{ width: "20%", padding: 0 }}>
                      <Form.Item
                        name={[name, "party"]}
                        rules={[{ required: true, message: "Required" }]}
                        noStyle
                      >
                        <Select bordered={false} style={{ width: "100%" }} loading={props.loading}>
                          {props?.party?.options &&
                            props?.party?.options?.map((item: any) => {
                              let val = JSON.stringify(item);
                              return (
                                <Select.Option key={val}>
                                  {item.party}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ width: "20%", padding: 0 }}>
                      <Form.Item noStyle name={[name, "status"]}>
                        <Select
                          bordered={false}
                          placeholder="Status"
                          style={{ width: "100%" }}
                        >
                          <Select.Option key={"up"}>UP</Select.Option>
                          <Select.Option key={"down"}>DOWN</Select.Option>
                          <Select.Option key={"win"}>WIN</Select.Option>
                          <Select.Option key={"fail"}>FAIL</Select.Option>
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <Button
                        size="small"
                        block
                        danger
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={4}></td>
                  <td>
                    <Button
                      size="small"
                      block
                      onClick={() => add()}
                      style={{ backgroundColor: "green", color: "#fff" }}
                    >
                      Add +
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </Form.List>
        </tbody>
      </Table>
    </div>
  );
}

export default LocationForm;
