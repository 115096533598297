import React, { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import DataTable from "./components/datatable";
import Loadingbox from "../../component/loadingbox";
import { API } from "../../config/API";
import { DELETE, GET, PUT } from "../../utils/apirequest";
import ShareModal from "./components/shareModal";

function Frames() {
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  const [selectItem, setSelectItem] = useState({});
  const [shareModal, setShareModal] = useState(false);

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      setIndicator(true);
      let url = API.FRAME_LIST;
      const res: any = await GET(url, null, null);
      if (res?.data) {
        setData(res?.data);
        setIsloading(false);
        setIndicator(false);
      }
    } catch (err) {
      console.log("err", err);
      setIsloading(false);
      setIndicator(false);
      message.error("Something went wrong...");
    }
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/frames/${val?.id}`);
  };

  const addToTrash = async (val: any, type: any) => {
    let url = API.FRAME_EDIT + `${val.id}`;
    let obj = { data: { is_deleted: type } };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.FRAME_EDIT + `${val.id}`;
      let response: any = await DELETE(url);
      if (response) {
        loadData(filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleStatus = async (val: any) => {
    let URL = API.FRAME_EDIT + val?.id;
    let obj = {
      data: {
        publishedAt: val?.attributes?.publishedAt ? null : new Date(),
      },
    };
    let res: any = await PUT(URL, obj);
    if (res?.data?.id) {
      message.success("Status Changed");
      loadData(filter);
    }
  };

  const openShareModal = (item: any) => {
    setSelectItem(item);
    setShareModal(true);
  };

  return (
    <div>
      <PageHeader title={"Frames"} loader={0} length={`Dashboard / Frames`}>
        <div className="homescreen-pageItem">
          <Button
            type="primary"
            onClick={() => navigate("/Auth/frames/create")}
          >
            New Frame +
          </Button>
        </div>
      </PageHeader>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data}
          page={page}
          pagesize={pageSize}
          onEdit={(val: any) => onEdit(val)}
          onTrash={(val: any) => onDelete(val)}
          toggleStatus={(val: any) => toggleStatus(val)}
          shareModal={(item: any) => openShareModal(item)}
        />
      )}
      {ShareModal ? (
        <ShareModal
          item={selectItem}
          visible={shareModal}
          onCancel={() => setShareModal(false)}
        />
      ) : null}
    </div>
  );
}

export default Frames;
