import { useEffect, useState } from "react";
import { Input, Pagination, DatePicker } from "antd";
import { API } from "../../config/API";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import useDebounce from "../../shared/hook/useDebounce";
function Gallary(props: any) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(50);
  const [skip, setSkip] = useState<any>(0);
  const [meta, setMeta] = useState<any>();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState<string>("");
  const [date, setDate] = useState<any>("");
  const debounceSearch = useDebounce(search, 600);

  useEffect(() => {
    loadData(page, pageSize, skip, search, date);
  }, [debounceSearch]);

  const loadData = async (
    page: any,
    pageSize: any,
    skip: any,
    search: string,
    date: any = ""
  ) => {
    try {
      setLoading(true);
      let params = `?page=${page}&pageSize=${pageSize}&skip=${skip}&search=${search}&date=${date}`;
      let url = API.BASE_URL + API.GET_ALL_LIBRARIES + params;
      const results = await axios.get(url);
      if (results) {
        const result = results.data.data;
        const Meta = results.data.totalPages;
        setData(result);
        setMeta(Meta);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onImageClick = (item: any) => {
    const myNewFile = new File([item], item?.imageName || "image", {
      type: "image/jpeg",
    });
    const url = item.imageUrl || URL.createObjectURL(myNewFile);
    let obj = {
      file: myNewFile,
      url: url,
    };
    props?.setMedia(item.imageUrl);
    props?.handleDrop(obj);
    props?.setUploadeAvailable(false);
    setTimeout(() => props?.setTab("picker"), 300);
  };

  return (
    <div>
      <div className="imagePicker-GallaryHeader">
        <div>
          <Input
            placeholder="Search Images . . . "
            onChange={(txt) => setSearch(txt.target.value)}
          />
        </div>
        &nbsp;
        <div>
          <DatePicker
            onChange={(a, date) => loadData(page, pageSize, skip, search, date)}
          />
        </div>
        <div style={{ flex: 1 }} />
        <div>
          <Pagination
            size="small"
            defaultCurrent={1}
            total={500}
            defaultPageSize={50}
            onChange={(page, size) => {
              const skip = (page - 1) * size;
              setPage(page);
              setPageSize(size);
              loadData(page, size, skip, search, date);
            }}
          />
        </div>
      </div>
      <div style={{ margin: 10 }} />
      {loading ? (
        // <div>Loading . . . .</div>
        <div>
          <LinearProgress color={"primary"} style={{ height: 1 }} />
        </div>
      ) : (
        <div className="">
          <Row>
            {data.map((item: any, index: any) => {
              return (
                <Col sm={2} key={index}>
                  <img
                    onClick={() => onImageClick(item)}
                    src={item.imageUrl}
                    style={{
                      aspectRatio: 1,
                      width: "100%",
                      backgroundColor: "grey",
                      marginBottom: 20,
                      objectFit: "contain",
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </div>
  );
}

export default Gallary;
