import {
  Space,
  Table,
  Tag,
  Popconfirm,
  Pagination,
  Tooltip,
  notification,
  Button,
  message,
  Image,
  Spin,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { IoCopyOutline, IoTrashOutline } from "react-icons/io5";
import axios from "axios";
import { API } from "../../../config/API";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaRegEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { TbSquareNumber1, TbSquareNumber2 } from "react-icons/tb";
import { IoMdOpen } from "react-icons/io";
import { useState, useEffect } from "react";
import { GET } from "../../../utils/apirequest";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  const [Notifications, contextHolder] = notification.useNotification();
  const [template, setTemplate] = useState(null) as any;

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    let url = API.SETTINGS_DETAILS;
    let res: any = await GET(url, null, null);
    if (res?.data) {
      setTemplate(res?.data[0]?.attributes?.whatsappLinkAttachment);
    }
  };

  const generateLink = (record: any, type: any) => {
    let link = record?.permalink?.replace(/\s+/g, "-");
    let url = `${API.APP_URL}details/${record.id}?link=${link}`;
    let body = `${record?.head} \nRead more at: ${url} ${
      props?.settings?.whatsappJoinLink
        ? `\nStay updated with the latest news! Join our WhatsApp group: ${props?.settings?.whatsappJoinLink}`
        : null
    }`;
    if (template) {
      let what = template
        .replace("${heading}", `${record.head}`)
        .replace("${link}", url);
      body = what;
    }
    if (type === "copy") {
      navigator.clipboard.writeText(body).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(url, "_blank");
    }
  };

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Photos",
      key: "imageurl",
      dataIndex: "imageurl",
      render: (text, record: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {record.image ? (
              <Image
                key={record.image}
                src={record.image}
                style={{ width: 70, borderRadius: 6 }}
                alt="Table Image"
              />
            ) : (
              <div className="homescreen-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Headline",
      dataIndex: "newshead",
      key: "newshead",
      width: 600,
      render: (text, record: any) => {
        return (
          <>
            {record?.onlive === true ? <Tag color="red">Live</Tag> : null}
            {/* {record.editing_Status ? (
              <strong
                style={{ color: "#0055a6", fontSize: "12px" }}
              >{`  ${record.liveEditingUser}  is currently editing`}</strong>
            ) : null} */}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.onEdit(record)}
            >
              {record.head}
            </div>
          </>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "user",
      key: "user",
      width: 300,
      render: (user: any) => {
        return <div style={{ fontSize: "12px" }}>{user?.username}</div>;
      },
    },
    {
      title: "Date-Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 12 }}
            onClick={() => props.onEdit(record)}
          >
            {moment(record?.createdAt).format("DD-MM-YYYY-h:mm a")}
          </div>
        );
      },
    },
    {
      title: "type",
      dataIndex: "newshead",
      key: "address",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px", cursor: "pointer" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.categories?.map((item: any) => {
              return <Tag style={{ fontSize: 10 }}>{item?.category}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "isHighPriority",
      key: "isHighPriority",
      render: (text, record: any) => {
        return (
          <>
            {record.isHighPriority ? <TbSquareNumber1 size={15} /> : null}
            {record.isSecondLeads ? <TbSquareNumber2 size={15} /> : null}
          </>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={
                record?.is_deleted === true
                  ? "red"
                  : record?.publishedAt
                  ? "green"
                  : record?.publishedAt === null && record?.publish_at
                  ? "pink"
                  : "blue"
              }
              style={{ fontSize: "10px" }}
            >
              {record?.is_deleted === true
                ? "trash"
                : record?.publishedAt
                ? "Published"
                : record?.publishedAt === null && record?.publish_at
                ? "Scheduled"
                : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Log",
      key: "log",
      render: (text, record: any) => {
        return (
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <FaRegEye size={20} onClick={() => props.onlog(record.id)} />
          </div>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            {record.publishedAt && (
              <>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoMdOpen
                    color="#666"
                    cursor={"pointer"}
                    size={20}
                    onClick={() => generateLink(record, "preview")}
                  />
                </Space>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoCopyOutline
                    size={20}
                    color="green"
                    cursor={"pointer"}
                    onClick={() => generateLink(record, "copy")}
                  />
                </Space>
              </>
            )}
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            &nbsp;
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteItem(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.ALL_NEWS + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        Notifications["success"]({
          message: `Successfully deleted`,
        });
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
        Notifications["error"]({
          message: `Oops! Something went wrong`,
        });
      });
  };

  return (
    <div style={{ marginTop: 10 }}>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        {props.loading ? (
          <Spin size="small" />
        ) : (
          <Pagination
            responsive
            defaultCurrent={props?.page}
            total={props.meta && props.meta}
            pageSize={props?.pagesize}
            onChange={(p, t) => {
              props.onPageChange(p, t);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DataTable;
