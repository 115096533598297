import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import NullLogo from "../../assets/image/nulllogo.svg";
import TextArea from "antd/es/input/TextArea";
import { Button, Tag } from "antd";
import Footer from "./components/footer";
import { FaUserCircle } from "react-icons/fa";
import { CiBookmark, CiCamera } from "react-icons/ci";
import { Breadcrumb } from "antd";
import { IoIosArrowForward } from "react-icons/io";
import { RiFontSize } from "react-icons/ri";
import IND from "../../assets/image/ind.png";
import { RiTwitterFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io5";
import { AiOutlineFacebook } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import axios from "axios";
import NewsItem3 from "./components/newsItem3";
import NewsItem2 from "./components/newsItem2";
import { API } from "../../config/API";

function WebPreview() {
  const [news, setnews] = useState() as any;

  const storedData: any = localStorage.getItem("previewdata");
  const predata = JSON.parse(storedData);

  let style = {
    marginBottom: 10,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    fontFamily: "Noto_Sans_Medium_500",
    cursor: "pointer",
  };
  useEffect(() => {
    getSheduledItems();
  }, []);
  const getSheduledItems = () => {
    const graphqlQuery = `
    query {
      allNews(
			  filters: { categories: {category: {eq: "LatestNews"}}}
			  sort: "id:desc"
			  pagination:{page:1,pageSize:30}
			) {
			  data {
				id
				attributes {
				  head
				  title
				  body
				  image
				  createdAt
          audio
          likes
          permalink
				}
			  }
			}
		  }`;
    axios({
      method: "post",
      url: API.GRAPHQL,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: graphqlQuery,
      },
    })
      .then((response) => {
        const count = response.data.data.allNews.data;
        setnews(count);
      })
      .catch((error) => {
        console.error("Error:", error);
        // setIsloading(false);
      });
  };
  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      <Header />
      <div>
        <Container fluid={false}>
          <Row>
            {predata?.map((item: any) => {
              return (
                <>
                  <Col sm={8} xs={12}>
                    <div className="details_box">
                      <br />
                      <Breadcrumb
                        separator={
                          <span style={{ color: "#ffff" }}>
                            <IoIosArrowForward color="rgba(54, 54, 54, 0.8)" />
                          </span>
                        }
                      >
                        <Breadcrumb.Item>HOME</Breadcrumb.Item>
                        <Breadcrumb.Item>DETAILS</Breadcrumb.Item>
                      </Breadcrumb>
                      <div className="page-header">
                        <div className="tags_Header">
                          <div className="tags">
                            {item.tag
                              ? item.tag.map((item: any) => (
                                  <Tag
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "grey",
                                      cursor: "pointer",
                                    }}
                                    key={item}
                                  >
                                    {item.toUpperCase()}
                                  </Tag>
                                ))
                              : null}
                          </div>
                          <div style={{ flex: 1 }}></div>
                          <div>
                            <div
                              style={{
                                border: "1px solid #d4d2d2",
                                borderRadius: 4,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 10,
                                height: 30,
                              }}
                            >
                              <RiFontSize size={20} />
                            </div>
                          </div>
                          <div style={{ margin: 5 }}></div>
                          <div>
                            <div
                              style={{
                                border: "1px solid #d4d2d2",
                                borderRadius: 4,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 13,
                                paddingLeft: 10,
                                paddingRight: 10,
                                height: 30,
                              }}
                            >
                              <div style={style}>
                                <div>
                                  <img
                                    src={IND}
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </div>
                                &nbsp;
                                <div> MAL</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <h1 className="details_txt1">{item?.head}</h1>
                          {item.title ? (
                            <div
                              style={{
                                marginTop: -10,
                                marginBottom: 10,
                                fontFamily: "Noto_Sans_Medium_500",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item?.title,
                              }}
                            ></div>
                          ) : null}
                          <div className="details_box1">
                            <div>
                              <div className="details_box4">
                                <div>
                                  <FaUserCircle size={20} />
                                </div>
                                &nbsp;&nbsp;
                                <div>
                                  <div className="details_txt3">
                                    {item?.author_name}
                                  </div>
                                  <div className="details_txt2">
                                    {moment().format("MMMM DD yy | HH:MM A")}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="ShareBox">
                                <div>
                                  <CiBookmark color="black" />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                  <IoLogoWhatsapp />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                  <AiOutlineFacebook />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                  <RiTwitterFill />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                  <FiMoreVertical />
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          {item?.image ? (
                            <img
                              src={item?.image}
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: 6,
                              }}
                              alt={""}
                            />
                          ) : (
                            <img
                              style={{
                                width: "100%",
                                height: "400px",
                                objectFit: "cover",
                                borderRadius: 6,
                                backgroundColor: "#D9D9D9",
                              }}
                              src={NullLogo}
                            />
                          )}
                          <div className="captionDiscription">
                            <div className="caption">
                              {item?.image1_caption}
                            </div>
                            {item?.image1_description ? (
                              <div className="discription">
                                {item?.image1_description}
                                &nbsp;|&nbsp;
                                <CiCamera size={20} />
                              </div>
                            ) : null}
                          </div>

                          <br />
                          <div>
                            {item?.body
                              .split("\n")
                              .map((paragraph: any, index: any) => (
                                <p
                                  key={index}
                                  style={{
                                    fontFamily: "Noto_Sans_Medium_500",
                                    marginBottom: "16px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: paragraph,
                                  }}
                                ></p>
                              ))}
                          </div>
                        </div>
                        <div
                          style={{ borderTop: "1px solid rgb(234, 234, 234)" }}
                        />
                        <br />
                        <p className="CommentBox_txt1">Comments</p>
                        <TextArea
                          rows={4}
                          className="typeBox"
                          autoSize={false}
                          placeholder="Write a comment"
                        />
                        <span className="CommentBox_txt2">
                          <strong>Disclaimer:</strong> "The website reserves the
                          right to moderate, edit, or remove any comments that
                          violate the guidelines or terms of service."
                        </span>
                        <div className="CommentBox_box">
                          <Button
                            style={{
                              backgroundColor: "#0055A6",
                              color: "#ffff",
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                      <div className="page-header">
                        <div>
                          <h2 className="page-heading">RELATED NEWS</h2>
                        </div>
                        <div style={{ flex: 1 }}></div>
                      </div>
                      <Row>
                        <Col sm={news?.length > 22 ? 6 : 12} xs={24}>
                          {news?.length
                            ? news?.slice(10, 22)?.map((item: any) => {
                                return <NewsItem3 key={item.id} item={item} />;
                              })
                            : null}
                        </Col>
                        <Col sm={6} xs={12}>
                          {news?.length
                            ? news?.slice(23, 25)?.map((item: any) => {
                                return <NewsItem2 key={item.id} item={item} />;
                              })
                            : null}
                          {news?.length
                            ? news?.slice(25, 30)?.map((item: any) => {
                                return <NewsItem3 key={item.id} item={item} />;
                              })
                            : null}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={4} xs={12}>
                    <div className="details_box3">
                      {news?.length
                        ? news?.slice(0, 10)?.map((item: any) => {
                            return <NewsItem3 key={item.id} item={item} />;
                          })
                        : null}
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default WebPreview;
