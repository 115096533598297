import { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders";
import { Row, Col, Container } from "react-bootstrap";
import Loadingbox from "../../../component/loadingbox";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Select, Switch, message } from "antd";
import LeadForm from "../components/leadForm";
import CandidateForm from "../components/candidateForm";
import LocationForm from "../components/locationForm";
import { API } from "../../../config/API";
import { PUT, POST, GET } from "../../../utils/apirequest";
import HighlightForm from "../components/highlightForm";

function ElectionItemForm() {
  const [form] = Form.useForm();
  let { id, EID } = useParams();
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isLoading1, setIsLoading1] = useState<any>(false);
  const [isLoading2, setIsLoading2] = useState<any>(false);
  const [formtype, setFormtype] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [data, setData] = useState([]) as any;
  const [party, setParty] = useState([]) as any;

  useEffect(() => {
    if (id !== "create") {
      getData();
    } else {
      setIsLoading(false);
    }
    loadMasters();
  }, []);

  const getData = async () => {
    try {
      let url = API.ELECTION_ITEMS_DETAILS + id;
      let res: any = await GET(url, null, null);
      if (res?.data?.id) {
        let data = res?.data?.attributes;
        setData(data);
        setType(data.type);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };

  const loadMasters = async () => {
    try {
      setIsLoading2(true)
      let url = API.ELECTION_MASTER_DETAILS + EID;
      let res: any = await GET(url, null, null);
      let data = res?.data?.attributes?.parties;
      setParty(data);
    } catch (err) {
      console.log("err", err);
    }finally{
      setIsLoading2(false)
    }
  };

  const Finished = async (type: any) => {
    try {
      setFormtype(type);
      setIsLoading1(true);
      form.submit();
      await form.validateFields();
      let values = form.getFieldsValue();
      var requestObj = {
        data: {
          title: values?.title,
          type: values?.type,
          data: values?.data,
          election_master: EID,
        },
      };
      let url =
        id !== "create" ? API.ELECTION_ITEMS_EDIT + id : API.ELECTION_ITEMS_ADD;
      let METHOD = id !== "create" ? PUT : POST;
      let res: any = await METHOD(url, requestObj);
      if (res?.data?.id) {
        message.success(
          `Campaign Item ${
            id !== "create" ? "Updated" : "Created"
          } Successfully.`
        );
        setTimeout(() => {
          window.location.replace(
            `/Auth/election-items/${EID}/${res?.data?.id}`
          );
        }, 200);
      }
    } catch (err) {
      console.log(err);
      setIsLoading1(false);
    }
  };

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Election Items"} length={"Add New Campaign"} />
      <br />
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div>
            <Form
              form={form}
              initialValues={{
                title: data?.title,
                type: data?.type,
                data: data?.data,
              }}
            >
              <Row>
                <Col sm={4}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item
                    name={"title"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                </Col>
                <Col sm={4}>
                  <label className="newsFormLabels">Type</label>
                  <Form.Item
                    name="type"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      size="large"
                      onChange={(val) => setType(val)}
                      disabled={id !== "create" ? true : false}
                    >
                      <Select.Option key={"lead"}>Lead</Select.Option>
                      <Select.Option key={"candidate"}>Candidate</Select.Option>
                      <Select.Option key={"location"}>Location</Select.Option>
                      <Select.Option key={"highlight"}>Highlights</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={4}>
                  <label className="newsFormLabels">Info</label>
                </Col>
              </Row>
              {type === "lead" && <LeadForm key_name={"data"} party={party} loading={isLoading2}/>}
              {type === "candidate" && (
                <CandidateForm key_name={"data"} form={form} party={party} loading={isLoading2}/>
              )}
              {type === "location" && (
                <LocationForm key_name={"data"} party={party} loading={isLoading2}/>
              )}
              {type === "highlight" && (
                <HighlightForm key_name={"data"} party={party} loading={isLoading2}/>
              )}
              <hr />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Row>
                    <Col sm={6}>
                      <Button
                        size="large"
                        block
                        style={{ height: 50 }}
                        loading={isLoading1 && formtype === "save"}
                        onClick={() => Finished("save")}
                      >
                        {id !== "create" ? "Update" : "Save"}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        disabled={data?.publishedAt ? true : false}
                        size="large"
                        block
                        type="primary"
                        style={{ height: 50 }}
                        loading={isLoading1 && formtype === "publish"}
                        onClick={() => Finished("publish")}
                      >
                        Publish
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Container>
    </div>
  );
}

export default ElectionItemForm;
