import { Button, Form, Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { uploadPodcast } from "../../../config/s3config/s3fileUpload";
import moment from "moment";
import { API } from "../../../config/API";
import ImageUpload from "../../../assets/image/Group 225.svg";
import PageHeader from "../../../component/pageHeaders";
import ImagePicker2 from "../../../component/image-pickersecond";
import { GET } from "../../../utils/apirequest";

const PodcastForm = (props: any) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState<any>([]);
  const [imageurl, setImageurl] = useState("");
  const [isUploading, setIsUploading] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [imageUploader, setImageUploader] = useState(false);
  const [videoUrl, setVideoUrl] = useState<any>("");

  const LoadTags = async() => {
    let url =  API.TAGS_LIST_ALL;
    let res:any = await GET(url,null,null)
    setTags(res)
  }

  useEffect(() => {
    LoadTags()
  },[])

  const onFinish = async (values: any) => {
    setLoading(true);
    let requestObj = {
      data: {
        title: values.head,
        body: values.body,
        audio: values.link,
        tags: values.tags,
        permalink: values.permalink,
        image:
          props.items.id && props.items.attributes.image
            ? props.items.attributes.image
            : imageurl,
      },
    };
    let url =
      props.items && props.items.id
        ? API.BASE_URL + API.PODCAST + `/${props.items.id}`
        : API.BASE_URL + API.PODCAST;

    let method = props.items && props.items.id ? "PUT" : "POST";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        setLoading(false);
        props.onClose();
        message.success(
          <div>
            Success: <b>Saved</b>
          </div>
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onImageUpload = async (val: any) => {
    setIsUploading(true);
    const fileName: any =
      moment().unix().toString() + "-suprabhaatham-podcasts.jpg";
    const file = val;
    const response = uploadPodcast(file, fileName);
    response.then((location) => {
      setVideoUrl(location.Location);
      setImageurl(location.Location);
      setIsUploading(false);
    });
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };
  const addImageToList = async (val: any) => {
    if (val?.type === "medialibrary") {
      setImageurl(val?.val);
      setVideoUrl(val?.val);
    } else {
      await onImageUpload(val?.val?.file);
    }
  };

  return (
    <>
      <PageHeader title={"Podcast Form"} goBack={() => props.onClose()} />
      <div
        className="portcastForm-mainDiv"
        style={{ height: "70vh", overflowY: "scroll" }}
      >
          <Row style={{ marginTop: 10 }}>
            <Form
              onFinish={onFinish}
              name="add-podcast"
              form={form}
              initialValues={{
                head:
                  props.items &&
                  props.items.id &&
                  props.items.attributes &&
                  props.items.attributes.title,
                body:
                  props.items &&
                  props.items.id &&
                  props.items.attributes &&
                  props.items.attributes.body,
                link:
                  props.items &&
                  props.items.id &&
                  props.items.attributes &&
                  props.items.attributes.audio,
                image:
                  props.items &&
                  props.items.id &&
                  props.items.attributes &&
                  props.items.attributes.image,
                  permalink:
                  props.items &&
                  props.items.id &&
                  props.items.attributes &&
                  props.items.attributes.permalink,
                tags:
                  props.items &&
                  props.items.id &&
                  props.items.attributes &&
                  props.items.attributes?.tags && props.items.attributes?.tags?.data.map((item:any) => item?.id) ,
              }}
            >
              <Col md={{ span: 11, offset: 1 }}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <label className="formLabel">Heading</label>
                      <Form.Item name="head" {...formItemLayout}>
                        <Input
                          size="large"
                          style={{ maxWidth: 678 }}
                          placeholder="type the podcast name"
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <label className="formLabel">Body</label>
                      <Form.Item
                        className="formLabel"
                        name="body"
                        {...formItemLayout}
                      >
                        <TextArea
                          rows={8}
                          placeholder=""
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <label className="formLabel">Podcast Link</label>
                      <Form.Item
                        className="formLabel"
                        name="link"
                        {...formItemLayout}
                      >
                        <Input
                          size="large"
                          style={{ maxWidth: 678 }}
                          placeholder="add podcast link"
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row >
                    <Col md={8}>
                    <label className="newsFormLabels">Tags</label>
                      <Form.Item name="tags">
                        <Select
                           mode="multiple"
                          filterOption={(input: any, option: any) =>
                            option?.children
                              ?.toLowerCase()
                              ?.indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {tags.length > 0 &&
                            tags.map((item: any, index: any) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.tag}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                    <Col md={10}>
                      <label className="formLabel">Perma Link</label>
                      <Form.Item
                        className="formLabel"
                        name="permalink"
                        {...formItemLayout}
                      >
                        <Input size="large"  />
                      </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 24 }}>
                      <Form.Item name="video">
                        <div
                          className="podcast-ImageUploader"
                          onClick={() => setImageUploader(true)}
                        >
                          <img
                            className="podcast-img1"
                            src={ImageUpload}
                            alt=""
                          />
                          <div className="podacast-Uploadtxt">
                            Upload Picture
                          </div>
                        </div>
                      </Form.Item>
                    </Row>
                    {imageurl ? (
                      <>
                        <img
                          style={{ width: "75%", height: "50%" }}
                          src={imageurl}
                        />
                      </>
                    ) : null}
                    <Row style={{ padding: 10 }}>
                      <div>
                        <Button
                          className="podcast-Btn"
                          htmlType="submit"
                          loading={loading}
                          type="primary"
                        >
                          save podcast
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        {imageUploader ? (
          <ImagePicker2
            onSubmit={(val: any) => addImageToList(val)}
            visible={imageUploader}
            close={() => setImageUploader(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default PodcastForm;
