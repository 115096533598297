import { Button, Modal, message } from "antd";
import Wheel from "../../../assets/image/wheel.png";
import TROPHY from "../../../assets/image/trophy.gif";
import { useState } from "react";
import { API } from "../../../config/API";
import { POST, PUT } from "../../../utils/apirequest";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

function SelectModal(props: any) {
  let { id } = useParams();
  
  const [winner, setWinner] = useState<any>(null);
  const [drawn, setDrawn] = useState(false);
  const [start, setStart] = useState(false);
  const [loading, setLoading] = useState(false);

  const drawStart = () => {
    setWinner(null);
    setStart(true);
  };

  const handleDraw = async () => {
    try {
      let obj = {
        type: props.type,
      };
      let url = API.QUIZZ_SELECT_WINNER;
      const res: any = await POST(url, obj);
      setWinner(res);
      setStart(false);
    } catch (err) {
      console.log(err);
    }
  };

  const confirmWinner = async () => {
    try {
      setLoading(true);
      let obj = {
        data: {
          winner_selected: true,
          winner_name: winner.winner_name,
          winner_phone: winner.winner_phone,
          winner_address: winner.winner_address,
        },
      };
      let url = API.QUIZZ_MASTER_EDIT + id;
      const res: any = await PUT(url, obj);
      if (res?.data.id) {
        message.success("Success");
        setLoading(false);
      } else {
        message.error("Something went wrong...!");
        setLoading(false);
      }
    } catch (err) {
      message.error("Something went wrong...!");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Modal
      title={<div>Select Winner</div>}
      open={props?.visible}
      onCancel={props?.onCancel}
      footer={false}
      width={500}
    >
      <div className="SelectModal-Box">
        {winner ? (
          <div>
            <img src={TROPHY} className={"SelectModal-wheel1"} />
          </div>
        ) : (
          <>
            <img
              src={Wheel}
              className={start ? "SelectModal-wheel2" : "SelectModal-wheel1"}
            />
            <Button
              danger
              size="large"
              block
              onClick={() => (start ? handleDraw() : drawStart())}
              disabled={drawn}
            >
              {start ? "Stop" : "Draw Winner"}
            </Button>
          </>
        )}
        {winner && (
          <div
            style={{
              border: "1px solid grey",
              padding: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <h4>{winner.winner_name}</h4>
            <p style={{ marginBottom: 0 }}>{winner.winner_phone}</p>
            <p style={{ marginBottom: 0 }}>{winner.winner_address}</p>
          </div>
        )}
      </div>
      <br />
      <Row>
        <Col xs="4"></Col>
        <Col xs="3">
          <Button block size="large" onClick={() => props?.onCancel()}>
            Close
          </Button>
        </Col>
        <Col xs="5">
          <Button
            block
            size="large"
            type="primary"
            loading={loading}
            onClick={() => confirmWinner()}
            disabled={!winner}
          >
            Confirm Winner
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default SelectModal;
