import { Button, Form, Input, Modal, Select } from "antd";
import { Col, Row } from "react-bootstrap";

export default function MediaJoin(props: any) {
  const Joinmedia = () => {
    const [form] = Form.useForm();

    const media = [
      { name: "Whatsapp" },
      { name: "Instagram" },
      { name: "Telegram" },
    ];

    const onFinish = (val: any) => {
      props.onSubmit(val);
      props.close();
    };

    return (
      <main style={{ width: "100%" }}>
        <Row>
          <Form form={form} onFinish={onFinish}>
            <Col md={24}>
              <div className="newsFormLabels">media</div>
              <Form.Item name="media">
                <Select style={{ background: "transparent" }}>
                  {media.map((item: any, index: any) => {
                    return (
                      <Select.Option key={index} value={item.name}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="newsFormLabels">Text</div>
              <Form.Item name="Text">
                <Input />
              </Form.Item>
              <div className="newsFormLabels">Link</div>
              <Form.Item name="Link">
                <Input />
              </Form.Item>
              <Button
                className="hmScrn-SaveEditBtn"
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Form>
        </Row>
      </main>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      style={{ top: 20 }}
      width={500}
      footer={false}
    >
      <Joinmedia />
    </Modal>
  );
}
