import "./styles.css";

import { IoIosInformationCircleOutline } from "react-icons/io";
import PageHeader from "../../component/pageHeaders";
import Home from "./screens/home";
import Details from "./screens/details";
import { Tabs, TabsProps } from "antd";
import Editorial from "./screens/editorial";
import EditorialDetails from "./screens/editorialDetailed";
import Articles from "./screens/articles";
import ArticlesDetailed from "./screens/articleDetailed";
import Njayar from "./screens/njayar";
import NjayarDetailed from "./screens/njayarDetailed";
import Vidya from "./screens/vidya";
import VidyaDetailed from "./screens/vidyaDetailed";
import Search from "./screens/searchScreen";
import Podcast from "./screens/podcast";
import Quiz from "./screens/quiz";
import ReadMore from "./screens/readMore";

function AddScreen() {
  const onChange = (key: string) => {};

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Home",
      children: <Home />,
    },
    {
      key: "2",
      label: "News Details",
      children: <Details />,
    },
    {
      key: "13",
      label: "Quiz",
      children: <Quiz />,
    },
    {
      key: "3",
      label: "Editorial",
      children: <Editorial />,
    },
    {
      key: "4",
      label: "Editorial Details",
      children: <EditorialDetails />,
    },
    {
      key: "5",
      label: "Todays Article",
      children: <Articles />,
    },
    {
      key: "6",
      label: "Todays Article Details",
      children: <ArticlesDetailed />,
    },
    {
      key: "7",
      label: "Vidya Prabhaatham",
      children: <Vidya />,
    },
    {
      key: "8",
      label: "Vidya Prabh. Details",
      children: <VidyaDetailed />,
    },
    {
      key: "9",
      label: "Njayar Prabhaatham",
      children: <Njayar />,
    },
    {
      key: "10",
      label: "Njayar Prabh. Details",
      children: <NjayarDetailed />,
    },
    {
      key: "11",
      label: "Search",
      children: <Search />,
    },
    {
      key: "12",
      label: "Podcast",
      children: <Podcast />,
    },
    {
      key: "14",
      label: "Read More",
      children: <ReadMore />,
    },
  ];

  return (
    <div className="AddScreen">
      <PageHeader
        title={"Manage Adds"}
        length={"Add and Remove adds in entire website"}
      >
        <div style={{ fontSize: 10, maxWidth: 400 }}>
          <IoIosInformationCircleOutline size={20} color="blue" />In this screen, it represents the actual position of ads on the news website. You can edit all positions and update images OR Google AdSense tags.
        </div>
      </PageHeader>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ paddingLeft: 10, paddingRight: 10, fontWeight: "900" }}
        items={items}
        onChange={onChange}
      />
      <br />
      <br />
    </div>
  );
}

export default AddScreen;
