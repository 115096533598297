import { Button, Popconfirm, Space, Switch, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { LuUndo2 } from "react-icons/lu";

function DataTable(props: any) {
  interface DataType {
    id: number;
    title: string;
    type: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.type}
          </div>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      width: 50,
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record?.is_deleted === true ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => props?.onDelete(record)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default DataTable;
