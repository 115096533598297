import { Table } from "react-bootstrap";
import { Input, Button, Form, Select } from "antd";
import ImagePicker from "./imagePicker";
import { useEffect, useState } from "react";

function CandidateForm(props: any) {
  const [images, setImages] = useState({}) as any;

  useEffect(() => {
    const initialImages =
      props.form.getFieldValue([props.key_name, "data"]) || [];
    setImages(
      initialImages.reduce((acc: any, item: any, index: number) => {
        acc[index] = item?.image;
        return acc;
      }, {})
    );
  }, [props.form, props.key_name]);

  const handleImageChange = (value: any, index: number) => {
    setImages((prevImages: any) => ({
      ...prevImages,
      [index]: value,
    }));
    props.form.setFieldsValue({
      [props.key_name]: {
        data: props.form
          .getFieldValue([props.key_name, "data"])
          .map((item: any, idx: number) => {
            if (idx === index) {
              return { ...item, image: value };
            }
            return item;
          }),
      },
    });
  };

  return (
    <div>
      <label className="newsFormLabels">Candidates</label>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th>Image</th>
            <th>Candidate Name</th>
            <th>Location</th>
            <th>Party</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <Form.List
            name={[props?.key_name, "data"]}
            initialValue={[{ party: "" }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <tr key={key}>
                    <td>{index + 1}</td>
                    <td style={{ padding: 0 }}>
                      <ImagePicker
                        image={images[index]}
                        onChagne={(value: any) =>
                          handleImageChange(value, index)
                        }
                      />
                    </td>
                    <td style={{ width: "30%" }}>
                      <Form.Item
                        noStyle
                        name={[name, "name"]}
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
                          bordered={false}
                          placeholder="Enter name"
                          style={{ marginBottom: -10 }}
                        />
                      </Form.Item>
                    </td>
                    <td style={{ width: "30%" }}>
                      <Form.Item
                        noStyle
                        name={[name, "location"]}
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
                          bordered={false}
                          placeholder="Enter location"
                          style={{ marginBottom: -10 }}
                        />
                      </Form.Item>
                    </td>
                    <td style={{ width: "20%" }}>
                      <Form.Item noStyle name={[name, "party"]}>
                        <Select
                          bordered={false}
                          style={{ width: "100%" }}
                          placeholder={"Select Party"}
                          loading={props.loading}
                        >
                          {props?.party?.options &&
                            props?.party?.options?.map((item: any) => {
                              return (
                                <Select.Option key={item.party}>
                                  {item.party}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ width: "10%", padding: 0 }}>
                      <Form.Item noStyle name={[name, "status"]}>
                        <Select
                          bordered={false}
                          placeholder="Status"
                          style={{ width: "100%" }}
                        >
                          <Select.Option key={"മുന്നിൽ"}>മുന്നിൽ</Select.Option>
                          <Select.Option key={"പിന്നിൽ"}>പിന്നിൽ</Select.Option>
                          <Select.Option key={"വിജയിച്ചു"}>
                            വിജയിച്ചു
                          </Select.Option>
                          <Select.Option key={"പരാജയപ്പെട്ടു"}>
                            പരാജയപ്പെട്ടു
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <Button
                        block
                        size="small"
                        onClick={() => remove(index)}
                        danger
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6}></td>
                  <td>
                    <Button
                      block
                      size="small"
                      onClick={() => add()}
                      style={{ backgroundColor: "green", color: "#fff" }}
                    >
                      Add +
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </Form.List>
        </tbody>
      </Table>
    </div>
  );
}

export default CandidateForm;
