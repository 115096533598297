import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles.css";
import Picker from "../components/picker";
import { API } from "../../../config/API";
import { POST } from "../../../utils/apirequest";
import { IoNewspaperOutline } from "react-icons/io5";
const ReadMore = () => {
  const [section1, setSection1] = useState({}) as any;
  const [section2, setSection2] = useState({}) as any;
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "readmore",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find((item: any) => item.section === "section1");
        let sec2 = res.data.find((item: any) => item.section === "section2");
        setSection1(sec1);
        setSection2(sec2);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={9}>
          <Row>
            <Col md={6}>
              <div className="addScreen-box" style={{ height: 300 }}>
                <IoNewspaperOutline size={30} color="#0055a6" /> &nbsp;News 1
              </div>
              <div
                className="addScreen-box"
                style={{ height: 500, margin: "20px 0px" }}
              >
                <IoNewspaperOutline size={30} color="#0055a6" /> &nbsp;Related
                News
              </div>
            </Col>
            <Col md={6}>
              <Picker
                value={section2}
                screen={"readmore"}
                field={"section2"}
                title={"Top Screen"}
                desc={"This add will Show above header"}
                style={{ height: 300 }}
                refresh={() => loadData()}
              />
              <br />
              <div className="addScreen-box" style={{ height: 500 }}>
                <IoNewspaperOutline size={30} color="#0055a6" /> &nbsp;Related
                News
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <div
            className="addScreen-box"
            style={{ height: 500, margin: "0px 0px 20px 0px" }}
          >
            <IoNewspaperOutline size={30} color="#0055a6" /> &nbsp;Related News
          </div>
          <Picker
            value={section1}
            screen={"readmore"}
            field={"section1"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 300 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default ReadMore;
