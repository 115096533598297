import { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import { Col, Container, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loadingbox from "../../component/loadingbox";
import { Button, Form, Input, Select, message } from "antd";
import { useParams } from "react-router-dom";
import { LoadTags } from "../../utils/commonapis";
import { API } from "../../config/API";
import { GET, POST, PUT } from "../../utils/apirequest";
import ImagePicker from "../framesScreen/components/frameImages";
import PartyItems from "./components/partyItems";

function ElectionForm() {
  const [form] = Form.useForm();
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState<any>(true);
  const [isLoading1, setIsLoading1] = useState<any>(false);
  const [isLoading2, setIsLoading2] = useState<any>(true);
  const [type, setType] = useState(null) as any;
  const [description, setDescription] = useState<any>(null);
  const [image, setImage] = useState("");
  const [data, setData] = useState({}) as any;
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    if (id !== "create") {
      getData();
    } else {
      setIsLoading(false);
    }
    loadTags();
  }, []);

  const getData = async () => {
    try {
      let url = API.ELECTION_MASTER_DETAILS + id + `?populate[0]=tags`;
      let res: any = await GET(url, null, null);
      if (res?.data) {
        let data = res?.data?.attributes;
        console.log("data", data);
        setData(data);
        setDescription(data?.description);
        setImage(data?.image);
      }
    } catch (err) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const loadTags = async () => {
    try {
      setIsLoading2(true);
      const res: any = await LoadTags();
      setTags(res);
    } catch (error) {
    } finally {
      setIsLoading2(false);
    }
  };

  const Finished = async (type: any) => {
    try {
      setIsLoading1(true);
      setType(type);
      form.submit();
      await form.validateFields();
      let values = form.getFieldsValue();
      var requestObj = {
        data: {
          title: values?.title,
          head: values?.head,
          image: image,
          permalink: values?.permalink,
          tags: values?.tags,
          description: description,
          parties: values?.parties,
          publishedAt:
            type === "publish" ? new Date() : data?.publishedAt || null,
        },
      };
      console.log("values", values);
      let url =
        id !== "create"
          ? API.ELECTION_MASTER_EDIT + id
          : API.ELECTION_MASTER_ADD;
      let METHOD = id !== "create" ? PUT : POST;
      let res: any = await METHOD(url, requestObj);
      if (res?.data?.id) {
        message.success(
          `Campaign ${id !== "create" ? "Updated" : "Created"} Successfully.`
        );
        setTimeout(() => {
          window.location.replace(`/Auth/election/${res?.data?.id}`);
        }, 200);
      }
    } catch (err) {
      console.log(err);
      setIsLoading1(false);
    }
  };

  return (
    <div style={{ height: "95vh", overflow: "scroll" }}>
      <PageHeader title={"Election"} length={"Add New Campaign"} />
      <br />
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div>
            <Form
              form={form}
              initialValues={{
                title: data?.title,
                head: data?.head,
                permalink: data?.permalink,
                description: data?.description,
                tags: data?.tags?.data?.map((item: any) => item?.id),
                image: image,
                parties: data.parties,
              }}
            >
              <Row>
                <Col sm={3}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item
                    name={"title"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Heading</label>
                  <Form.Item
                    name={"head"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Perma-Link</label>
                  <Form.Item
                    name={"permalink"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Tags</label>
                  <Form.Item
                    name="tags"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      style={{ background: "transparent" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      loading={isLoading2}
                    >
                      {tags.length &&
                        tags.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item.id}>
                              {item.tag}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={5}>
                  <label className="newsFormLabels">Description</label>
                  <ReactQuill
                    style={{ height: "115px", borderRadius: 5 }}
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                  <br />
                  <br />
                  <Row>
                    <Col sm={4}>
                      <label className="newsFormLabels">Image</label>
                      <ImagePicker
                        image={image}
                        onChagne={(value: any) => setImage(value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <label className="newsFormLabels">Parties</label>
                  <PartyItems />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Row>
                    <Col sm={6}>
                      <Button
                        size="large"
                        block
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "save"}
                        onClick={() => Finished("save")}
                      >
                        {id !== "create" ? "Update" : "Save"}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        disabled={data?.publishedAt ? true : false}
                        size="large"
                        block
                        type="primary"
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "publish"}
                        onClick={() => Finished("publish")}
                      >
                        Publish
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
            </Form>
          </div>
        )}
      </Container>
    </div>
  );
}

export default ElectionForm;
