import { useState, useEffect } from "react";
import PageHeader from "../../component/pageHeaders";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/API";
import axios from "axios";
import { CloseCircleOutlined } from "@ant-design/icons";
import Loadingbox from "../../component/loadingbox";
import InSnapDataTable from "./component/datatable";
import { PUT } from "../../utils/apirequest";
import { Button, DatePicker, Input, Tag, message } from "antd";
import moment from "moment";
function InSnap() {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  const navigate = useNavigate();

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      let url = API.BASE_URL + API.GET_INSNAP;
      axios
        .post(url, filter)
        .then((response) => {
          let imageData = response.data.data;
          let metaData = response.data?.totalCount;
          setData(imageData);
          setPublishCount(response.data?.publishedCount);
          setTrashcount(response.data?.trashedCount);
          setMeta(metaData);
          setIsLoading(false);
          setIndicator(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } catch (error: any) {
      console.log(error);
    }
  };

  const editSnap = (value: any) => {
    navigate("/Auth/insnap-form", { state: { item: value } });
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadPublished = () => {
    let filters = {
      ...filter,
      type: "published",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadTrashed = () => {
    let filters = {
      ...filter,
      type: "trash",
    };
    setFilter(filters);
    loadData(filters);
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };
  const CategoryChange = (category: any) => {
    let filters = {
      ...filter,
      catId: category,
    };
    setFilter(filters);
    loadData(filters);
  };

  const addToTrash = async (val: any) => {
    let url = API.INSNAP + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: true,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };
  const UndoTrash = async (val: any) => {
    let url = API.INSNAP + `/${val.id}`;
    let obj = {
      data: {
        is_deleted: false,
      },
    };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully Recovered from trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      <PageHeader title={"In-Snap"} length={meta?.total} loader={indicator}>
        <div className="homescreen-pageItem">
          <div style={{ width: 250 }}>
            <Input
              allowClear
              placeholder="Search inSnap "
              onChange={(e: any) => Search(e.target.value)}
            />
          </div>
          &nbsp;
          <div className="homescreen-pageBox">
            <div
              className="homescreen-pageItem"
              onClick={() => LoadPublished()}
            >
              <span style={{ color: "green" }}>Published</span> : &nbsp;
              <b>{publishCount}</b>
            </div>
            |
            <div className="homescreen-pageItem" onClick={() => LoadTrashed()}>
              <span style={{ color: "red" }}>Trashed</span> : &nbsp;
              <b>{trashcount}</b>
            </div>
          </div>
          &nbsp;
          <Button type="primary" onClick={() => navigate("/Auth/insnap-form")}>
            Add New +
          </Button>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="homescreen-pageItem">
        <div
          style={{
            flex: 5,
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(filter)?.length > 2 ? "Filter By :" : "All InSnaps"}{" "}
          &nbsp;
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag style={{ marginBottom: 5 }}>
                  {`${key}:  ${
                    key === "createdAt"
                      ? moment(filter[key]).format("DD-MM-YYYY")
                      : filter[key]
                  }`}{" "}
                  <CloseCircleOutlined onClick={() => clearFilters(key)} />
                </Tag>
              )
          )}
        </div>
        <div style={{ flex: 1 }}>
          <DatePicker
            allowClear
            style={{ width: 200 }}
            onChange={onChangeDate}
          />
        </div>
      </div>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <InSnapDataTable
          meta={meta}
          data={data}
          page={page}
          pagesize={pageSize}
          onPageChange={(page: any, pageSize: any) =>
            onPageChange(page, pageSize)
          }
          onTrash={(val: any) => addToTrash(val)}
          unTrash={(val: any) => UndoTrash(val)}
          refresh={() => loadData(filter)}
          onEdit={(val: any) => editSnap(val)}
        />
      )}
    </div>
  );
}

export default InSnap;
