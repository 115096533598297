import { useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import { Row, Col, Container } from "react-bootstrap";
import Logo from "../../assets/image/logo_light.png";
import "./styles.css";
import { POST } from "../../utils/apirequest";
import { API } from "../../config/API";
import Lockmodal from "../../component/lock/index";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/userslice";
import moment from "moment";

const LoginScreen = (props: any) => {
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, SetOtp] = useState(false);
  const [lockmodal, setlockModal] = useState(false);
  const [loginattempts, setloginattempts] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [LoginRes, setLoginRes] = useState<any>({});

  let userDesc = localStorage.getItem("userlist");
  let userlistData: any = [];

  if (userDesc !== null) {
    let user = JSON.parse(userDesc);
    userlistData = user;
  }

  let uniqueUserlistData = userlistData?.filter(
    (value: any, index: any, self: any) => {
      return (
        index === self.findIndex((obj: { id: any }) => obj.id === value.id)
      );
    }
  );

  const onFinish = async (values: any) => {
    try {
      setloginattempts((preveattempts: number) => preveattempts + 1);
      setLoading(true);
      let body = {
        email: values.email,
        password: values.password,
        otp: values.otp,
      };
      if (otp) await verifyOTP(body);
      else await validateUser(body);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong......!");
    }
  };

  const verifyOTP = async (body: any) => {
    let url = API.VERIFY_OTP;
    const response: any = await POST(url, body);
    const message = response.status === 200 ? "success" : "error";
    notification[message]({
      message: message,
      description: response.message,
    });
    if (LoginRes.status === 200 && response.status === 200) {
      let data = {
        ...LoginRes,
      };
      let obj = {
        id: data.id,
        email: data.email,
      };
      await userlistData.push(obj);
      localStorage.setItem("userlist", JSON.stringify(userlistData));
      dispatch(login(data));
      const redirectPath = localStorage.getItem("redirectPath") || "/Auth";
      localStorage.removeItem("redirectPath");
      navigate(redirectPath, { replace: true });
      // navigate("/Auth");
    }
  };
  const calculateDuration = (value: any) => {
    if (value) {
      const currentTime: any = new Date();
      const endTimeParts = value.split(":");
      const endDate: any = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        parseInt(endTimeParts[0], 10),
        parseInt(endTimeParts[1], 10)
      );
      const timeDifference = endDate - currentTime;
      const durationInMinutes = Math.floor(timeDifference / 60000);
      const remainingSeconds = Math.floor((timeDifference % 60000) / 1000);
      return { minutes: durationInMinutes, seconds: remainingSeconds };
    }
  };

  const setTime = () => {
    const lockoutData: any = localStorage.getItem("loginLockoutData");
    const selectedDate = new Date(lockoutData);
    const formattedTime = moment(selectedDate).format("HH:mm");
    const [hours, minutes] = formattedTime.split(":").map(Number);
    const newMinutes = (minutes + 3) % 60;
    const newHours = hours + Math.floor((minutes + 3) / 60);
    const newTime = `${String(newHours).padStart(2, "0")}:${String(
      newMinutes
    ).padStart(2, "0")}`;
    var mishab: any = calculateDuration(newTime);
    return mishab;
  };

  const validateUser = async (body: any) => {
    let url = API.LOGIN;
    const response: any = await POST(url, body);
    if (response.status) {
      const timeOut = setTime();
      if (response.blocked === true) {
        setlockModal(true);
        setErrorMessage(
          `Your account login has been blocked. Please find an alternative way to access it.`
        );
      } else if (response.status === 404) {
        notification["error"]({
          message: "error",
          description: response.message,
        });
        if (loginattempts > 3) {
          setlockModal(true);
          setErrorMessage(
            `Maximum allowable login attempts 3 have exceeded. Your account is unavailable. Please try again in 30  minute.`
          );
          const currentTime = new Date();
          const lockoutDateString = currentTime.toISOString();
          localStorage.setItem("loginLockoutData", lockoutDateString);
        }
      } else {
        SetOtp(response.status === 200);
        setLoginRes(response.status === 200 ? response : {});
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="loginSection">
      <Container>
        <Row>
          <Col sm={4} md={4}></Col>
          <Col sm={4} md={4} xs={12}>
            <div className="loginSection-box">
              <div className="loginSection-logobox">
                <img src={Logo} className="loginSection-logo" />
              </div>
              <br />
              <br />
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>E-mail</div>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  {userlistData.length ? (
                    <Select style={{ width: "100%" }} mode="tags" size="large">
                      {uniqueUserlistData.length > 0 &&
                        uniqueUserlistData.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item?.email}>
                              {item?.email}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Input style={{ width: "100%" }} size="large" />
                  )}
                </Form.Item>
                <div>Password</div>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      message: "Please input your password!",
                      required: true,
                    },
                  ]}
                >
                  <Input.Password style={{ width: "100%" }} size="large" />
                </Form.Item>
                {otp ? (
                  <>
                    <div>OTP</div>
                    <Form.Item
                      label=""
                      name="otp"
                      rules={[
                        {
                          message: "Please input your OTP!",
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </>
                ) : null}
                <Form.Item name={"remember"}>
                  <Checkbox onChange={() => console.log("Checkbox")}>
                    <div>Remember Me</div>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    block
                    style={{ height: 50 }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col sm={4} md={4}></Col>
        </Row>
      </Container>
      {lockmodal ? (
        <Lockmodal
          visible={lockmodal}
          close={() => setlockModal(false)}
          data={errorMessage}
        />
      ) : null}
    </div>
  );
};

export default LoginScreen;
