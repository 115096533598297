import {
  Card,
  Space,
  Table,
  Tag,
  Popconfirm,
  message,
  Pagination,
  Tooltip,
  Button,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import { API } from "../../../config/API";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { IoCopyOutline, IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import { IoMdOpen } from "react-icons/io";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  const navigate = useNavigate();

  const onEdit = (id: any, item: any) => {
    navigate(`/Auth/NjayarScreen/njayarArticles/${id}`, {
      state: { items: item },
    });
  };
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  // const generateLink = (record: any, type: any) => {
  //   let link = record?.permalink?.replace(/\s+/g, "-");
  //   let url = `${API.APP_URL}njayarprabhaatham?id=${record.id}&link=${link}`;
  //   let body = `${record?.head} \nRead more at: ${url} ${
  //     props?.settings?.whatsappJoinLink
  //       ? `\nStay updated with the latest news! Join our WhatsApp group: ${props?.settings?.whatsappJoinLink}`
  //       : null
  //   }`;
  //   if (type === "copy") {
  //     navigator.clipboard.writeText(body).then(() => {
  //       message.success("link copied");
  //     });
  //   } else if (type === "preview") {
  //     window.open(url, "_blank");
  //   }
  // };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 500,
      render: (text, record: any) => {
        return (
          <div
            onClick={() => onEdit(record.id, record)}
            style={{ fontSize: "10px" }}
          >
            {record?.head}
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
      width: 300,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => onEdit(record.id, record)}
          >
            {record?.user?.username}
          </div>
        );
      },
    },
    {
      title: "Date-Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 12 }}
            onClick={() => onEdit(record.id, record)}
          >
            {moment(record?.createdAt).format("DD-MM-YYYY-h:mm a")}
          </div>
        );
      },
    },
    {
      title: "tags",
      dataIndex: "tags",
      key: "tags",
      width: 150,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => onEdit(record.id, record)}
          >
            {record?.tags.map((item: any) => {
              return <Tag style={{ fontSize: 10 }}>{item?.tag}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      width: 300,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record?.publishedAt ? "green" : "red"}
              style={{ fontSize: "10px" }}
            >
              {record?.publishedAt ? "Published" : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      width: 200,
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            {/* {record.publishedAt && (
              <>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoMdOpen
                    color="#666"
                    cursor={"pointer"}
                    size={20}
                    onClick={() => generateLink(record, "preview")}
                  />
                </Space>
                <Space size="middle" style={{ marginRight: 10 }}>
                  <IoCopyOutline
                    size={20}
                    color="green"
                    cursor={"pointer"}
                    onClick={() => generateLink(record, "copy")}
                  />
                </Space>
              </>
            )} */}
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => onEdit(record.id, record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record?.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteItem(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.NJAYAR_MASTER + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  return (
    <div className="mt-2">
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props?.meta}
          pageSize={props.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
