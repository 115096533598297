import  { useState } from "react";
import { Button, Modal } from "antd";
import "../styles.css";
import Wheel from "../../../assets/image/wheel.png";
import TROPHY from "../../../assets/image/trophy.gif";

const SelectModal = (props: any) => {
  const [winner, setWinner] = useState<any>(null);
  const [drawn, setDrawn] = useState(false);
  const [start, setStart] = useState(false);

  const drawStart = () => {
    setWinner(null);
    setStart(true);
  };

  const handleDraw = () => {
    if (props?.winners?.length === 0) {
      alert("No participants available for draw.");
      return;
    } else {
      const randomIndex = Math.floor(Math.random() * props?.winners?.length);
      const selectedWinner: any = props?.winners?.[randomIndex];
      setWinner(selectedWinner);
      setStart(false);
    }
  };

  return (
    <Modal
      title={<div>Total Winners = {props?.winners?.length}</div>}
      open={props?.visible}
      onCancel={props?.onCancel}
      footer={false}
      width={500}
    >
      <div className="SelectModal-Box">
        {winner ? (
          <img src={TROPHY} className={"SelectModal-wheel1"} />
        ) : (
          <>
            <img
              src={Wheel}
              className={start ? "SelectModal-wheel2" : "SelectModal-wheel1"}
            />
            <Button
              danger
              size="large"
              block
              onClick={() => (start ? handleDraw() : drawStart())}
              disabled={drawn}
            >
              {start ? "Stop" : "Draw Winner"}
            </Button>
          </>
        )}

        {winner && (
          <div style={{ border: "1px solid grey", padding: 10, marginTop: 20,width:"100%" }}>
            <h4>{winner.name}</h4>
            <p style={{ marginBottom: 0 }}>{winner.phone}</p>
            <p style={{ marginBottom: 0 }}>{winner.address}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SelectModal;
