import Dropzone from "react-dropzone";
import { Button, Modal, Spin, message } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import { UploadApi } from "../helpers/compressUpload";
function UpdateImage(props: any) {
  const [isloading, setIsloading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const uploadImage = async (val: any) => {
    try {
      setIsloading(true);
      const file = val[0];
      if (file?.type === "image/jpeg" || file?.type === "image/png") {
        let fileName = props.data.name;
        let editon = props?.editon;
        let date = props?.date;
        const upload: any = await UploadApi(file, fileName, 0, 4, editon, date);
        setImageUrl(upload?.image_url);
      } else {
        message.error("File Format Not supported");
      }
      setIsloading(false);
    } catch (error) {
      console.log(error);
      setIsloading(false);
      message.error("File Format Not supported");
    }
  };

  return (
    <Modal open={props.open} onCancel={props.onCancel} footer={false}>
      {props.data.name}
      <Dropzone onDrop={uploadImage}>
        {({ getRootProps, getInputProps }) => (
          <section className="Epaper-ThumbnailPicker">
            <div className="Epaper-ThumbnailPickerBox">
              {isloading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ) : null}
            </div>
            <div
              {...getRootProps({
                className: `ImagePicker-backgroud2 ${
                  props?.size ? "small" : ""
                }`,
              })}
            >
              <input {...getInputProps()} />
              {imageUrl ? (
                <img src={imageUrl} className="Epaper-ThumbnailImage" />
              ) : (
                <div className="Epaper-PagesListBox">
                  {isloading ? (
                    <div>Loading . . .</div>
                  ) : (
                    <FiUploadCloud size={30} />
                  )}
                  <div>Upload Thumbnail</div>
                  <div style={{ fontSize: 8 }}>only PNG/JPEG *</div>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      <br />
      <Row>
        <Col xs="6" sm="6"></Col>
        <Col xs="6" sm="3">
          <Button size="large" block onClick={() => props.onCancel()}>
            Cancel
          </Button>
        </Col>
        <Col xs="6" sm="3">
          <Button
            size="large"
            block
            type="primary"
            onClick={() => props.onChange(imageUrl)}
          >
            Update
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default UpdateImage;
