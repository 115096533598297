import React, { useState } from "react";
import PageHeader from "../../../component/pageheader";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Spin, notification } from "antd";
import { useLocation } from "react-router-dom";
import { API } from "../../../config/API";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/slices/userslice";
import { LoadingOutlined } from "@ant-design/icons";

function ChangePassword() {
  const [loading, setLoading] = useState<any>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = location?.state?.val?.id;
  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      let requestObj = {
        username: location?.state?.val?.username,
        email: location?.state?.val?.email,
        password: values.password,
        phone_number: location?.state?.val?.phone_number,
      };
      let url = API.BASE_URL + API.PUT_UPDATE_USER + `${userId}`;
      const response = await axios.put(url, requestObj);
      let role = location?.state?.val?.role;
      let data = {
        ...response.data,
        role: role,
        Password: values.password,
      };
      dispatch(login(data));
      notification.success({
        message: "Success",
        description: "Update your Password.",
      });
      setLoading(false);
      navigate("/Auth/profile");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const validatePassword = (rule: any, value: any, callback: any) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?]).{8,}$/.test(
      value
    )
      ? callback()
      : callback("Password Doesn't match the format specified!");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
  );
  return (
    <div>
      <PageHeader
        title={"Change Password"}
        onBtnPress={() => navigate("/")}
        goBack={() => navigate("/Auth/profile")}
      />
      <Form onFinish={onFinish}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row style={{ width: "70%", justifyContent: "space-between" }}>
            <Col md={12} xs={24}>
              <label>Current Password </label>
              <Form.Item
                name={"currentpassword"}
                style={{ marginRight: "10px" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your current password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const password = location?.state?.val?.Password;
                      if (!value || password === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(`please confirm your password`)
                      );
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <label>New Password </label>
              <Form.Item
                name={"password"}
                rules={[
                  { validator: validatePassword },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const password = getFieldValue(["currentpassword"]);
                      if (password != value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(`Try another password`));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item style={{ marginRight: "10px" }}>
                <Button
                  className="editProfile-Bttn"
                  onClick={() => navigate("/Auth/profile")}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Button
                type="primary"
                htmlType="submit"
                className="editProfile-Bttn"
              >
                {loading ? <Spin indicator={antIcon} /> : "Update"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default ChangePassword;
