import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../../component/pageheader";
import { useNavigate } from "react-router-dom";
import "../style.css";
import Loadingbox from "../../../component/loadingbox";

function InSnapImageList() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state.val;

  return (
    <>
      <PageHeader
        title={"More Image"}
        goBack={() => navigate("/Auth/inSnap")}
      />

      {data ? (
        <>
          <div className="inSnap-Main ">
            <div className="inSnap-Scroll">
              <div className="inSnap-title">{data.attributes.title}</div>
              <div className="inSnap-imgCardMain ">
                <div className="inSnap-imgCardLst">
                  <img
                    className="inSnap-img"
                    src={data.attributes.image}
                    alt=""
                  />
                </div>
                {data?.attributes?.imageList?.map((item: any, index: any) => {
                  return (
                    <>
                      <div className="inSnap-imgCard2">
                        <img className="inSnap-img" src={item} alt="" />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loadingbox />
      )}
    </>
  );
}

export default InSnapImageList;
