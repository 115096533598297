import React from "react";
import { ColumnsType } from "antd/es/table";
import {
  Space,
  Switch,
  Tag,
  Button,
  Tooltip,
  Popconfirm,
  Table,
  Image,
} from "antd";
import moment from "moment";
import { BiEdit } from "react-icons/bi";
import { IoShareSocialOutline, IoTrashOutline } from "react-icons/io5";
import { LuUndo2 } from "react-icons/lu";
import NoImg from "../../../assets/image/noImg.jpg";

function DataTable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "Image",
      key: "image",
      dataIndex: "image",
      width: 20,
      render: (text, record: any) => {
        return (
          <Image
            width={40}
            height={40}
            style={{ borderRadius: 5 }}
            src={record?.attributes?.image}
            fallback={NoImg}
          />
        );
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 400,
      render: (text, record: any) => {
        return (
          <div onClick={() => props.onEdit(record)}>
            {record?.attributes?.title}
          </div>
        );
      },
    },
    {
      title: "head",
      dataIndex: "head",
      key: "head",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            onClick={() => props.onEdit(record)}
            style={{ fontSize: "12px" }}
          >
            {record?.attributes?.title}
          </div>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 60,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Switch
              onClick={() => props.toggleStatus(record)}
              checked={record?.attributes?.publishedAt}
            />
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      width: 60,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={
                record?.attributes?.is_deleted === true
                  ? "red"
                  : record?.attributes?.publishedAt
                  ? "green"
                  : "blue"
              }
              style={{ fontSize: "10px" }}
            >
              {record?.attributes?.is_deleted === true
                ? "Trash"
                : record?.attributes?.publishedAt
                ? "Published"
                : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "12px" }}>{moment(text).format("lll")}</div>
        );
      },
    },

    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div className="table-action">
            <div>
              <Button
                onClick={() => props.shareModal(record)}
                type="text"
                size="small"
              >
                <IoShareSocialOutline
                  size={20}
                  color="#000"
                  cursor={"pointer"}
                />
              </Button>
            </div>
            <div>
              <Button size="small" ghost onClick={() => props.onEdit(record)}>
                <BiEdit size={20} color="#f5a442" cursor={"pointer"} />
              </Button>
            </div>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.attributes?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.attributes?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => props?.onDelete(record)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default DataTable;
