import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../component/pageHeaders";
import { Button, message } from "antd";
import Loadingbox from "../../../component/loadingbox";
import DataTable from "./dataTable";
import { API } from "../../../config/API";
import { DELETE, GET, POST, PUT } from "../../../utils/apirequest";

function ElectionItem() {
  const navigate = useNavigate();
  let { EID } = useParams();

  const [isLoading, setIsloading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({masterId:EID}) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      setIndicator(true);
      let url = API.ELECTION_ITEMS_LIST;
      const res: any = await POST(url, filter);
      if (res?.data) {
        setData(res?.data);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsloading(false);
      setIndicator(false);
    }
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/election-items/${EID}/${val?.id}`);
  };

  const addToTrash = async (val: any, type: any) => {
    let url = API.ELECTION_ITEMS_EDIT + `${val.id}`;
    let obj = { data: { is_deleted: type } };
    let res: any = await PUT(url, obj);
    if (res?.data?.id) {
      message.success("Sucesssfully moved to trash");
      loadData(filter);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.ELECTION_ITEMS_EDIT + `${val.id}`;
      let response: any = await DELETE(url);
      if (response) {
        loadData(filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Election Items"} loader={indicator} length={meta}>
        <div className="homescreen-pageItem">
          <Button
            type="primary"
            onClick={() => navigate(`/Auth/election-items/${EID}/create`)}
          >
            New +
          </Button>
        </div>
      </PageHeader>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          page={page}
          pagesize={pageSize}
          onEdit={(val: any) => onEdit(val)}
          onTrash={(val: any) => addToTrash(val, true)}
          unTrash={(val: any) => addToTrash(val,false)}
          onDelete={(val: any) => onDelete(val)}
        />
      )}
    </div>
  );
}

export default ElectionItem;
