import * as React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles.css";
import Picker from "../components/picker";
import { API } from "../../../config/API";
import { POST } from "../../../utils/apirequest";
function Editorial() {
  const [section1, setSection1] = useState({}) as any;
  const [section2, setSection2] = useState({}) as any;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "editorial",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find(
          (item: any) => item.section === "section1"
        );
        let sec2 = res.data.find(
          (item: any) => item.section === "section2"
        );
        setSection1(sec1);
        setSection2(sec2);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={9}>
          <Row>
            <Col md={6}>
              <div className="addScreen-Related">Editorial </div>
              <div className="addScreen-Detaild">Related Editorial</div>
            </Col>
            <Col md={6}>
              <div className="addScreen-Detaild">Related Editorial</div>
              <Picker
                value={section1}
                screen={"editorial"}
                field={"section1"}
                title={"Top Screen"}
                desc={"This add will Show above header"}
                style={{ height: 300 }}
                refresh={() => loadData()}
              />
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <br />
          <Picker
            value={section2}
            screen={"editorial"}
            field={"section2"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 300 }}
            refresh={() => loadData()}
          />
          <div className="addScreen-Detaild">Related News</div>
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default Editorial;
