import React, { useCallback, useRef, useState } from "react";
import "./styles.css";
import { Button, Form, Modal, notification, Tabs } from "antd";
import { Col, Row } from "react-bootstrap";
import { IoImages, IoCloudUploadOutline, IoCrop } from "react-icons/io5";
import Cropper, { ReactCropperElement } from "react-cropper";

import Gallary from "./gallary";
import Picker from "./picker";
import Crop from "./crop";
import { API } from "../../config/API";
import axios from "axios";
import moment from "moment";

function ImagePicker(props: any) {
  const [form] = Form.useForm();
  const [tab, setTab] = useState<any>("gallary");
  const [CropVisible, setCropVisible] = useState(false);
  const hasWindow = typeof window !== "undefined";
  const height: any = hasWindow ? window.innerHeight : null;
  const [imageDetails, setimageDetails] = useState<any>();

  const [image, setImage] = useState<any>({});
  const cropperRef = useRef<ReactCropperElement>(null);
  const [loadingS3, setLoadingS3] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [sasUrl, setsasUrl] = useState<any>();
  const [media, setMedia] = useState<any>();
  const [medialibraryclick, setMedialibraryclick] = useState(false);
  const [uploadeAvailable, setUploadeAvailable] = useState(true);
  const [Notifications, contextHolder] = notification.useNotification();
  const type = props?.type;
  const update = "update";
  const [OnFinishLoading, setOnFinishLoading] = useState(false);
  const [Title, setTitle] = useState("");

  const onFinish = async (values: any) => {
    try {
      setOnFinishLoading(true);
      let info = {
        title: values?.title,
        captionn: values?.caption,
        discription: values?.description,
      };
      if (uploadeAvailable) {
        const response = await uploadImagesToS3(image.file, info);
        if (response) {
          let obj = {
            image_url: response?.data,
            type: props.imageType,
            image_desc: values?.description,
            image_Caption: values?.caption,
          };

          props.onSubmit(obj);
          if (props.imageType === "Media") {
            props.onSelect(obj);
          }

          setCropVisible(false);
          setMedialibraryclick(false);
          setOnFinishLoading(false);
          setDescription("");
          setImageUrl("");
          setImage("");
          setsasUrl("");
          setMedia("");
          props.close();
        }
      } else {
        let obj = {
          image_url: media,
          type: props.imageType,
          image_desc: values?.description,
          image_Caption: values?.caption,
        };
        if (props.imageType === "Media") {
          props.onSelect(obj);
        }
        props.onSubmit(obj);
        setCropVisible(false);
        setMedialibraryclick(false);
        setOnFinishLoading(false);
        setDescription("");
        setImageUrl("");
        setImage("");
        setsasUrl("");
        setMedia("");
        props.close();
      }
    } catch (error) {
      console.log(error);
      setOnFinishLoading(false);
    }
  };

  const handleDrop = (obj: any) => {
    setImage(obj ? obj : media);
    setTitle(obj?.file?.name);
    setimageDetails(obj ? obj : "");
    setImageUrl(media);
    setsasUrl(obj ? obj?.url : media);
    setUploadeAvailable(obj ? true : false);
  };

  const handleCrop = async () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob(async (blob) => {
          if (blob) {
            const fle = new File(
              [blob],
              `-${moment().unix()}-suprbhatham.jpg`,
              {
                type: "image/jpeg",
              }
            );
            setImage({
              file: fle,
            });
            const url = URL.createObjectURL(fle);
            setsasUrl(url);
            setUploadeAvailable(true);
            setTimeout(() => setTab("picker"), 300);
          }
        }, "image/jpeg");
      }
    }
  };

  const uploadImagesToS3 = async (file: any, info: any) => {
    let details = JSON.stringify(info);
    setLoadingS3(true);
    try {
      let data = new FormData();
      data.append("file", file);
      data.append("details", details);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: API.BASE_URL + API.IMAGE_UPLODER,
        data: data,
      };
      let response = await axios.request(config);
      console.log("s3 upload response -->", response);
      if (response) {
        setImageUrl(response.data);
        Notifications["success"]({
          message: "Success",
          description: `Uploaded Image Successfully`,
        });
      } else {
        Notifications["error"]({
          message: "Oops !",
          description: `Failed to upload image. Please try again`,
        });
      }
      setLoadingS3(false);
      return response;
    } catch (error) {
      Notifications["error"]({
        message: "Oops !",
        description: `Failed to upload image. Try with another image`,
      });
      console.log(error);
      setLoadingS3(false);
    }
  };

  const CustomTab = ({ elements }: any) => {
    return (
      <Tabs
        activeKey={tab}
        onChange={(tab) => setTab(tab)}
        tabBarStyle={{ backgroundColor: "white", paddingLeft: 10 }}
      >
        {elements.map((element: any) => (
          <Tabs.TabPane
            key={element.path}
            tab={element.tabTitle}
            style={{ minHeight: height - 200 }}
          >
            {element.tabBody}
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      centered
      width={"70vw"}
      footer={false}
      style={{ padding: -10, margin: -10 }}
    >
      <CustomTab
        elements={[
          {
            tabTitle: (
              <div>
                <IoImages size={17} /> &nbsp; Image Gallary
              </div>
            ),
            tabBody: (
              <Gallary
                setTab={setTab}
                handleDrop={handleDrop}
                setMedia={(val: any) => setMedia(val)}
                setUploadeAvailable={(val: boolean) => setUploadeAvailable(val)}
              />
            ),
            path: "gallary",
          },
          {
            tabTitle: (
              <div>
                <IoCloudUploadOutline size={17} /> &nbsp; Upload Image
              </div>
            ),
            tabBody: (
              <Picker
                title={Title}
                loading={OnFinishLoading}
                close={() => props.close()}
                onFinish={(val: any) => onFinish(val)}
                onChange={(file: any, info: any) => {
                  var myFile = file[0];
                  let name = moment(new Date()).unix();
                  const myNewFile = new File([myFile], myFile.name, {
                    type: myFile.type,
                  });
                  const url = URL.createObjectURL(myNewFile);
                  let obj = {
                    file: myNewFile,
                    url: url,
                  };
                  handleDrop(obj);
                }}
                fileURL={
                  image?.url
                    ? image.url
                    : type == update
                    ? props?.data?.image
                    : media
                }
                sasUrl={sasUrl}
                type="medialibrary"
                tab={tab}
              />
            ),
            path: "picker",
          },
          {
            tabTitle: (
              <div onClick={() => setCropVisible(true)}>
                <IoCrop size={17} /> &nbsp; Crop Image
              </div>
            ),
            tabBody: (
              <Crop
                setUploadeAvailable={(val: boolean) => setUploadeAvailable(val)}
                cropperRef={cropperRef}
                image={image}
                handleCrop={handleCrop}
              />
            ),
            path: "crop",
          },
        ]}
      />
      {tab === "picker" ? null : (
        <Row>
          <Col sm={6} xs={12} />
          <Col sm={6} xs={12}>
            <Row>
              <Col sm={6} xs={12} />
              <Col sm={6} xs={12}>
                <Button size="large" block danger onClick={() => props.close()}>
                  Close
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default ImagePicker;
