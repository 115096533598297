import Dropzone from "react-dropzone";
import { Spin, message } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { useState } from "react";
import { convertApi } from "../helpers/compressUpload";
import { LoadingOutlined } from "@ant-design/icons";

function ThumbnailPicker(props: any) {
  const [isloading, setIsloading] = useState(false);

  const uploadImage = async (val: any) => {
    try {
      setIsloading(true);
      const file = val[0];
      if (file?.type === "application/pdf") {
        if (props?.edition) {
          let editon = props?.edition;
          let date = props?.date;
          const up: any = await convertApi(file, "thumb", 0, 1, editon, date);
          props?.onChagnge(up?.image_url);
        } else {
          message.error("Select Edition");
        }
      } else {
        props?.onChagnge(null);
      }
      setIsloading(false);
    } catch (error) {
      console.log(error);
      setIsloading(false);
      message.error("File Format Not supported");
    }
  };

  return (
    <Dropzone onDrop={uploadImage}>
      {({ getRootProps, getInputProps }) => (
        <section className="Epaper-ThumbnailPicker">
          <div className="Epaper-ThumbnailPickerBox">
            {isloading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
              />
            ) : null}
          </div>
          <div
            {...getRootProps({
              className: `ImagePicker-backgroud2 ${props?.size ? "small" : ""}`,
            })}
          >
            <input {...getInputProps()} />
            {props.fileURL ? (
              <img src={props.fileURL} className="Epaper-ThumbnailImage" />
            ) : (
              <div className="Epaper-PagesListBox">
                {isloading ? (
                  <div>Loading . . .</div>
                ) : (
                  <FiUploadCloud size={30} />
                )}
                <div>Upload Thumbnail</div>
              </div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
}

export default ThumbnailPicker;
