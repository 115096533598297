import React from "react";
import type { ColumnsType } from "antd/es/table";
import { Pagination, Card, Space, Table, Popconfirm, message } from "antd";
import { IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import axios from "axios";
import { API } from "../../../config/API";
import { BiEdit } from "react-icons/bi";

function CategoryDataTable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Category",
      dataIndex: "attributes",
      key: "category",
      width: 500,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px", cursor: "pointer" }}
            onClick={() => props.onEdit(record)}
          >
            {record.category}
          </div>
        );
      },
    },
    {
      title: "CreatedAt",
      dataIndex: "attributes",
      key: "createdAt",
      width: 500,
      render: (text, record: any) => {
        const createdAt = moment(record.createdAt).format("LL");
        return <div style={{ fontSize: "10px" }}>{createdAt}</div>;
      },
    },
    {
      title: "publishedAt",
      dataIndex: "attributes",
      key: "publishedAt",
      width: 500,
      render: (text, record: any) => {
        const PublishedAt = moment(record.publishedAt).format("LL");
        return <div style={{ fontSize: "10px" }}>{PublishedAt}</div>;
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle"></Space>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteData(record.id)}
            >
              <Space size="middle">
                <IoTrashOutline size={20} color="red" cursor={"pointer"} />
              </Space>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const deleteData = (id: any) => {
    let url = API.BASE_URL + API.CATEGORY + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        props.refresh();
      })
      .catch((error) => {
        console.log("======delte--error====", error);
      });
  };
  return (
    <div>
      <br />
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ textAlign: "end" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props?.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
      <br />
    </div>
  );
}

export default CategoryDataTable;
