import { Pagination, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

function Datatable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Preview",
      key: "image",
      dataIndex: "image",
      width: 20,
      render: (text, record: any) => {
        return (
          <div>
            {record?.image ? (
              <img
                key={record?.image}
                src={record?.image}
                alt="Table Image"
                style={{ height: 40, borderRadius: 6 }}
              />
            ) : (
              <div className="homescreen-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "head",
      dataIndex: "head",
      key: "head",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Switch
              onClick={() => props.toggleStatus(record)}
              checked={record?.publishedAt}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ marginTop: 10 }}>
      <Table
        columns={columns}
        dataSource={[]}
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props.meta && props.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default Datatable;
