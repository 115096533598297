import AWS from "aws-sdk";
import moment from "moment";

const s3 = new AWS.S3({
  accessKeyId: "AKIAZR4MRHMHJZWAYS66",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const bucket = "suprabhaatham-bucket";

export const uploadImageToS3 = (file: File, filename: string) => {
  let fileLabel = filename.replace(/\s+/g, "_");
  const params = {
    Bucket: bucket,
    Key: fileLabel,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};
export const uploadPodcast = (file: File, filename: string) => {
  let fileLabel = filename.replace(/\s+/g, "_");
  const params = {
    Bucket: `${bucket}` + `/podcast`,
    Key: fileLabel,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};

export const uploadAudioToS3 = (
  extension: any,
  path: any,
  file: any,
  fileName: any
) => {
  const params = {
    Bucket: bucket,
    Key: path + "/" + fileName + extension,
    Body: file,
    ContentType: "audio/mpeg",
    ACL: "public-read",
  };

  return s3.upload(params).promise();
};

export const uploadInsnap = async (file: File[]) => {
  const uploadPromises = file.map(async (item: any) => {
    const name = item.originFileObj.name.slice(
      0,
      item.originFileObj.name.length - 4
    );

    const filename = `${moment().format("YYYY-MM-DDHH:MM:SS")}${name}.png`;
    let fileLabel = filename.replace(/\s+/g, "_");

    const params = {
      Bucket: `${bucket}/insnap`,
      Key: fileLabel,
      Body: item?.originFileObj,
      ACL: "public-read",
    };
    return await s3.upload(params).promise();
  });
  return Promise.all(uploadPromises);
};

export const uploadImageToS3banner = (file: File, filename: string) => {
  const params = {
    Bucket: `${bucket}/banners`,
    Key: filename,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};
export const uploadImageToS3WebStories = (file: File, filename: string) => {
  let filelabel = filename.replace(/\s+/g, "_");
  const params = {
    Bucket: `${bucket}/webstories`,
    Key: filelabel,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};

export const getImagesFromS3bucket = async (bucketName: string) => {
  const allData: AWS.S3.Object[] = [];
  let continuationToken: string | undefined = undefined;

  while (true) {
    const params: AWS.S3.ListObjectsV2Request = {
      Bucket: bucketName,
      ContinuationToken: continuationToken,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();

      allData.push(...(data.Contents || []));

      if (!data.IsTruncated) {
        break; // No more objects to retrieve, exit the loop
      }

      continuationToken = data.NextContinuationToken;
    } catch (error) {
      console.error("Error:", error);
      return error;
    }
  }
  const sortedData = allData.sort((a, b) => {
    const dateA = a.LastModified ?? new Date(0); // Use a default date if LastModified is undefined
    const dateB = b.LastModified ?? new Date(0);

    return dateA < dateB ? 1 : -1;
  });

  return sortedData;
};



//upload pdfimage
export const uploadPdfImageToS3 = (file: File, filename: string) => {
  let fileLabel = filename.replace(/\s+/g, "_");
  const params = {
    Bucket:  "epaper-2024/page-images" ,
    Key: fileLabel,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};