"use client";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "../styles.css";
const Timer = (props: any) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  return (
    <div className="Header_timerBox">
      <div className="Header_txt2">
        {" "}
        {moment().format("dddd")} {moment().format("DD")},{" "}
      </div>
      <div className="Header_txt3">
        &nbsp;
        {currentTime.toLocaleString("en-in", {
          month: "long",
          year: "numeric",
          hour12: true,
        })}
      </div>
    </div>
  );
};

export default Timer;
