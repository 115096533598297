import { Button, Popconfirm, Space, Switch, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { LuUndo2 } from "react-icons/lu";

function DataTable(props: any) {
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}.</div>;
      },
    },
    {
      title: "Preview",
      key: "image",
      dataIndex: "image",
      width: 20,
      render: (text, record: any) => {
        return (
          <div>
            {record?.image ? (
              <img
                key={record?.image}
                src={record?.image}
                alt="Table Image"
                style={{ height: 40, borderRadius: 6 }}
              />
            ) : (
              <div className="homescreen-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "head",
      dataIndex: "head",
      key: "head",
      width: 350,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.title}
          </div>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record: any) => {
        console.log("record", record);

        return (
          <Space size="middle">
            <Switch
              onClick={() => props.toggleStatus(record)}
              checked={record?.publishedAt}
            />
          </Space>
        );
      },
    },
    {
      title: "created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record: any) => {
        return <div>{moment(text).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={
                record?.is_deleted === true
                  ? "red"
                  : record?.publishedAt
                  ? "green"
                  : "blue"
              }
              style={{ fontSize: "10px" }}
            >
              {record?.is_deleted === true
                ? "Trash"
                : record?.publishedAt
                ? "Published"
                : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="small" style={{ marginRight: 10 }}>
              <Button
                size="small"
                type="primary"
                onClick={() => props?.openElectItems(record)}
              >
                Items
              </Button>
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record?.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record?.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record?.is_deleted === true ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => props?.onDelete(record)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginTop: "10px" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default DataTable;
