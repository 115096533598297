import moment from "moment";
import { API } from "../../../config/API";

const convertApi = async (
  file: any,
  type: any,
  index: any,
  quality: any,
  editon: any,
  date: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = API.EPAPER_PDF;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);
      formData.append("index", index);
      formData.append("quality", quality);
      formData.append("editon", editon);
      formData.append("date", moment(date).format("DD-MM-YYYY"));
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("convertApi error = = = >", error);
      resolve(error);
    }
  });
};

const UploadApi = async (
  file: any,
  type: any,
  index: any,
  quality: any,
  editon: any,
  date: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = API.EPAPER_IMAGE;
      const formData = new FormData();
      formData.append("files", file);
      formData.append("type", type);
      formData.append("index", index);
      formData.append("quality", quality);
      formData.append("editon", editon);
      formData.append("date", moment(date).format("DD-MM-YYYY"));
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("convertApi error = = = >", error);
      resolve(error);
    }
  });
};

export { convertApi, UploadApi };
