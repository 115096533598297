import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Switch,
  message,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { API } from "../../../config/API";
import { uploadImageToS3 } from "../../../config/s3config/s3fileUpload";
import { BsUpload } from "react-icons/bs";
import ImageUpload from "../../../assets/image/Group 225.svg";
import PageHeader from "../../../component/pageHeaders/index";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadCategories, LoadTags } from "../../../utils/commonapis";
import ImagePicker2 from "../../../component/image-pickersecond";

function NewForm() {
  const location = useLocation();
  const props = location?.state?.items;

  const navigate = useNavigate();

  const User = useSelector((state: any) => state.User.user);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageurl, setImageurl] = useState<any>(props?.banner_image || "");
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState<any>(false);
  const [isUploading, setIsUploading] = useState<any>(false);
  const [imageUploader, setImageUploader] = useState<any>(false);
  const [tags, setTags] = useState([]);

  const [reelsId, setReelsId] = useState() as any;

  useEffect(() => {
    loadCategories();
    loadTags();
  }, []);

  const loadCategories = async () => {
    const res: any = await LoadCategories();
    setCategories(res.data);
  };

  const loadTags = async () => {
    const res: any = await LoadTags();
    setTags(res);
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };

  const onFinish = (values: any) => {
    let show;
    if (props?.handleShow === true && values.Show === undefined) {
      show = true;
    }
    let categori;
    if (values.selectall === true) {
      categori = categories.map((item: any) => {
        let obj = {
          id: item.id,
          category: item.attributes.category,
        };

        return obj;
      });
    } else {
      categori = values?.categories
        ? values.categories.map((item: any) => JSON.parse(item))
        : null;
    }

    const tags = values.tags
      ? values.tags?.map((item: any) => JSON.parse(item))
      : null;

    setIsLoading(true);
    let requestObj = {
      data: {
        banner_image: imageurl,
        type: values.type ? values.type : "",
        hyperlink: values.hyperlink ? values.hyperlink : "",
        handleShow: values?.Show === true ? values?.Show : show || false,
        categories: categori,
        permalink: values?.permalink,
        tags: tags !== null ? tags.map((item: any) => item.id) : null,
        publishedAt: props?.id ? props?.publishedAt : null,
      },
    };
    let url = props?.id
      ? API.BASE_URL + API.BANNERS + `/${props?.id}`
      : API.BASE_URL + API.BANNERS;
    let method = props?.id ? "PUT" : "POST";

    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestObj),
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
          notification.success({
            message: `success`,
          });
          console.log(json);
          setReelsId(json.data.id);
          if (props?.id) {
            navigate(`/Auth/banner`);
          }
        })
        .catch((error) => {
          reject(error);
          notification.error({
            message: error,
          });
        });
    });
  };

  const onPublish = (id: any) => {
    setLoading(true);
    try {
      let url = API.BASE_URL + API.BANNERS + `/${id}`;
      let requestObj = {
        data: {
          publishedAt: new Date(),
        },
      };
      axios({
        method: "PUT",
        url: url,
        data: requestObj,
      })
        .then((response) => {
          console.log(response);
          message.success("Published");
          navigate(`/Auth/banner`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log("======error=====", error);
    }
  };

  const onImageUpload = async (value: any) => {
    setIsUploading(true);
    let imagefolder = value?.target?.files[0];
    const fileName: any = moment().unix().toString() + "-suprabhaatham.png";
    const file = imagefolder;
    const response = uploadImageToS3(file, fileName);
    response.then((location) => {
      setImageurl(location.Location);
      setIsUploading(false);
    });
  };

  const addImageToList = async (val: any) => {
    if (val?.type === "medialibrary") {
      setImageurl(val?.val);
    } else {
      await onImageUpload(val?.val?.file);
    }
  };

  return (
    <div style={{ maxHeight: "100vh", overflow: "scroll" }}>
      <PageHeader
        title={`${
          props?.id !== null && props?.id !== undefined ? "Edit" : "Create"
        } Banner`}
      ></PageHeader>
      <Col md={{ span: 11, offset: 1 }}>
        <Form
          {...formItemLayout}
          initialValues={{
            type: props?.type,
            categories:
              props?.categories &&
              props?.categories?.data?.map((item: any) => {
                let obj = {
                  id: item?.id,
                  category: item?.category,
                };
                let category = JSON.stringify(obj);
                return category;
              }),
            tags:
              props?.tags &&
              props?.tags?.data?.map((item: any) => {
                let obj = {
                  id: item?.id,
                  tag: item?.tag,
                };
                let category = JSON.stringify(obj);
                return category;
              }),
            permalink: props?.permalink,
            hyperlink: props?.attributes?.hyperlink,
          }}
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col md={6}>
              <Row>
                <label className="reelScrn-formLabel">Position</label>
                <Form.Item name="type">
                  <Select placeholder="Select Role">
                    <Select.Option value={"Home"}>Home-Header</Select.Option>
                    <Select.Option value={"Editorial"}>
                      Above-Editorial
                    </Select.Option>
                    <Select.Option value={"Shortz"}>Above-Shortz</Select.Option>
                    <Select.Option value={"detailedNews"}>
                      Above-detailedNews
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item name="selectall" valuePropName="checked">
                  <Checkbox>Select all From Categories</Checkbox>
                </Form.Item>
                <label className="newsFormLabels">Categories</label>
                <Form.Item name="categories">
                  <Select mode="tags">
                    {categories.length > 0 &&
                      categories.map((item: any, index: any) => {
                        let obj = {
                          id: item.id,
                          category: item.attributes.category,
                        };
                        let category = JSON.stringify(obj);
                        return (
                          <Select.Option key={index} value={category}>
                            {item?.attributes?.category}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Row>
              <Row>
                <label className="newsFormLabels">Tags</label>
                <Form.Item name="tags">
                  <Select style={{ background: "transparent" }} mode="multiple">
                    {tags.length &&
                      tags.map((item: any, index: any) => {
                        let obj = {
                          id: item.id,
                          tag: item.tag,
                        };
                        let tags = JSON.stringify(obj);
                        return (
                          <Select.Option key={index} value={tags}>
                            {item.tag}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Row>
              <Row>
                <label className="reelScrn-formLabel">
                  Upload banner-image (1116 × 100 px)
                </label>
                <Form.Item name="image" {...formItemLayout}>
                  <div
                    className="imageuploadbox"
                    style={{
                      height: "150px",
                      maxWidth: 678,
                      backgroundColor: "#f1f1f1",
                    }}
                    onClick={() => setImageUploader(true)}
                  >
                    <img
                      style={{ width: "50%", height: 50 }}
                      src={ImageUpload}
                    />
                    <div className="reelScrn-Uploadtxt">
                      Upload banner-image
                    </div>
                    {/* <Input type="file" onChange={(val) => onImageUpload(val)} /> */}
                  </div>
                  <br />
                  {imageurl ? (
                    <>
                      <img
                        style={{ width: "50%", height: "100%" }}
                        src={imageurl}
                      />
                    </>
                  ) : null}
                </Form.Item>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <label className="reelScrn-formLabel">Hyper-Link</label>
                <Form.Item name="hyperlink">
                  <Input size="large" />
                </Form.Item>
              </Row>
              <Row>
                <label className="reelScrn-formLabel">Perma Link</label>
                <Form.Item name="permalink">
                  <Input size="large" />
                </Form.Item>
              </Row>
              <Row>
                <label className="reelScrn-formLabel">allow T0 Live </label>
                <Form.Item name="Show">
                  <Switch
                    defaultChecked={
                      props?.attributes?.handleShow === true ? true : false
                    }
                  />
                </Form.Item>
              </Row>
              <Row style={{ padding: 10 }}>
                <div>
                  <Button className="podcast-Btn" htmlType="submit">
                    {props?.id ? "update" : "save"}
                  </Button>
                </div>
                <div style={{ marginTop: 30 }}>
                  {User.role.id === 4 && !props?.id ? (
                    <Button
                      className="podcast-Btn"
                      type="primary"
                      onClick={() => onPublish(reelsId)}
                      loading={loading}
                      icon={<BsUpload />}
                    >
                      Publish
                    </Button>
                  ) : null}
                </div>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
      <br />
      <br />
      <br />
      <br />
      {imageUploader ? (
        <ImagePicker2
          onSubmit={(val: any) => addImageToList(val)}
          visible={imageUploader}
          close={() => setImageUploader(false)}
        />
      ) : null}
    </div>
  );
}

export default NewForm;
