import React, { useState, useRef } from "react";
import { Col, Row } from "antd";
import ReactPlayer from "react-player/youtube";
import Loadingbox from "../../../component/loadingbox";
import { Button, Popover } from "antd";
import "../styles.css";

import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { Avatar, Card } from "antd";
function PodcastList(props: any) {
  const [activeItemId, setActiveItemId] = useState(null);

  const content = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <button className="editBtn" onClick={() => props.onDelete()}>
        Yes
      </button>
      <button className="editBtn">No</button>
    </div>
  );
  const handleClick = (itemId: any) => {
    setActiveItemId(itemId);
  };
  const { Meta } = Card;
  const ref = useRef<any>();
  const scroll = (ratio: any) => {
    ref.current.scrollLeft += ratio;
  };

  return (
    <>
      {props.loading ? (
        <Loadingbox />
      ) : (
        <div style={{ height: "100%" }}>
          <div className="podcastList-main">
            {props.data?.map((item: any) => {
              return (
                <div className="podcastList-cardBox1">
                  <div className="podcastList-CardBox2">
                    <Card
                      style={{ width: 300 }}
                      cover={
                        activeItemId !== item.id ? (
                          <img
                            style={{ width: "100%", height: "200px" }}
                            src={item.attributes.image}
                            alt=""
                            onClick={() => handleClick(item.id)}
                          />
                        ) : (
                          <ReactPlayer
                            controls
                            width={"100%"}
                            height={"200px"}
                            url={item.attributes.audio}
                          />
                        )
                      }
                      actions={[
                        <Row>
                          <Col md={12}>
                            <div>
                              <Popover
                                content={
                                  <>
                                    <div className="podcastList-ContentBtn">
                                      <Button
                                        type="primary"
                                        className="podcastList-editBtn-yes"
                                        onClick={() => props.onDelete(item.id)}
                                      >
                                        Yes
                                      </Button>
                                      <Button className="podcastList-editBtn-no">
                                        No
                                      </Button>
                                    </div>
                                  </>
                                }
                                title="Delete Podcast ?"
                                trigger="click"
                              >
                                <Button>
                                  <RiDeleteBin6Line
                                    size={18}
                                    style={{ marginBottom: 5, marginRight: 4 }}
                                  />
                                  Delete
                                </Button>
                              </Popover>
                            </div>
                          </Col>
                          <Col md={12}>
                            <Button
                              style={{ marginRight: 4 }}
                              type="primary"
                              onClick={() => props.onEdit(item)}
                            >
                              <CiEdit size={22} color="white" />
                              Edit
                            </Button>
                          </Col>
                        </Row>,
                      ]}
                    >
                      <div className="podcasrtCard-TileTxt">
                        {item?.attributes?.title}
                      </div>
                    </Card>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
export default PodcastList;
