import React, { useState, useEffect } from "react";
import PageHeader from "../../../component/pageHeaders/index";
import { useNavigate, useParams } from "react-router-dom";
import "../styles.css";
import { API } from "../../../config/API";
import Loadingbox from "../../../component/loadingbox";
import { DELETE, POST, PUT } from "../../../utils/apirequest";
import { CloseCircleOutlined } from "@ant-design/icons";
import DataTable from "./datatable";
import { Button, DatePicker, Input, Popover, Radio, Tag, message } from "antd";
import moment from "moment";
import { CiTrophy } from "react-icons/ci";
import SelectModal from "./selectModal";

function QuizQuestion() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [trashcount, setTrashcount] = useState();
  const [publishCount, setPublishCount] = useState();
  const [filter, setFilter] = useState({
    masterId: id,
    start: 0,
    limit: 10,
  }) as any;
  const [draft, setDraft] = useState();
  const [winModal, setWinModal] = useState(false);
  const [type, setType] = useState(null);

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = async (filter: any) => {
    try {
      setIndicator(true);
      let url = API.QUIZZ_ITEM_LIST;
      const res: any = await POST(url, filter);
      if (res?.status) {
        setData(res?.data);
        setMeta(res.count);
        setPublishCount(res?.publishedCount);
        setTrashcount(res?.trashedCount);
        setDraft(res?.draftCount);
        setIsloading(false);
        setIndicator(false);
      }
    } catch (err) {
      console.log("err", err);
      setIsloading(false);
      setIndicator(false);
      message.error("Something went wrong...");
    }
  };

  const onDelete = async (val: any) => {
    try {
      let url = API.QUIZZ_ITEM_DELETE + `${val.id}`;
      let response: any = await DELETE(url);
      if (response) {
        loadData(filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };

  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const onEdit = (val: any) => {
    navigate(`/Auth/QuestionForm/${id}/${val?.id}`);
  };

  const answers = (val: any) => {
    navigate(`/Auth/QuizAnswers/${id}/${val?.id}`);
  };

  const LoadStatus = (type: any) => {
    let filters = {
      ...filter,
      type: type,
    };
    setFilter(filters);
    loadData(filters);
  };

  const toggleStatus = async (id: any) => {
    let URL = API.QUIZZ_ITEM_ACTIVATE + id;
    let res: any = await PUT(URL, {});
    if (res.id) {
      message.success("Status Changed");
      loadData(filter);
    }
  };

  const content = (
    <Radio.Group
      onChange={(e) => {
        setType(e.target.value);
        setWinModal(true);
      }}
    >
      <Radio value={"winner"}>Select from winner</Radio>
      <br />
      <Radio value={"all"}>Select from all participants</Radio>
    </Radio.Group>
  );

  return (
    <>
      <div style={{ height: "90vh", overflow: "scroll" }}>
        <PageHeader
          title={"Quiz Question"}
          loader={indicator}
          length={`${meta} questions`}
        >
          <div className="homescreen-pageItem">
            <div style={{ width: 250 }}>
              <Input
                allowClear
                placeholder="Search Campaign"
                onChange={(e: any) => Search(e.target.value)}
              />
            </div>
            &nbsp;
            <div className="homescreen-pageBox">
              <div
                className="homescreen-pageItem"
                onClick={() => LoadStatus("published")}
              >
                <span style={{ color: "green" }}>Published</span> : &nbsp;
                <b>{publishCount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadStatus("trash")}
              >
                <span style={{ color: "red" }}>Trashed</span> : &nbsp;
                <b>{trashcount}</b>
              </div>
              |
              <div
                className="homescreen-pageItem"
                onClick={() => LoadStatus("draft")}
              >
                <span style={{ color: "#0055a6" }}>Draft</span> : &nbsp;
                <b>{draft}</b>
              </div>
            </div>
            &nbsp;
            <Button
              type="primary"
              onClick={() => navigate(`/Auth/QuestionForm/${id}/create`)}
            >
              New Question +
            </Button>
          </div>
        </PageHeader>
        <div style={{ padding: 5 }} />
        <div className="homescreen-pageItem">
          <div
            style={{
              flex: 5,
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(filter)?.length > 3 ? "Filter By :" : "All Question"}{" "}
            &nbsp;
            {Object.keys(filter)?.map(
              (key, index) =>
                key !== "start" &&
                key !== "limit" &&
                key !== "masterId" && (
                  <Tag style={{ marginBottom: 5 }}>
                    {`${key}:  ${
                      key === "createdAt"
                        ? moment(filter[key]).format("DD-MM-YYYY")
                        : filter[key]
                    }`}{" "}
                    <CloseCircleOutlined onClick={() => clearFilters(key)} />
                  </Tag>
                )
            )}
          </div>
          <Popover content={content} title="Title" trigger="click">
            <Button
              style={{ background: "green", color: "#fff" }}
              icon={<CiTrophy size={20} />}
            >
              Select Winner
            </Button>
          </Popover>
          &nbsp;
          <div>
            <DatePicker
              allowClear
              style={{ width: 200 }}
              onChange={onChangeDate}
            />
          </div>
        </div>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div style={{ height: "80vh", overflow: "scroll" }}>
            <DataTable
              meta={meta}
              data={data}
              page={page}
              pagesize={pageSize}
              onEdit={(val: any) => onEdit(val)}
              answers={(val: any) => answers(val)}
              onDelete={(val: any) => onDelete(val)}
              refresh={() => loadData(filter)}
              onPageChange={(page: any, pageSize: any) =>
                onPageChange(page, pageSize)
              }
              toggleStatus={(val: any) => toggleStatus(val)}
            />
          </div>
        )}
        {winModal ? (
          <SelectModal
            visible={winModal}
            type={type}
            onCancel={() => setWinModal(false)}
          />
        ) : null}
      </div>
    </>
  );
}

export default QuizQuestion;
