import { useEffect, useState } from "react";
import { Button, message, Spin, Tag } from "antd";
import Dropzone from "react-dropzone";
import { IoNewspaperOutline } from "react-icons/io5";
import { UploadOutlined } from "@ant-design/icons";
import { Draggable } from "react-drag-reorder";
import { AiOutlineDelete } from "react-icons/ai";
import { CgReorder } from "react-icons/cg";
import { IoReaderOutline } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiUploadCloud } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";
import { MdOutlineBrokenImage } from "react-icons/md";
import UpdateImage from "./updateImage";
import CanvasModal from "./canvasModal";

function PagesList(props: any) {
  const [isloading, setIsloading] = useState(false);
  const [item, setItem] = useState({}) as any;
  const [updateImage, setUpdateImage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState<any>(null);

  useEffect(() => {
    setIsloading(true);
    setTimeout(() => {
      setIsloading(false);
    }, 50);
  }, [props?.uploadIndx]);

  const FilePicked = (value: any) => {
    setIsloading(true);
    if (value?.length) {
      createObject(value);
      setTimeout(() => {
        setIsloading(false);
      }, 50);
    } else {
      setIsloading(false);
      message.error("No file Found");
    }
  };

  const createObject = (value: any) => {
    var arro = props?.pages?.length ? props?.pages : [];
    for (let i = 0; i < value?.length; i++) {
      let obj = {
        index: arro?.length ? Number(arro?.length) + i : i,
        fileUrl: value?.fileUrl ? value?.fileUrl : null,
        file: value[i],
        name: value[i]?.name,
        uploaded: value?.fileUrl ? true : false,
        coords: [],
      };
      arro.push(obj);
    }
    props?.onChagnge(arro);
  };

  const removeItem = (item: any, index: number) => {
    try {
      setIsloading(true);
      let arro = props?.pages;
      arro.splice(index, 1);
      props?.onChagnge(arro);
      setTimeout(() => {
        setIsloading(false);
      }, 50);
    } catch (err) {
      props?.onChagnge(props?.pages);
    }
  };

  const uploadImage = (val: any, index: any) => {
    try {
      setUpdateImage(false);
      setIsloading(true);
      let arro = props?.pages;
      const foundIndex = arro.findIndex((item: any) => item.index === index);
      if (foundIndex !== -1) {
        arro[foundIndex] = { ...arro[foundIndex], imageUrl: val };
        props?.onChagnge(arro);
      }
      setTimeout(() => {
        setIsloading(false);
      }, 50);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getChangedPos = (currentPos: any, newPos: any) => {
    try {
      let arro = props?.pages;
      const updatedItems = [...arro];
      const movedItem = updatedItems.splice(currentPos, 1)[0];
      updatedItems.splice(newPos, 0, movedItem);
      props?.onChagnge(updatedItems);
    } catch (err) {
      console.log("err", err);
    }
  };

  const openModal = (item: any, index: any) => {
    setModalImage(item?.imageUrl);
    setModalVisible(true);
    setCurrentIndex(index);
  };

  const onSaveCanvas = (rectangles: any) => {
    try {
      if (currentIndex !== null) {
        let arro = props?.pages;
        const foundIndex = arro.findIndex(
          (item: any, index: any) => index === currentIndex
        );
        if (foundIndex !== -1) {
          arro[foundIndex] = { ...arro[foundIndex], coords: rectangles };
          props?.onChagnge(arro);
        }
      }
      setModalVisible(false);
    } catch (err) {
      console.log("err", err);
      setModalVisible(false);
    }
  };
  return (
    <>
      <label className="newsFormLabels">Upload Pages</label>
      <Dropzone onDrop={FilePicked}>
        {({ getRootProps, getInputProps }) => (
          <section {...getRootProps({})}>
            <input {...getInputProps()} />
            <Button
              icon={<UploadOutlined color="blue" size={30} />}
              block
              size="large"
              style={{
                border: "2px dashed #d1d1d1",
                height: "18vh",
                backgroundColor: "#fafafa",
                fontSize: 14,
              }}
            >
              Select or Drag Files
            </Button>
          </section>
        )}
      </Dropzone>
      <div style={{ margin: 20 }} />
      <label className="newsFormLabels">Pages</label>
      <div>
        {isloading ? (
          <div style={{ height: 500 }}></div>
        ) : props?.pages?.length ? (
          <div>
            <Draggable onPosChange={getChangedPos}>
              {props?.pages?.map((item: any, index: number) => {
                return (
                  <div key={index} className="Epaper-pageItem">
                    <div>
                      Page - <strong>{index + 1}</strong> |
                    </div>
                    &nbsp; &nbsp;
                    <div style={{ flex: 1 }}>{item?.name}</div>
                    {item?.coords?.length ? (
                      <Tag style={{ fontSize: 9 }}>
                        Cropped ( {item?.coords?.length} - Crop )
                      </Tag>
                    ) : null}
                    {item?.imageUrl ? (
                      <div onClick={() => openModal(item, index)}>
                        <IoReaderOutline color="blue" size={20} />
                      </div>
                    ) : null}
                    &nbsp; &nbsp;
                    <div onClick={() => removeItem(item, index)}>
                      <AiOutlineDelete color="red" size={20} />
                    </div>
                    &nbsp; &nbsp;
                    <div>
                      <CgReorder size={20} />
                    </div>
                    &nbsp; &nbsp;
                    {item?.uploaded ? (
                      item?.imageUrl ? (
                        <FaRegCheckCircle size={18} color="green" />
                      ) : (
                        <MdOutlineBrokenImage
                          size={18}
                          color="orange"
                          onClick={() => {
                            setItem(item);
                            setUpdateImage(true);
                          }}
                        />
                      )
                    ) : props?.uploadIndx === index ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : (
                      <FiUploadCloud size={20} color="orange" />
                    )}
                  </div>
                );
              })}
            </Draggable>
          </div>
        ) : (
          <div className="Epaper-PagesList">
            <div className="Epaper-PagesListBox">
              <IoNewspaperOutline size={30} />
              <div>No Pages Found</div>
            </div>
          </div>
        )}
      </div>
      {updateImage ? (
        <UpdateImage
          open={updateImage}
          data={item}
          editon={props?.edition}
          date={props?.date}
          onCancel={() => setUpdateImage(false)}
          onChange={(val: any) => uploadImage(val, item.index)}
        />
      ) : null}
      {modalVisible ? (
        <CanvasModal
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setModalImage("");
          }}
          imageUrl={modalImage}
          onSaveClick={(rectangles: any) => onSaveCanvas(rectangles)}
          initialCoords={props?.pages[currentIndex]?.coords}
        />
      ) : null}
    </>
  );
}

export default PagesList;
