import { useState } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import { Row ,Col} from "react-bootstrap";
import { API } from "../../../config/API";
import axios from "axios";

function TagForm(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  
  const onFinish = async (values: any) => {
    let body = {
      data: {
        tag: values.tag,
      },
    };
    let url = props.method === "post" ?   API.TAGS_LIST : API.TAGS_LIST + `/${props?.item?.id}`
    let method = props.method
    axios({
      method: method,
      url:API.BASE_URL + url,
      data: body,
    })
      .then((response) => {
        setIsLoading(false);
        props.onCancel();
        props.onSuccess();
        let type =  props.method === "post" ?  "Created" :"Updated"
        let res = `Sucessfully ${type}`
        message.success(res);
      })
      .catch((error) => {
        console.log(error);
        message.error("Please try again");
      });
  };

  return (
    <Modal
      footer={false}
      open={true}
      onCancel={() => props.onCancel()}
      centered
    >
      <div className="category-Modal">
        <div className="categoryScrn-Txt1">Create Tag</div>
        <Form
          layout="vertical"
          initialValues={{
            tag:
              props?.item &&
              props?.item?.attributes &&
              props?.item?.attributes?.tag,
          }}
          onFinish={onFinish}
        >
          <div style={{ marginTop: 30 }}>
            <div style={{ textAlign: "center" }}>
              <Form.Item name={"tag"}>
                <Input placeholder="Type here....." size="large" />
              </Form.Item>
            </div>
          </div>

            <Row>
            <Col xs="6"></Col>
              <Col xs={3}>
                <Form.Item>
                  <Button block size="large" onClick={() => props.onCancel()}>
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={3}>
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    size="large"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default TagForm;
