import { Button, notification } from "antd";
import Cropper, { ReactCropperElement } from "react-cropper";
function Crop(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      {props?.image?.file ? (
        <>
          <Cropper
            ref={props?.cropperRef as React.RefObject<ReactCropperElement>}
            src={props?.image?.url}
            style={{ height: 400, width: "100%" }}
            guides={true}
          />
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <Button
              type="primary"
              onClick={() => {
                props?.setUploadeAvailable(true);
                props?.handleCrop();
              }}
              style={{ marginLeft: 16 }}
            >
              Crop Image
            </Button>
          </div>
        </>
      ) : (
        <div className="no-img">No Image available</div>
      )}
    </>
  );
}

export default Crop;