import { useState, useEffect } from "react";
import { Button, Form, Input, Select, message, DatePicker, Switch } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { LoadTags } from "../../utils/commonapis";
import PageHeader from "../.././component/pageHeaders";
import Loadingbox from "../.././component/loadingbox";
import { Container, Row, Col } from "react-bootstrap";
import BannerImage from "../quiz/components/bannerImages";
import { API } from ".././../config/API";
import axios from "axios";
import moment from "moment";
import { POST, PUT } from "../../utils/apirequest";
import DynamicForm from "./components/dynamicForm";
import { IoCopyOutline } from "react-icons/io5";

function CreateForm(props: any) {
  const [form] = Form.useForm();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isLoading1, setIsLoading1] = useState<any>(false);
  const [type, setType] = useState(null) as any;
  const [tags, setTags] = useState<any>([]);
  const [data, setData] = useState({}) as any;

  const [description, setDescription] = useState<any>(null);
  const [bannerDesk, setBannerDesk] = useState<any>(null);
  const [bannerMob, setBannerMob] = useState<any>(null);

  useEffect(() => {
    if (id !== "create") {
      getData();
    } else {
      setIsLoading(false);
    }
    loadTags();
  }, []);

  const getData = async () => {
    try {
      if (id !== "create") {
        let url =
          API.BASE_URL + API.DYNAMIC_FORM_DETAILS + id + `?populate[0]=tags`;
        const results = await axios.get(url);
        if (results?.data?.data) {
          let data = results?.data?.data?.attributes;
          setData(data);
          setDescription(data?.description);
          setBannerDesk(data?.bannerDesk);
          setBannerMob(data?.bannerMob);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const loadTags = async () => {
    const res: any = await LoadTags();
    setTags(res);
  };

  const Finished = async (type: any) => {
    try {
      setIsLoading1(true);
      setType(type);
      form.submit();
      await form.validateFields();
      let values = form.getFieldsValue();
      var requestObj = {
        data: {
          end_date: values?.end_date,
          end_time: values?.end_time,
          head: values?.head,
          permalink: values?.permalink,
          start_date: values?.start_date,
          start_time: values?.start_time,
          tags: values?.tags,
          title: values?.title,
          fields: JSON.stringify(values?.fields),
          description: description,
          bannerDesk: bannerDesk,
          bannerMob: bannerMob,
          publishedAt:
            type === "publish" ? new Date() : data?.publishedAt || null,
        },
      };

      let url =
        id !== "create" ? API.DYNAMIC_FORM_EDIT + id : API.DYNAMIC_FORM_ADD;
      let METHOD = id !== "create" ? PUT : POST;
      let res: any = await METHOD(url, requestObj);
      if (res?.data?.id) {
        message.success(
          `Form ${id !== "create" ? "Updated" : "Created"} Successfully.`
        );
        setTimeout(() => {
          window.location.replace(`/Auth/form/${res?.data?.id}`);
        }, 200);
      }
    } catch (err) {
      console.log(err);
      setIsLoading1(false);
    }
  };

  const copyLink = () => {
    let link = data?.permalink?.replace(/\s+/g, "-");
    let url = `${API.APP_URL}form?id=${id}&link=${link}`;
    navigator.clipboard.writeText(url).then(() => {
      message.success("link copied");
    });
  };

  return (
    <div style={{ height: "95vh", overflow: "scroll" }}>
      <PageHeader title={"Form"} length={"Add New Form"}>
        <Button onClick={() => copyLink()}>
          Copy Link &nbsp;
          <IoCopyOutline size={20} color="green" cursor={"pointer"} />
        </Button>
      </PageHeader>
      <br />
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <div>
            <Form
              form={form}
              initialValues={{
                start_date: data?.start_date && moment(data?.start_date),
                end_date: data?.end_date && moment(data?.end_date),
                head: data?.head,
                permalink: data?.permalink,
                tags: data?.tags?.data?.map((item: any) => item?.id),
                title: data?.title,
                fields: data.fields && JSON.parse(data?.fields),
              }}
            >
              <Row>
                <Col sm={5}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item
                    name={"title"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Heading</label>
                  <Form.Item
                    name={"head"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Perma-Link</label>
                  <Form.Item
                    name={"permalink"}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                  <label className="newsFormLabels">Tags</label>
                  <Form.Item
                    name="tags"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      style={{ background: "transparent" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {tags.length &&
                        tags.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item.id}>
                              {item.tag}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col sm={6}>
                      <label className="newsFormLabels">Start Date</label>
                      <Form.Item name={"start_date"}>
                        <DatePicker
                          showTime
                          mode="date"
                          size="large"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <label className="newsFormLabels">End Date</label>
                      <Form.Item name={"end_date"}>
                        <DatePicker
                          showTime
                          mode="date"
                          size="large"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col sm={7}>
                  <label className="newsFormLabels">Description</label>
                  <ReactQuill
                    style={{ height: "115px", borderRadius: 5 }}
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                  <div style={{ margin: 40 }} />
                  <Row>
                    <Col sm={6}>
                      <label className="newsFormLabels">Desktop Banner</label>
                      <BannerImage
                        image={bannerDesk}
                        onChagne={(value: any) => setBannerDesk(value)}
                      />
                    </Col>
                    <Col sm={6}>
                      <label className="newsFormLabels">
                        Mobile Banner{" "}
                        <span style={{ color: "grey", fontSize: 10 }}>
                          (optional)
                        </span>
                      </label>
                      <BannerImage
                        image={bannerMob}
                        onChagne={(value: any) => setBannerMob(value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <br />
                <DynamicForm key_name={"fields"} />
              </Row>
              <hr />
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <Row>
                    <Col sm={6}>
                      <Button
                        size="large"
                        block
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "save"}
                        onClick={() => Finished("save")}
                      >
                        {id !== "create" ? "Update" : "Save"}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        disabled={data?.publishedAt ? true : false}
                        size="large"
                        block
                        type="primary"
                        style={{ height: 50 }}
                        loading={isLoading1 && type === "publish"}
                        onClick={() => Finished("publish")}
                      >
                        Publish
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
}

export default CreateForm;
