import { combineReducers } from "@reduxjs/toolkit";
import UserSlice from "./userslice";
import FormData from "./formSlice";
import TagSlice from "./tagSlice";

const Slices = combineReducers({
  User: UserSlice,
  News: FormData,
  Tags: TagSlice,
});
export default Slices;
